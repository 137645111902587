import { FC, useMemo } from "react";

import { PieChart, Pie, Sector, Tooltip, Cell, TooltipProps } from "recharts";

import { FITokens } from "../../utils/types/swap.types";
import { getFloor } from "../../utils/helpers/global.helper";

const TEXT_COLORS = ["#fff", "black", "black"];

const RADIAN = Math.PI / 180;

const INNER_RADIUS = 80;
const OUTER_RADIUS = 130;
const PIE_WIDTH = 270;
const PIE_HEIGHT = 270;
const PIE_X = PIE_WIDTH / 2;
const PIE_Y = PIE_HEIGHT / 2;

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.2;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  if (percent === 0) {
    return null;
  }
  return (
    <text
      x={x}
      y={y}
      fill={TEXT_COLORS[index % TEXT_COLORS.length]}
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const renderShape = (
  totalHoldings: number,
  cx: number,
  cy: number,
  iR: number,
  oR: number
) => {
  return (
    <g>
      <text x={cx} y={cy} dx={5} textAnchor="middle" fill={"black"}>
        Total balance:
      </text>
      <text x={cx} y={cy} dx={5} dy={20} textAnchor="middle" fill={"black"}>
        {totalHoldings.cofiFormatFloor(1)}$
      </text>
      <Sector cx={cx} cy={cy} innerRadius={iR} outerRadius={oR} fill={"red"} />
    </g>
  );
};

const CustomTooltip: FC<TooltipProps<any, any>> = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0];
    return (
      <div className=" border bg-white px-4 py-2 ">
        <p className="intro">
          {data.name}: ${data.value}
        </p>
      </div>
    );
  }
  return null;
};

type PieChartComponentProps = {
  balancesFi: FITokens;
  bgColors: Record<string, string>;
};

const PieChartComponent: FC<PieChartComponentProps> = ({
  balancesFi,
  bgColors,
}) => {
  const holdings = useMemo(() => {
    return [
      {
        name: "coUSD",
        value: getFloor(balancesFi.COUSD, 1),
      },
      {
        name: "coETH",
        value: getFloor(balancesFi.COETH, 1),
      },
      {
        name: "coBTC",
        value: getFloor(balancesFi.COBTC, 1),
      },
    ];
  }, [balancesFi]);

  const totalHoldings = useMemo(() => {
    return holdings.reduce((t, c) => t + (c?.value || 0), 0);
  }, [holdings]);

  return (
    <PieChart width={PIE_WIDTH} height={PIE_HEIGHT}>
      <Pie
        data={holdings}
        cx={PIE_X}
        cy={PIE_Y}
        innerRadius={INNER_RADIUS}
        outerRadius={OUTER_RADIUS}
        label={renderCustomizedLabel}
        labelLine={<></>}
        fill="#8884d8"
        dataKey="value"
      >
        {holdings.map((entry, index) => (
          <Cell
            key={`cell-${index}`}
            fill={bgColors[entry.name.toUpperCase()]}
            stroke={"#00000040"}
          />
        ))}
      </Pie>
      {totalHoldings > 0 &&
        renderShape(totalHoldings, PIE_X, PIE_Y, INNER_RADIUS, OUTER_RADIUS)}
      <Tooltip content={CustomTooltip} />
    </PieChart>
  );
};

export default PieChartComponent;
