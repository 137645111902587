import {
  ChangeEventHandler,
  FC,
  KeyboardEventHandler,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { Dialog } from "@headlessui/react";
import { useAccount } from "wagmi";
import ReactGA from "react-ga4";
// import { useNavigate } from "react-router-dom";

import Spinner from "../../../components/Spinner/Spinner";
import { ReactComponent as Cross } from "../../../assets/icons/Cross.svg";
import ListboxComponent from "../../../components/ListboxComponent/ListboxComponent";
import { CoinsString, Coins, Token } from "../../../utils/types/swap.types";
// import { getReceiveAmount } from "../../../utils/helpers/swap.helpers";
import { WalletContext } from "../../../context/Wallet.context";
import { ReactComponent as DoubleArrow } from "../../../assets/icons/DoubleArrow.svg";
import { ReactComponent as COUSD } from "../../../assets/logos/tokens/COUSDCLogo.svg";
import { ReactComponent as COBTCLogo } from "../../../assets/logos/tokens/COBTCLogo.svg";
import { ReactComponent as COETHLogo } from "../../../assets/logos/tokens/COETHLogo.svg";
import { ReactComponent as USDC } from "../../../assets/logos/tokens/USDC.svg";
import { ReactComponent as USDTLogo } from "../../../assets/logos/tokens/USDT.svg";
import { ReactComponent as DaiLogo } from "../../../assets/logos/tokens/DAILogo.svg";
import { ReactComponent as W_BTC_Logo } from "../../../assets/logos/tokens/wBTCLogo.svg";
import { ReactComponent as W_ETH_Logo } from "../../../assets/logos/tokens/wEtherLogo.svg";
import { ReactComponent as ETH_Logo } from "../../../assets/logos/tokens/ETHLogo.svg";
import { SwapContext, addressesTokens } from "../../../context/Swap.context";
import {
  bigIntToDecimal,
  displayBalance,
  // getMinAmountOut,
  roundNumber,
  showError,
  stringToBigInt,
} from "../../../utils/helpers/global.helper";
import useGetSupportedSwaps from "../../../hooks/useGetSupportedSwaps";
// import useRedeem from "../../../hooks/useRedeem";
import useApprove from "../../../hooks/useApprove";
// import useMint from "../../../hooks/useMint";
import useEnterCofi from "../../../hooks/useEnterCofi";
import useExitCofi from "../../../hooks/useExitCofi";
import usePrevious from "../../../hooks/usePrevious";
import useValueRef from "../../../hooks/useValueRef";
import useGetEstimatedValue from "../../../hooks/useGetEstimatedValue";
import { MainContext } from "../../../context/Main.context";
import Modal from "../../../components/Modal/Modal";
import { COINS_DISPLAY_DIGITS } from "../../../utils/constants/general";
import { cookies } from "../../../App/App";

const listStableCoinsFrom: Token[] = [
  { name: "ETH", svgLogo: ETH_Logo, title: "ETH" },
  { name: "USDC", svgLogo: USDC },
  { name: "wETH", svgLogo: W_ETH_Logo, title: "wETH" },
  { name: "wBTC", svgLogo: W_BTC_Logo, title: "wBTC" },
  { name: "DAI", svgLogo: DaiLogo, title: "Dai" },
  { name: "USDT", svgLogo: USDTLogo, title: "USDT" },
];

const listStableCoinsTo: Token[] = [
  { name: "COUSD", svgLogo: COUSD, title: "coUSD" },
  { name: "COETH", svgLogo: COETHLogo, title: "coETH" },
  { name: "COBTC", svgLogo: COBTCLogo, title: "coBTC" },
];

type ModalSwapProps = {
  isOpen: boolean;
  setIsOpen: Function;
  action: 0 | 1;
  setAction: Function;
};

const ETH_PREFERABLE_MIN_AMOUNT = 0.0001;

const ModalSwap: FC<ModalSwapProps> = ({
  isOpen,
  setIsOpen,
  action,
  setAction,
}) => {
  // const navigate = useNavigate();

  const [cryptoAmount, setCryptoAmount] = useState<string>("");

  const { kycDone, userData } = useContext(WalletContext);
  const { address, connector } = useAccount();

  const prevOpen = usePrevious(isOpen);
  const emailRef = useValueRef(userData.email);
  const connectorIdRef = useValueRef(connector?.id);

  const {
    tokenInSelected,
    tokenOutSelected,
    setTokenInSelected,
    setTokenOutSelected,
    decimalsTokens,
    pricesCoins,
    balanceCoins,
  } = useContext(SwapContext);
  const {
    setShowTransak,
    setShowCoffeeLoader,
    showCoffeeLoader,
    transakOrderStatusDataModal,
  } = useContext(MainContext);

  const cryptoAmountBigInt = stringToBigInt(
    cryptoAmount || "0",
    decimalsTokens[tokenInSelected as keyof CoinsString]
  ) as bigint;

  const [addressReferral, setAddressReferral] = useState<string>("");

  // const estimatedReceiving = useMemo(() => {
  //   const fee = action === 0 ? constants.mintFee : constants.redeemFee;

  //   return getReceiveAmount(
  //     cryptoAmountBigInt,
  //     fee,
  //     decimalsTokens[tokenInSelected as keyof Coins],
  //     decimalsTokens[tokenOutSelected as keyof Coins]
  //   );
  // }, [
  //   cryptoAmountBigInt,
  //   constants,
  //   action,
  //   tokenInSelected,
  //   tokenOutSelected,
  // ]);

  const estimatedReceivedTokensAmount = useGetEstimatedValue(
    cryptoAmountBigInt,
    action
  );

  const estimadedSendingUSD = useMemo(() => {
    if (estimatedReceivedTokensAmount !== undefined) {
      const estimatedSendingFormatted = Number(cryptoAmount);

      if (estimatedSendingFormatted !== undefined) {
        return (
          estimatedSendingFormatted *
          pricesCoins[tokenInSelected as keyof Coins]
        );
      }
    }
    return 0;
  }, [estimatedReceivedTokensAmount, action, pricesCoins, tokenInSelected]);

  const estimadedReceivingUSD = useMemo(() => {
    if (estimatedReceivedTokensAmount) {
      const estimatedReceivingFormatted = bigIntToDecimal(
        estimatedReceivedTokensAmount as unknown as bigint,
        decimalsTokens[tokenOutSelected as keyof Coins]
      );

      if (estimatedReceivingFormatted !== undefined) {
        return (
          estimatedReceivingFormatted *
          pricesCoins[tokenOutSelected as keyof Coins]
        );
      }
    }
  }, [estimatedReceivedTokensAmount, action, pricesCoins, tokenOutSelected]);

  // const minAmountOut = useMemo(() => {
  //   if (estimatedReceiving !== undefined) {
  //     return getMinAmountOut(estimatedReceiving);
  //   } else {
  //     return BigInt(0);
  //   }
  // }, [estimatedReceiving]);

  useEffect(() => {
    setCryptoAmount("");
  }, [action, tokenInSelected]);

  useEffect(() => {
    const referralAddress = cookies.get("referralAddress");

    if (referralAddress && referralAddress !== address) {
      setAddressReferral(referralAddress);
    }
  }, [address]);

  const closeModal = () => {
    setIsOpen(false);
  };

  // const openModal = () => {
  //   setIsOpen(true);
  // };

  const closeCoffeeModal = () => {
    setShowCoffeeLoader(false);
  };

  const supportedSwapsTokensList = useGetSupportedSwaps(tokenInSelected, [
    ...(action === 1 ? [] : listStableCoinsTo),
    ...(action === 0 ? [] : listStableCoinsFrom),
  ]);

  // const {
  //   write: writeCofiToUnderlying,
  //   error: redeemError,
  //   isRedeemLoading,
  // } = useRedeem(cryptoAmountBigInt, minAmountOut, action);

  // const { refetchMint, isMintLoading } = useMint(
  //   cryptoAmountBigInt,
  //   minAmountOut,
  //   addressReferral,
  //   action,
  //   closeCoffeeModal
  // );

  const { writeEnterCofi, enterCofiLoading } = useEnterCofi(
    cryptoAmountBigInt,
    addressReferral
  );
  const { writeExitCofi, exitCofiLoading } = useExitCofi(cryptoAmountBigInt);

  const {
    approveSuccess,
    skipApproval,
    writeApprove,
    error: approveError,
    isApproveLoading,
  } = useApprove(
    cryptoAmountBigInt,
    action === 0 ? writeEnterCofi : writeExitCofi
  );

  useEffect(() => {
    if (!isOpen && prevOpen && !approveSuccess) {
      ReactGA.event("close_deposit_modal", {
        category: "Deposit",
        email: emailRef.current,
        auth_method: connectorIdRef.current,
        action: "close_deposit_modal",
        label: "Opened deposit modal but do no deposit",
      });
    }
  }, [isOpen, approveSuccess, prevOpen]);

  useEffect(() => {
    if (
      isApproveLoading ||
      enterCofiLoading ||
      exitCofiLoading /* || isMintLoading*/
    ) {
      setShowCoffeeLoader(true);
    } else {
      setShowCoffeeLoader(false);
    }
  }, [isApproveLoading, enterCofiLoading, exitCofiLoading /*, isMintLoading*/]);

  useEffect(() => {
    const errorMessage = approveError?.message; // || redeemError?.message;
    if (errorMessage) {
      showError(errorMessage?.split(".")[0]);
      closeCoffeeModal();
    }
  }, [approveError?.message /*, redeemError?.message*/]);

  const handleCreditCardLinkClick = () => {
    // closeModal();

    setShowTransak(true);
  };

  const handleBridgeLinkClick = () => {
    // We need to reinit the bridge module on every page mount (to update the default params)
    window.location.replace(
      `/Bridge?destinationToken=${
        addressesTokens[tokenInSelected as keyof Coins]
      }`
    );
  };

  const handleAddressReferralChange: ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setAddressReferral(event.target.value);
  };

  const handleActionChange = () => {
    setAction((prev: 0 | 1) => {
      if (prev === 0) {
        return 1;
      } else {
        return 0;
      }
    });
  };

  const handleMaxClick = () => {
    const amount = bigIntToDecimal(
      balanceCoins[tokenInSelected as keyof Coins],
      decimalsTokens[tokenInSelected as keyof Coins]
    );
    if (amount) {
      const newNumber = `${amount}`.slice(
        0,
        decimalsTokens[tokenInSelected as keyof Coins] +
          Number(`${amount}`.includes("."))
      );
      setCryptoAmount(newNumber);
    } else {
      setCryptoAmount("");
    }
  };

  const handleDepositAmountInputChange: ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const value = event.target.value;

    const decimals = decimalsTokens[tokenInSelected as keyof Coins];

    const [wholePart, fraction] = value.split(".");
    const wholePartLength = wholePart === "0" ? 0 : wholePart?.length || 0;
    const fractionLength = fraction?.length || 0;

    if (wholePartLength + fractionLength > decimals || Number(value) < 0) {
      return;
    }

    setCryptoAmount(value);
  };

  const handleDepositAmountInputKeyDown: KeyboardEventHandler<
    HTMLInputElement
  > = (event) => {
    if (["e", "E", "+", "-"].includes(event.key)) {
      event.preventDefault();
    }
  };

  const hasAmountError =
    (balanceCoins[tokenInSelected as keyof Coins] || 0) < cryptoAmountBigInt;

  const disableApproveButton =
    hasAmountError ||
    cryptoAmountBigInt === (BigInt(0) as bigint) ||
    // isRedeemLoading ||
    // isMintLoading ||
    enterCofiLoading ||
    exitCofiLoading ||
    isApproveLoading;

  const balance = displayBalance(
    balanceCoins[tokenInSelected as keyof Coins],
    decimalsTokens[tokenInSelected as keyof CoinsString]
  );

  const nullableBalance = parseFloat(balance || "0") === 0;

  const displayBalanceWarning = nullableBalance && action === 0;
  const ethBalance = displayBalance(balanceCoins["ETH"], decimalsTokens["ETH"]);

  const hasPreferableMinEth = Number(ethBalance) > ETH_PREFERABLE_MIN_AMOUNT;

  return (
    <Modal
      dialogClassname="overflow-y-auto"
      isOpen={isOpen}
      closeModal={() => {
        // prevent closing swap modal on coffee loader click
        if (showCoffeeLoader || !!transakOrderStatusDataModal) {
          return;
        }
        closeModal();
      }}
    >
      <Dialog.Panel className="card w-[500px] transform text-left align-middle shadow-xl transition-all ">
        <Dialog.Title
          as="h3"
          className="flex flex-row items-center justify-between border-b-[0.5px] border-solid border-borderCardAbout p-6  text-lg font-medium leading-6 text-gray-900"
        >
          {action === 0 ? "Deposit with Wallet" : "Redeem"}
          <div
            className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-lg border border-slate-100 hover:border-slate-300"
            onClick={closeModal}
          >
            <Cross stroke="black" height="10" width="10" />
          </div>
        </Dialog.Title>
        <div className="borderBottom m-auto flex flex-col gap-5  p-5">
          <div className="flex flex-col items-center gap-3 sm:flex-row ">
            <div className="flex w-full flex-1 flex-col gap-2">
              <div className="text-base font-medium">From Wallet</div>
              <ListboxComponent
                width="100%"
                list={action === 0 ? listStableCoinsFrom : listStableCoinsTo}
                value={tokenInSelected}
                onChange={setTokenInSelected}
              />
              <div className="text-xs font-medium text-textGray">
                {`Balance : ${
                  tokenInSelected &&
                  roundNumber(balance, COINS_DISPLAY_DIGITS[tokenInSelected]) +
                    " " +
                    tokenInSelected
                }`}
              </div>
            </div>
            <div className="flex w-full flex-1 flex-col gap-2">
              <div className=" text-base font-medium">Amount</div>
              <div
                className={`flex h-[40px] flex-1  items-center justify-between rounded-xl border-solid ${
                  hasAmountError
                    ? "border-[1px] border-red-300 p-[7.5px]"
                    : "border-[0.5px] border-borderCardAbout p-2"
                }`}
              >
                <input
                  type="number"
                  placeholder="0,00"
                  value={cryptoAmount}
                  onKeyDown={handleDepositAmountInputKeyDown}
                  onChange={handleDepositAmountInputChange}
                />
                <div
                  onClick={handleMaxClick}
                  className="center h-[28px] w-[40px] cursor-pointer rounded-md bg-ethBalance px-1 py-2 text-xs font-semibold"
                >
                  Max
                </div>
              </div>
              <div className="text-xs font-medium text-textGray">{`$${roundNumber(
                `${estimadedSendingUSD}`,
                2
              )}`}</div>
            </div>
          </div>

          {(displayBalanceWarning || !hasPreferableMinEth) && (
            <div className="rounded-lg bg-brightYellow p-2">
              {!hasPreferableMinEth && (
                <p
                  className={`mx-auto max-w-[300px] text-center text-xs ${
                    displayBalanceWarning && "mb-2"
                  }`}
                >
                  To increase the chance of successful operation you need to
                  have at least {ETH_PREFERABLE_MIN_AMOUNT} ETH in your wallet.
                </p>
              )}
              {displayBalanceWarning && (
                <p className="text-center text-xs">
                  Dont have enough crypto? Topup your wallet using
                  <br />
                  <u
                    className="cursor-pointer transition-opacity hover:opacity-40"
                    onClick={handleCreditCardLinkClick}
                  >
                    credit card
                  </u>{" "}
                  or swap your coins via{" "}
                  <u
                    className="cursor-pointer transition-opacity hover:opacity-40"
                    onClick={handleBridgeLinkClick}
                  >
                    Bridge
                  </u>
                </p>
              )}
            </div>
          )}

          <div className="relative flex h-[20px] w-full items-center justify-center">
            <hr className="h-px w-full border-0 bg-gray-200 dark:bg-gray-700" />
            <DoubleArrow
              className="absolute bottom-[50%] right-[50%] translate-x-1/2 translate-y-1/2 cursor-pointer"
              onClick={handleActionChange}
            />
          </div>

          <div className=" flex flex-col items-center gap-3 sm:flex-row ">
            <div className="flex w-full flex-1 flex-col gap-2">
              <div className="text-base font-medium">
                To {action === 0 ? "Stoa" : ""} stablecoin
              </div>
              <ListboxComponent
                width="100%"
                list={supportedSwapsTokensList}
                value={tokenOutSelected}
                onChange={setTokenOutSelected}
              />
              <div className="text-xs font-medium text-textGray">2.93%</div>
            </div>

            <div className="flex w-full flex-1 flex-col gap-2">
              <div className="text-base font-medium">You will receive</div>
              <input
                type="number"
                placeholder="0,00"
                value={
                  cryptoAmount &&
                  displayBalance(
                    estimatedReceivedTokensAmount as unknown as bigint,
                    decimalsTokens[tokenOutSelected as keyof Coins]
                  )
                }
                disabled
                className="flex h-[40px] flex-1  items-center justify-between rounded-xl border-[0.5px] border-solid border-borderCardAbout p-[10px]"
              />
              <div className="text-xs font-medium text-textGray">{`$${roundNumber(
                `${(cryptoAmount && estimadedReceivingUSD) || 0}`,
                2
              )}`}</div>
            </div>
          </div>
        </div>

        {action === 0 && (
          <div className="flex flex-col gap-2 p-5">
            <div className=" text-sm  font-medium">Referral address</div>
            <input
              placeholder="Enter address..."
              type="text"
              className="h-[40px] w-[100%] rounded-lg border-[0.5px] border-solid border-[#00000033]  p-[10px]"
              value={addressReferral}
              onChange={handleAddressReferralChange}
            />
          </div>
        )}

        <div className="flex flex-col p-5">
          <button
            type="button"
            onClick={() => {
              kycDone === true && (approveSuccess || skipApproval)
                ? action === 0
                  ? writeEnterCofi?.()
                  : writeExitCofi?.()
                : writeApprove?.();
            }}
            disabled={disableApproveButton}
            className={`flex h-[48px] items-center justify-center rounded-lg p-3 text-base font-normal text-white ${
              disableApproveButton ? "bg-gray-300" : "cursor-pointer bg-pink"
            }`}
          >
            {
              /*isRedeemLoading || isMintLoading ||*/ exitCofiLoading ||
              enterCofiLoading ||
              isApproveLoading ? (
                <Spinner />
              ) : approveSuccess || skipApproval ? (
                action === 0 ? (
                  "Mint"
                ) : (
                  "Redeem"
                )
              ) : (
                "Approve"
              )
            }
          </button>
        </div>
      </Dialog.Panel>
    </Modal>
  );
};

export default ModalSwap;
