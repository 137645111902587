import { FC, useContext, useState, useMemo } from "react";

import { Listbox } from "@headlessui/react";

import Graph from "../../../../components/Graph/Graph";
import { ReactComponent as ArrowSwap } from "../../../../assets/icons/arrowSwap.svg";
import { TokenName } from "../../../../utils/types/swap.types";
import {
  APYContext,
  PERIODS_DATA,
  PeriodType,
} from "../../../../context/APY.context";

type CollapseTokenInfosProps = {
  tokenName: TokenName;
};

const tokenDescriptions = {
  COUSD:
    "COFI Dollar (coUSD) is a yield-bearing stablecoin that is pegged to USDC on a 1:1 basis. Holders of coUSD receive yield, in the form of new coUSD. The yield strategy monitors interest rates for USDC, DAI, and USDT in Yearn Finance, and allocates accordingly to optimise for the highest yield. Staking rewards are swapped for the USD want asset and reinvested daily.",
  COETH:
    "COFI Ethereum (coETH) is a yield-bearing stablecoin that is pegged to Wrapped Ethereum on a 1:1 basis. Holders of coETH receive yield, in the form of new coETH. The yield strategy monitors interest rates across our partner venues, such as Yearn Finance and Sonne, and swaps staking rewards for ETH which are reinvested.",
  COBTC:
    "COFI Bitcoin (coBTC) is a yield-bearing stablecoin that is pegged to Wrapped Bitcoin on a 1:1 basis. Holders of coBTC receive yield, in the form of new coBTC, which is earned through our partner venue, Sonne Finance. Staking Rewards are automatically reinvested.",
};

const CollapseTokenInfos: FC<CollapseTokenInfosProps> = ({ tokenName }) => {
  const [activePeriod, setActivePeriod] = useState<PeriodType>(PERIODS_DATA[0]);

  const { getDataByTokenName } = useContext(APYContext);

  const data = useMemo(
    () => getDataByTokenName(tokenName)?.[activePeriod.value] || {},
    [getDataByTokenName, tokenName, activePeriod?.value]
  );

  const apyValue = useMemo(() => Object.values(data)[0], [data]);

  return (
    <>
      <div className="borderBottom flex flex-col sm:flex-row">
        <div className="borderRight flex-2 flex flex-col gap-2 p-5 pb-0 sm:pb-5">
          <div className=" text-base font-semibold">Description</div>
          <div className="text-sm font-normal leading-[16px] text-[#000000B2]">
            {tokenDescriptions[tokenName]}
          </div>
        </div>

        <div className="flex flex-1 flex-col gap-2 p-5">
          <div>Summary</div>
          <div className="flex flex-row gap-2">
            <div className="center h-[90px] w-full flex-col  rounded-lg border">
              <div className=" text-base font-semibold">
                {apyValue?.toPercentageFormat?.(2)}
              </div>
              <div className="center flex-row gap-1 text-xs  font-normal text-textGray">
                <div className="z-1 relative flex flex-col">
                  <Listbox value={activePeriod} onChange={setActivePeriod}>
                    {({ open }) => (
                      <div className="relative mt-1">
                        <Listbox.Button className="relative w-full cursor-pointer rounded-lg bg-white py-2 pl-3 pr-6 text-left shadow-md focus:outline-none sm:text-sm">
                          <span className="block truncate text-xs  font-normal text-textGray">
                            {activePeriod.name}
                          </span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ArrowSwap className={open ? "rotate-180" : ""} />{" "}
                          </span>
                        </Listbox.Button>
                        <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {PERIODS_DATA.map((period, personIdx) => (
                            <Listbox.Option
                              key={personIdx}
                              className={`relative my-1 cursor-default select-none p-2 text-gray-900 ${
                                period.name === activePeriod.name
                                  ? "bg-gray-200"
                                  : "hover:bg-gray-100"
                              }`}
                              value={period}
                            >
                              {({ selected }) => (
                                <span
                                  className={`block text-sm ${
                                    selected ? "font-medium" : "font-normal"
                                  }`}
                                >
                                  {period.name}
                                </span>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </div>
                    )}
                  </Listbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-5">
        <div className="flex flex-wrap items-center justify-between gap-2 pb-3">
          <div>APY per Period</div>
          <div className="flex items-center gap-6 text-xs">
            <p className="flex items-center gap-1">
              <span className="h-[6px] w-[6px] rounded-[50%] bg-pink" />
              APY (%)
            </p>
          </div>
        </div>
        {data && <Graph historyData={data} />}
      </div>
    </>
  );
};

export default CollapseTokenInfos;
