import { Address } from "viem";

export type Chain = {
  chainId: number;
  name: string;
  isL1: boolean;
  sendingEnabled: boolean;
  icon: string;
  receivingEnabled: boolean;
  rpcs: string[];
  explorers: string[];
};

export enum TransactionStatus {
  READY = "READY",
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
}

export type TransactionAsset = {
  chainId: number;
  address: Address;
  symbol: string;
  name: string;
  decimals: number;
  icon?: string;
  logoURI: string;
  chainAgnosticId: string | null;
};

export type GasFees = {
  asset: TransactionAsset;
  gasLimit: number;
  feesInUsd: number;
  gasAmount: number;
};

export type UserTxProtocol = {
  icon: string;
  name: string;
  displayName: string;
};

export type CumulativeGasUsed = {
  hex: string;
  type: string;
};

export type UserTxsStep = {
  type: string;
  gasFees: GasFees;
  toAsset: TransactionAsset;
  protocol: {
    icon: string;
    name: string;
    displayName: string;
    securityScore: number;
    robustnessScore: number;
  };
  toAmount: number;
  fromAsset: TransactionAsset;
  toChainId: number;
  fromAmount: number;
  fromChainId: number;
  serviceTime: number;
  minAmountOut: number;
  protocolFees: {
    asset: TransactionAsset;
    amount: number;
    feesInUsd: number;
  };
  bridgeSlippage: number;
  maxServiceTime: number;
};

export type UserTxs = {
  steps?: UserTxsStep[];
  sender: string;
  txType: string;
  chainId: number;
  gasFees: GasFees;
  toAsset: TransactionAsset;
  protocol?: {
    icon: string;
    name: string;
    displayName: string;
  };
  toAmount: number;
  fromAsset?: TransactionAsset;
  recipient: string;
  fromAmount?: string;
  userTxType: string;
  userTxIndex: number;
  approvalData: {
    owner: string;
    allowanceTarget: Address;
    approvalTokenAddress: Address;
    minimumApprovalAmount: number;
  } | null;
  minAmountOut?: string;
  swapSlippage?: number;
  userTxStatus?: string;
  sourceTransactionHash: string;
  sourceTransactionReceipt: {
    to: string;
    from: string;
    logs: {
      data: string;
      topics: string[];
      address: Address;
      logIndex: number;
      blockHash: string;
      blockNumber: number;
      transactionHash: string;
      transactionIndex: number;
    }[];
    type: number;
    status: number;
    gasUsed: CumulativeGasUsed;
    blockHash: string;
    byzantium: boolean;
    logsBloom: string;
    blockNumber: number;
    confirmations: number;
    contractAddress: Address | null;
    transactionHash: string;
    transactionIndex: number;
    cumulativeGasUsed: CumulativeGasUsed;
    effectiveGasPrice: CumulativeGasUsed;
  };
  serviceTime?: number;
  bridgeSlippage?: number;
  maxServiceTime?: number;
  routePath?: string;
  stepCount?: number;
};

export type SocketTransaction = {
  activeRouteId: number;
  userAddress: Address;
  totalUserTx: number;
  userTxs: UserTxs[];
  fromChainId: number;
  toChainId: number;
  fromAssetAddress: Address;
  toAssetAddress: Address;
  fromAmount: number;
  toAmount: number;
  refuel: string | null;
  routeStatus: TransactionStatus;
  transactionData: Record<string, { txHash: string; chainId: number }>;
  bridgeTxHash: string;
  recipient: string;
  integratorId: number;
  destinationCallData?: any | null;
  bridgeInsuranceData?: any | null;
  integratorFee: {
    asset: TransactionAsset;
    amount: string;
  };
  createdAt: string;
  updatedAt: string;
  currentUserTxIndex: string | number | null;
  fromAsset: TransactionAsset;
  toAsset: TransactionAsset;
};

export type SocketTransactionMetaInfo = {
  limit: number;
  offset: number;
  totalRecords: number;
};

export type SocketApiResponse = {
  result: {
    activeRoutes: SocketTransaction[];
    pagination: SocketTransactionMetaInfo;
  };
  success: boolean;
};
