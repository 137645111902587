import { useContext, useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";

import { ReactComponent as COUSD } from "../../assets/logos/tokens/COUSDCLogo.svg";
import { ReactComponent as COBTC } from "../../assets/logos/tokens/COBTCLogo.svg";
import { ReactComponent as DepositIllustration } from "../../assets/illustrations/DepositIllustration.svg";
import { ReactComponent as DepositCards } from "../../assets/illustrations/DepositCards.svg";
import { WalletContext } from "../../context/Wallet.context";
import { ReactComponent as Warning } from "../../assets/icons/Warning.svg";
import { ReactComponent as COETH } from "../../assets/logos/tokens/COETHLogo.svg";
import { ReactComponent as USDC } from "../../assets/logos/tokens/USDC.svg";
import { ReactComponent as BTCLogo } from "../../assets/logos/tokens/BTCLogo.svg";
import { ReactComponent as ETHLogo } from "../../assets/logos/tokens/ETHLogo.svg";
import { ReactComponent as Cadena } from "../../assets/icons/Cadena.svg";
import { MainContext } from "../../context/Main.context";
import { ReactComponent as SettingsModif } from "../../assets/icons/settings-svgrepo-com.svg";
import { getGreeting } from "../../utils/helpers/global.helper";
import { cookies } from "../../App/App";

import TokenInfos from "./TokenInfos/TokenInfos";

const SwapPage = () => {
  const [collapseOpen, setCollapseOpen] = useState<1 | 2 | 3 | undefined>(1);
  const [greeting, setGreeting] = useState<string>("Hi");
  const { kycDone, registrationDone, userData } = useContext(WalletContext);
  const { search } = useLocation();

  const {
    setShowModalConnexion,
    setShowModalForm,
    setShowTransak,
    setIsOpenModalSwap,
    action,
    setAction,
    setShowModalModifyForm,
  } = useContext(MainContext);

  const { isConnected } = useAccount();
  const navigate = useNavigate();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    if (search) {
      const query = new URLSearchParams(search);
      const refAddress = query.get("ref");

      if (refAddress) {
        cookies.set("referralAddress", refAddress, { maxAge: 60 * 60 * 24 });

        navigate("/");
      }
    }
  }, [search]);

  useEffect(() => {
    let s = new Date().toLocaleString();
    const hours = s?.split(" ")?.[1]?.slice(0, 2);

    setGreeting(getGreeting(Number(hours)));
  }, []);

  const walletDescription =
    action == 0
      ? "Get started by connecting your wallet."
      : "Withdraw funds anytime to your wallet.";
  const creditCardDescription =
    action == 0
      ? "Get started by depositing in your preferred currency."
      : "Withdraw funds anytime to your local currency.";

  return (
    <div className="flex-1 bg-bgCardNavbar pb-16 pt-8">
      <div className="container flex flex-col gap-3">
        {userData.firstName && (
          <div className="relative mb-4 flex flex-1 flex-wrap pr-8 text-2xl font-bold">
            <p>
              <span className="text-sm font-normal sm:text-base">
                {greeting}
                ,&nbsp;
              </span>
              <br className="sm:hidden" />
              {userData.firstName?.toUpperCase()} !
            </p>
            <SettingsModif
              className="mb-1 ml-2 cursor-pointer self-end sm:mb-0 sm:self-center"
              onClick={() => {
                setShowModalModifyForm(true);
              }}
            />
          </div>
        )}
        {!kycDone && isConnected && (
          <div className="center flex flex-row flex-wrap gap-2 text-center">
            <Warning className="inline-block" />
            You have to pass your KYC before minting.
            <div
              onClick={() => {
                navigate("/KYC");
              }}
              className="  cursor-pointer text-gray-500 hover:text-gray-400"
            >
              Let&apos;s go !
            </div>
          </div>
        )}

        <div className="card min-h-[200px] w-full">
          <div className="borderBottom relative flex flex-col justify-between gap-y-5 p-5 md:flex-row">
            <div className="flex h-[32px] w-[158px] flex-row gap-[2px] rounded-lg bg-bgCardNavbar p-[2px]">
              <div
                className={`center h-[28px] w-[76px] cursor-pointer rounded-md p-[6px] text-xs font-normal  ${
                  action === 0 && "borderToggle  bg-white shadow"
                }`}
                onClick={() => {
                  setAction(0);
                }}
              >
                Deposit
              </div>
              <div
                className={`center h-[28px] w-[76px] cursor-pointer rounded-md p-[6px] text-xs font-normal ${
                  action === 1 && "borderToggle bg-white shadow"
                }`}
                onClick={() => {
                  setAction(1);
                }}
              >
                Redeem
              </div>
            </div>
            <div className=" ml-auto mr-auto flex w-full flex-wrap items-center justify-center gap-x-2 gap-y-1 self-center rounded-lg border border-[0.5px] bg-[#FAFAFA] px-3 py-[7px] text-xs text-black text-opacity-70 md:absolute md:left-1/2 md:w-fit md:translate-x-[-50%]">
              <p>Please make sure you are visiting:</p>
              <p className="flex items-center">
                <span className="flex items-center gap-[2px] text-brightGreen">
                  <Cadena
                    fill="red"
                    className="fill-brightGreen"
                    width={12}
                    height={12}
                  />
                  https://
                </span>
                app.cofi.money
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-3 p-5 md:flex-row">
            <div className="flex w-full flex-row items-center justify-between rounded-xl bg-[#F5F5F5]">
              <div className="flex flex-col justify-between p-4 pr-0 sm:pr-4">
                <div className="flex flex-col justify-between md:max-w-[200px]">
                  <div className="text-sm font-medium">
                    {action === 0 ? "Deposit" : "Redeem"} with Wallet
                  </div>
                  <div className="my-2 text-sm font-normal text-black text-opacity-70">
                    {walletDescription}
                  </div>
                </div>
                <div
                  className="center h-[40px] max-w-[108px] cursor-pointer rounded-lg border-[1px] border-pinkLight text-pinkLight"
                  onClick={() => {
                    !isConnected
                      ? setShowModalConnexion(true)
                      : !registrationDone
                      ? setShowModalForm(true)
                      : !kycDone
                      ? navigate("/KYC")
                      : setIsOpenModalSwap(true);
                  }}
                >
                  Proceed
                </div>
              </div>
              <div className="mx-[-10px] h-[120px] w-[100%] max-w-[130px] sm:mx-0 sm:h-[156px] sm:max-w-[156px]">
                <DepositIllustration width="100%" height="100%" />
              </div>
            </div>
            <div className="flex w-full flex-row items-center justify-between rounded-xl bg-[#F5F5F5] ">
              <div className="flex flex-col justify-between p-4 pr-0 md:pr-4">
                <div className="flex flex-col justify-between md:max-w-[200px]">
                  <div className="text-sm font-medium">
                    {action === 0
                      ? "Deposit with Credit Card"
                      : "Redeem to Bank"}
                  </div>
                  <div className="my-2 text-sm font-normal text-black text-opacity-70">
                    {creditCardDescription}
                  </div>
                </div>
                <div
                  onClick={() => {
                    !isConnected
                      ? setShowModalConnexion(true)
                      : !registrationDone
                      ? setShowModalForm(true)
                      : !kycDone
                      ? navigate("/KYC")
                      : setShowTransak(true);
                  }}
                  className="center h-[40px] max-w-[108px] cursor-pointer rounded-lg border-[1px] border-pinkLight text-pinkLight"
                >
                  Proceed
                </div>
              </div>
              <div className="mx-[-10px] h-[120px] w-[100%] max-w-[130px] sm:mx-0 sm:h-[156px] sm:max-w-[156px]">
                <DepositCards width="100%" height="100%" />
              </div>
            </div>
          </div>
        </div>
        <TokenInfos
          collapseOpen={collapseOpen === 1}
          setCollapseOpen={() => {
            collapseOpen === 1
              ? setCollapseOpen(undefined)
              : setCollapseOpen(1);
          }}
          TokenLogo={USDC}
          FiLogo={COUSD}
          tokenName={"COUSD"}
        />
        <TokenInfos
          collapseOpen={collapseOpen === 2}
          setCollapseOpen={() => {
            collapseOpen === 2
              ? setCollapseOpen(undefined)
              : setCollapseOpen(2);
          }}
          TokenLogo={ETHLogo}
          FiLogo={COETH}
          tokenName={"COETH"}
        />
        <TokenInfos
          collapseOpen={collapseOpen === 3}
          setCollapseOpen={() => {
            collapseOpen === 3
              ? setCollapseOpen(undefined)
              : setCollapseOpen(3);
          }}
          TokenLogo={BTCLogo}
          FiLogo={COBTC}
          tokenName={"COBTC"}
        />
      </div>
    </div>
  );
};

export default SwapPage;
