import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDnfu7iILq1M-bwcbcwU5RCL6XqJNOkd44",
  authDomain: "cofi-11fb5.firebaseapp.com",
  projectId: "cofi-11fb5",
  storageBucket: "cofi-11fb5.appspot.com",
  messagingSenderId: "421635125695",
  appId: "1:421635125695:web:b34eb60846e2844399f10a",
  measurementId: "G-B6RDY2PKET",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth, app };
