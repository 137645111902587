import { useContext } from "react";

import { useContractRead } from "wagmi";

import { SwapContext } from "../context/Swap.context";
import { CoinsString } from "../utils/types/swap.types";
import { DiamondContract } from "../utils/constants/wagmiConfig/wagmiConfig";

const useGetEstimatedValue = (amount: BigInt, action: 0 | 1) => {
  const { addressesTokens, tokenInSelected, tokenOutSelected } =
    useContext(SwapContext);

  const { data } = useContractRead({
    ...DiamondContract,
    functionName:
      action === 0 ? "getEstimatedCofiOut" : "getEstimatedTokensOut",
    args: [
      amount,
      tokenInSelected === "ETH"
        ? addressesTokens["wETH"]
        : addressesTokens[tokenInSelected as keyof CoinsString],
      tokenOutSelected === "ETH"
        ? addressesTokens["wETH"]
        : addressesTokens[tokenOutSelected as keyof CoinsString],
    ],
    enabled: Boolean(amount),
  });

  return data;
};

export default useGetEstimatedValue;
