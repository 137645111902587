import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
  ChangeEvent,
} from "react";

import { Dialog } from "@headlessui/react";
import { useAccount, useSignMessage } from "wagmi";
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword } from "firebase/auth";
import debounce from "lodash.debounce";
import ReactGA from "react-ga4";

import { ReactComponent as Cross } from "../../../assets/icons/Cross.svg";
import apiOffChain from "../../../utils/services/apiOffChain";
import { UserOffChainData } from "../../../utils/types/global.types";
import { WalletContext } from "../../../context/Wallet.context";
import Spinner from "../../Spinner/Spinner";
import { auth } from "../../../utils/services/firebase";
import CRMService from "../../../utils/services/crm";
import { MainContext } from "../../../context/Main.context";
import { formValidator, showError } from "../../../utils/helpers/global.helper";
import Modal from "../../../components/Modal/Modal";
import { cookies } from "../../../App/App";

type ModalOffChainDataProps = {
  isOpen: boolean;
  setIsOpen: Function;
};

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  marketing: false,
  signature: "",
  privacy: false,
};

const ModalOffChainData: FC<ModalOffChainDataProps> = ({
  isOpen,
  setIsOpen,
}) => {
  const [registrationSent, setRegistrationSent] = useState<boolean>(false);
  const [isRequesting, setIsRequesting] = useState<boolean>(false);
  const [userData, setUserData] = useState<UserOffChainData>(initialState);

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm<UserOffChainData>();

  const formHasError = !!Object.keys(errors).length;
  const { address, isConnected, connector } = useAccount();
  const isMagicConnector = connector?.id === "magic";
  const { signMessage } = useSignMessage({
    message:
      "I certify that I am 18 years of age or older. I agree with the Terms and Conditions, User Consent Form and KYC Policy.",
    async onSuccess(signature) {
      try {
        apiOffChain
          .insertOffChainData(
            address,
            userData,
            signature,
            connector?.id,
            cookies.get("referralAddress")
          )
          .finally(() => {
            setStoredUserData(userData);
            ReactGA.event("registration", {
              category: "User",
              action: "registration",
              label: "Submitted own info",
              auth_method: connector?.id,
              ga_session_id: analyticsData.sessionId,
            });
          });

        await CRMService.addContact({
          firstname: userData.firstName,
          lastname: userData.lastName,
          email: userData.email,
        });

        if (address) {
          const password = `${Math.random()
            .toString(36)
            .slice(2)}${(+new Date()).toString(36)}`;

          createUserWithEmailAndPassword(auth, userData.email, password).catch(
            (error) => {
              if (!error.message?.includes("email-already-in-use")) {
                throw new Error(error);
              }
            }
          );

          setStoredUserData(userData);
          setRegistrationSent(true);
        }
      } catch (error: any) {
        showError(error.message || error);
        setIsRequesting(false);
      }
    },
    onError(error) {
      showError(error.message);
      setIsRequesting(false);
    },
  });

  const debouncedEmailCheck = useCallback(
    debounce((value) => {
      if (value && connector?.id !== "magic") {
        return apiOffChain.checkEmail(value).then((response) => {
          if (response.data) {
            setError("email", {
              type: "validate",
              message: "Email already exist",
            });
            return "Email already exist";
          }
        });
      }
    }, 500),
    [connector?.id]
  );

  const handleEmailChanged = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      clearErrors("email");
      debouncedEmailCheck(event.target.value);
    },
    []
  );

  const {
    kycDone,
    registrationDone,
    setRegistrationDone,
    userData: storedUserData,
    setUserData: setStoredUserData,
  } = useContext(WalletContext);

  const { analyticsData } = useContext(MainContext);

  const navigate = useNavigate();

  const onSubmit: SubmitHandler<UserOffChainData> = (data) => {
    setIsRequesting(true);
    setUserData(data);
    signMessage();
  };

  const resetInternalState = () => {
    setIsRequesting(false);
    setRegistrationSent(false);
    setUserData(initialState);
    reset();
  };

  function closeModal() {
    setIsOpen(false);
    resetInternalState();
  }

  useEffect(() => {
    if (!registrationDone && isConnected) {
      setIsOpen(true);
    }
  }, [registrationDone, isConnected]);

  useEffect(() => {
    if (registrationSent) {
      setRegistrationDone(true);
    }
  }, [registrationSent]);

  useEffect(() => {
    if (isMagicConnector && !userData.email && storedUserData.email && isOpen) {
      setValue("email", storedUserData.email);
      setUserData((prev) => ({ ...prev, email: storedUserData.email }));
    }
  }, [userData.email, storedUserData.email, isMagicConnector, isOpen]);

  return (
    <>
      <Modal isOpen={isOpen} closeModal={closeModal}>
        <Dialog.Panel
          className={`flex ${
            kycDone ? "min-h-[140px]" : "min-h-[160px]"
          }  w-full max-w-md transform flex-col gap-4 overflow-hidden rounded-3xl bg-white text-left align-middle font-bold shadow-xl transition-all`}
        >
          <div className="flex items-center justify-between border-b p-5 text-base">
            <div className="font-normal">Registration</div>
            <div
              className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-lg border border-slate-100 hover:border-slate-300"
              onClick={() => {
                closeModal();
              }}
            >
              <Cross stroke="black" height="10" width="10" />
            </div>
          </div>

          {registrationDone ? (
            <div className="p-5 pt-0 font-normal">
              Thank you for submitting your details.
              {!kycDone && (
                <span>
                  {" "}
                  Finish final step of identity verification to start using
                  COFI. &nbsp;
                  <span
                    onClick={() => {
                      navigate("/KYC");
                      closeModal();
                    }}
                    className="cursor-pointer text-brightGreen"
                  >
                    Let&apos;s go !
                  </span>
                </span>
              )}
            </div>
          ) : (
            <>
              <div className=" px-5 font-normal">
                Be sure to enter your legal name as it appears on your
                government issued ID.
              </div>

              <form
                onSubmit={handleSubmit(onSubmit)}
                className="text-sm font-normal"
              >
                <div className="flex flex-col gap-4 px-5 ">
                  <div className="flex flex-row gap-4">
                    <label
                      className="flex flex-1 flex-col gap-2"
                      htmlFor="firstName"
                    >
                      <div>First name*</div>
                      <input
                        placeholder="Enter first name..."
                        type="text"
                        id="firstName"
                        className="h-[40px] w-[100%] rounded-lg border-[0.5px] border-solid border-[#00000033]  p-[10px]"
                        {...register("firstName", {
                          ...formValidator.firstName,
                        })}
                      />
                      {errors.firstName && (
                        <span className=" text-red-600">
                          {errors.firstName.message}
                        </span>
                      )}
                    </label>
                    <label
                      className="flex flex-1 flex-col gap-2"
                      htmlFor="lastName"
                    >
                      <div>Last name*</div>
                      <input
                        placeholder="Enter last name..."
                        type="text"
                        id="lastName"
                        className="h-[40px] w-[100%] rounded-lg border-[0.5px] border-solid border-[#00000033]  p-[10px]"
                        {...register("lastName", {
                          ...formValidator.lastName,
                        })}
                      />
                      {errors.lastName && (
                        <span className=" text-red-600">
                          {errors.lastName.message}
                        </span>
                      )}
                    </label>
                  </div>
                  <label className="flex flex-col gap-2" htmlFor="email">
                    <div>Email*</div>
                    <input
                      placeholder="Enter email..."
                      type="text"
                      id="email"
                      readOnly={isMagicConnector}
                      className={`h-[40px] w-[100%] rounded-lg border-[0.5px] border-solid border-[#00000033] p-[10px] ${
                        isMagicConnector && "text-gray-500"
                      }`}
                      {...register("email", {
                        ...formValidator.email,
                        validate: debouncedEmailCheck,
                      })}
                      onChange={handleEmailChanged}
                    />
                    {errors.email && (
                      <span className=" text-red-600">
                        {errors.email.message}
                      </span>
                    )}
                  </label>

                  <label className="flex items-start gap-2" htmlFor="marketing">
                    <input
                      type="checkbox"
                      className="mt-1"
                      id="marketing"
                      {...register("marketing")}
                    />
                    <div className="font-normal">
                      I agree to be contacted by COFI&#x2019;s marketing
                      campaigns.
                    </div>
                  </label>

                  <label className="flex items-start gap-2" htmlFor="privacy">
                    <input
                      type="checkbox"
                      className="mt-1"
                      id="privacy"
                      {...register("privacy", {
                        required: {
                          value: true,
                          message: "Validate Conditions",
                        },
                      })}
                    />
                    <div className="font-normal">
                      I certify that I am 18 years of age of older, I agree to
                      the{" "}
                      <a
                        href="https://www.cofi.money/terms-of-use"
                        target="_blank"
                        rel="noreferrer"
                        className="underline"
                      >
                        Terms and Conditions
                      </a>
                      ,{" "}
                      <a
                        href="https://www.cofi.money"
                        target="_blank"
                        rel="noreferrer"
                        className="underline"
                      >
                        Consent Form
                      </a>{" "}
                      and{" "}
                      <a
                        href="https://www.cofi.money/kyc-policy"
                        target="_blank"
                        rel="noreferrer"
                        className="underline"
                      >
                        KYC Policy
                      </a>
                      .
                      <br />
                      {errors.privacy && (
                        <span className=" text-red-600">
                          {errors.privacy.message}
                        </span>
                      )}
                    </div>
                  </label>
                </div>
                <div className="mt-5 border-t">
                  <div className="text-green-400 col-span-1 flex w-full items-center justify-center p-5 font-semibold">
                    <button
                      className={`flex h-12 w-full items-center justify-center rounded-lg ${
                        formHasError ? "bg-gray-300" : "bg-pinkLight"
                      } px-5 py-2 font-semibold text-white hover:bg-[#FF67B2] ${
                        (formHasError || isRequesting) && "pointer-events-none"
                      }`}
                      type="submit"
                      disabled={formHasError || isRequesting}
                    >
                      {isRequesting ? <Spinner /> : "Submit"}
                    </button>
                  </div>
                </div>
              </form>
            </>
          )}
        </Dialog.Panel>
      </Modal>
    </>
  );
};

export default ModalOffChainData;
