import { FC, useContext, useEffect, useMemo, useRef, Fragment } from "react";

import { Popover, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { useAccount, useBalance, useDisconnect } from "wagmi";
import { signOut, getAuth } from "firebase/auth";
import { Tooltip } from "react-tooltip";

import ConnectButton from "../ConnectButton/ConnectButton";
import { ReactComponent as Explore } from "../../assets/icons/Explore.svg";
import { ReactComponent as Warning } from "../../assets/icons/Warning.svg";
import { ReactComponent as Disconnect } from "../../assets/icons/Disconnect.svg";
import { ReactComponent as Wallet } from "../../assets/icons/Wallet.svg";
import { ReactComponent as Mail } from "../../assets/icons/Mail.svg";
import { WalletContext } from "../../context/Wallet.context";
import {
  bigIntToDecimal,
  copyToClipboard,
  displayBalance,
  roundNumber,
} from "../../utils/helpers/global.helper";
import { app } from "../../utils/services/firebase";
import { MainContext } from "../../context/Main.context";
import useBalancesCoins from "../../hooks/useBalancesCoins";
import { ReactComponent as ETH_Logo } from "../../assets/logos/tokens/ETHLogo.svg";
import { BalanceCoins, Coins } from "../../utils/types/swap.types";
import { SwapContext } from "../../context/Swap.context";
import {
  COINS_DISPLAY_DIGITS,
  COINS_TO_RENDER,
} from "../../utils/constants/general";
import useEtherProvider from "../../hooks/useEtherProvider";
import { WalletAsset } from "../../utils/types/global.types";
import { MagicService } from "../../utils/services/magic";

import { MagicWalletUIButton } from "./MagicWalletUIButton";

type DropdownProps = {};

const Dropdown: FC<DropdownProps> = () => {
  const dRef = useRef<HTMLButtonElement>(null);
  const auth = getAuth(app);
  const { balanceCoins } = useBalancesCoins();
  const { watchAsset } = useEtherProvider();

  const { kycDone, userData } = useContext(WalletContext);
  const { showModalConnexion, setShowModalConnexion } = useContext(MainContext);
  const { decimalsTokens, pricesCoins } = useContext(SwapContext);
  const { isConnected, address, connector } = useAccount();
  const { data } = useBalance({
    address,
  });
  const { disconnect } = useDisconnect();

  const navigate = useNavigate();

  const isMetamask = connector?.id === "metaMask";
  const isMagic = connector?.name === "Magic";

  const [positiveCoinKey, positiveCOCoinKey] = useMemo(() => {
    const coinsWithBalance = COINS_TO_RENDER.filter(
      (item) => !!balanceCoins[item.key as keyof BalanceCoins]
    );
    const coCoins = coinsWithBalance.filter((item) =>
      item.key.startsWith("CO")
    );
    return [coinsWithBalance[0]?.key, coCoins[0]?.key];
  }, [balanceCoins]);

  const handleCoinClicked = (key: string) => {
    const { address, decimals, image, title } =
      COINS_TO_RENDER.find((item) => item.key === key) || {};
    if (address && title && decimals && image) {
      watchAsset({ address, decimals, image, symbol: title } as WalletAsset);
    }
  };

  const ETH_AMOUNT = roundNumber(data?.formatted, COINS_DISPLAY_DIGITS["ETH"]);
  const ETH_COST = roundNumber(
    (Number(data?.formatted) * pricesCoins.ETH).toString(),
    2
  );

  return (
    <div className="">
      <Popover className="relative">
        {({ open, close }) => {
          useEffect(() => {
            if (!isConnected && !open && showModalConnexion) {
              dRef?.current?.click();
            }
          }, [isConnected, open, showModalConnexion]);

          useEffect(() => {
            if (!isConnected && open) {
              setShowModalConnexion(true);
            }
          }, [isConnected, open]);

          return (
            <>
              <Popover.Button
                ref={dRef}
                className={`
                ${open ? "" : "text-opacity-90"}
              group inline-flex items-center rounded-md px-1 py-1 text-base font-medium text-black hover:text-opacity-100 focus:outline-none`}
              >
                <ConnectButton isOpen={open} onClose={close} />
              </Popover.Button>

              {open && isConnected && (
                <Transition
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel
                    static
                    className="absolute right-0 z-10 mt-3 w-[300px] sm:px-0"
                  >
                    <div className="overflow-hidden rounded-lg shadow-lg ring-black ring-opacity-5">
                      <div className="relative grid gap-[6px] bg-white p-3 lg:grid-cols-1">
                        <div className="flex flex-col rounded-lg  bg-ethBalance py-2">
                          <div
                            className="flex cursor-pointer flex-row items-center justify-between gap-[12px] px-2 pb-2"
                            onClick={() => copyToClipboard(address)}
                          >
                            <Wallet />
                            <div>
                              {address?.slice(0, 6) +
                                "..." +
                                address?.slice(-6)}
                            </div>
                          </div>
                          {userData?.email && (
                            <div className="flex flex-row items-center justify-between gap-[8px] px-2 pb-2">
                              <Mail />
                              <div className="overflow-hidden text-ellipsis whitespace-nowrap">
                                {userData.email}
                              </div>
                            </div>
                          )}
                          <div className="border-t-[0.5px] border-solid border-[#00000033]"></div>
                          <div className="flex flex-col items-center justify-center pt-2">
                            <div className="my-1 flex w-full items-center justify-between px-2">
                              <p className="flex-center flex gap-2">
                                <ETH_Logo width={24} height={24} />
                                {data?.symbol}
                              </p>
                              <p className="flex-center flex">
                                {`${ETH_AMOUNT} ($${ETH_COST})`}
                              </p>
                            </div>
                            {COINS_TO_RENDER.map(
                              ({ Logo, title, key, maxDigits }) => {
                                const balance = displayBalance(
                                  balanceCoins[key as keyof BalanceCoins],
                                  decimalsTokens[key as keyof Coins]
                                );
                                if (balance === "0") {
                                  return null;
                                }

                                const renderDivider =
                                  positiveCoinKey === key ||
                                  positiveCOCoinKey === key;

                                const decimalValue = bigIntToDecimal(
                                  balanceCoins?.[key as keyof BalanceCoins],
                                  decimalsTokens?.[key as keyof Coins]
                                );

                                const estimatedValue = decimalValue
                                  ? pricesCoins[key as keyof Coins] *
                                    decimalValue
                                  : "";

                                const estimatedValueRounded = roundNumber(
                                  `${estimatedValue}`,
                                  2
                                );

                                return (
                                  <Fragment key={key}>
                                    {renderDivider && (
                                      <div className="my-2 h-[0.5px] w-[90%] border-t-[0.5px] border-solid border-[#00000033]" />
                                    )}
                                    <div
                                      className="my-1 flex w-full items-center justify-end px-2"
                                      key={key}
                                    >
                                      <button
                                        className={`walletCoinTooltip mr-auto ${
                                          isMetamask && "cursor-pointer"
                                        }`}
                                        onClick={() => handleCoinClicked(key)}
                                        disabled={!isMetamask}
                                      >
                                        <p className="flex items-center gap-2">
                                          <Logo width={24} height={24} />
                                          {title}
                                        </p>
                                      </button>
                                      <span>
                                        {roundNumber(balance, maxDigits)}
                                      </span>
                                      <span className="ml-1">
                                        (${estimatedValueRounded})
                                      </span>
                                      {isMetamask && (
                                        <Tooltip
                                          anchorSelect=".walletCoinTooltip"
                                          className="bg-white"
                                          place="top"
                                        >
                                          Click to add coin to metamask
                                        </Tooltip>
                                      )}
                                    </div>
                                  </Fragment>
                                );
                              }
                            )}
                          </div>
                        </div>

                        {isMagic && <MagicWalletUIButton />}

                        <div
                          className={`flex max-h-[64px] cursor-pointer items-center  justify-between px-[12px] py-[10px] text-base font-normal ${
                            !kycDone && " bg-yellow"
                          }`}
                          onClick={() => navigate("/KYC")}
                        >
                          <div>
                            {!kycDone ? (
                              <>
                                <p>Vérification Center</p>
                                <p>Complete Verification</p>
                              </>
                            ) : (
                              <p>KYC verified </p>
                            )}
                          </div>
                          {!kycDone && <Warning />}
                        </div>
                        <div className=" border-t-[0.5px] border-solid border-[#00000033]"></div>
                        <div
                          className="flex h-[48px] cursor-pointer items-center justify-between p-3 text-base font-normal"
                          onClick={() =>
                            window.open(
                              `https://optimistic.etherscan.io/address/${address}`,
                              "_blank"
                            )
                          }
                        >
                          Explore
                          <Explore />
                        </div>
                        <div className=" border-t-[0.5px] border-solid border-[#00000033]"></div>
                        <div
                          className="flex h-[48px] cursor-pointer items-center justify-between p-3 text-base font-normal"
                          onClick={() => {
                            close();

                            MagicService?.wallet?.disconnect?.();
                            disconnect();

                            signOut(auth).catch(console.log);
                          }}
                        >
                          Disconnect
                          <Disconnect />
                        </div>
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition>
              )}
            </>
          );
        }}
      </Popover>
    </div>
  );
};

export default Dropdown;
