import { useRef } from "react";

const useValueRef = (value: unknown) => {
  const valueRef = useRef(value);
  valueRef.current = value;

  return valueRef;
};

export default useValueRef;
