import { FC, useContext } from "react";

import { useAccount } from "wagmi";

import { ReactComponent as Arrow } from "../../assets/icons/ArrowWhiteAccount.svg";
import { ReactComponent as Profile } from "../../assets/icons/profile.svg";
import { ReactComponent as Validate } from "../../assets/icons/Validate.svg";
import ModalConnexion from "../Modals/ModalConnexion/ModalConnexion";
import { MainContext } from "../../context/Main.context";

type IProps = {
  isOpen: boolean;
  onClose: Function;
};

const ConnectButton: FC<IProps> = ({ isOpen, onClose }) => {
  const { isConnected, address } = useAccount();
  const { showModalConnexion, setShowModalConnexion } = useContext(MainContext);

  return (
    <div className="flex gap-2">
      {isConnected && (
        <div className="center gap-[6px] rounded-lg border-[0.5px] border-solid border-white bg-darkgreen p-2">
          <div className="relative mr-[6px]">
            <Validate className="absolute bottom-[-2px] right-[-6px] " />
            <Profile />
          </div>
          <span className="hidden text-white sm:block">
            {address?.slice(0, 6) + "..." + address?.slice(38)}
          </span>
          <Arrow className={` cursor-pointer ${isOpen && "rotate-180"}`} />
        </div>
      )}

      {!isConnected && (
        <div className="text-green-400 col-span-1 flex w-full items-center justify-end gap-[8px] font-semibold  ">
          <div className=" w-fit whitespace-nowrap rounded-lg bg-white px-5 py-2 font-semibold text-black">
            {!isConnected && "Login"}
          </div>
        </div>
      )}
      <ModalConnexion
        isOpen={showModalConnexion}
        setIsOpen={setShowModalConnexion}
        onClose={onClose}
      />
    </div>
  );
};

export default ConnectButton;
