import { useCallback, useEffect, useState } from "react";

import { useAccount } from "wagmi";

import {
  SocketApiResponse,
  SocketTransaction,
  SocketTransactionMetaInfo,
} from "../utils/types/bridge.types";

const BASE_API = "https://api.socket.tech/v2";

const TRANSACTIONS_API = `${BASE_API}/route/active-routes/users`;
const CHAINS_API = `${BASE_API}/supported/chains`;

const useTransactions = ({ limit = 30 }) => {
  const { address, isConnected } = useAccount();
  const [transactions, setTransactions] = useState<SocketTransaction[]>([]);
  const [chains, setChains] = useState([]);
  const [metaInfo, setMetaInfo] = useState<SocketTransactionMetaInfo | null>(
    null
  );
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);

  const fetchTransactions = useCallback(() => {
    if (!address) {
      setTransactions([]);
      return;
    }
    setIsPending(true);
    setError(null);

    fetch(`${TRANSACTIONS_API}?userAddress=${address}&limit=${limit}`, {
      headers: {
        "Api-Key": process.env.REACT_APP_SOCKET_API_KEY || "",
      },
    })
      .then((res) => res.json() as Promise<SocketApiResponse>)
      .then((res) => {
        setTransactions(() => {
          const data = res?.result?.activeRoutes;
          if (Array.isArray(data)) {
            return data;
          }
          if (data) {
            return [data];
          }
          return [];
        });
        setMetaInfo(res?.result.pagination || null);
      })
      .catch((error) => setError(error))
      .finally(() => setIsPending(false));
  }, [limit, address]);

  useEffect(() => {
    fetch(`${CHAINS_API}`, {
      headers: {
        "Api-Key": process.env.REACT_APP_SOCKET_API_KEY || "",
      },
    })
      .then((res) => res.json())
      .then((res) => setChains(res.result))
      .catch(console.log);
  }, []);

  const refetch = useCallback(() => {
    if (address) {
      fetchTransactions();
    }
  }, [address, limit]);

  useEffect(() => {
    fetchTransactions();
  }, [isConnected, fetchTransactions]);

  return { transactions, isPending, error, metaInfo, refetch, chains };
};

export default useTransactions;
