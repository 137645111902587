import { FC, useEffect } from "react";

import { Dialog } from "@headlessui/react";
import { optimism } from "viem/chains";
import { Connector, useAccount, useConnect } from "wagmi";

import Modal from "../../../components/Modal/Modal";
import { ReactComponent as Cross } from "../../../assets/icons/Cross.svg";
import { ReactComponent as MetaMaskLogo } from "../../../assets/logos/wallet/metamask.svg";
import { ReactComponent as CoinbaseLogo } from "../../../assets/logos/wallet/coinbase.svg";
import { ReactComponent as WalletConnectLogo } from "../../../assets/logos/wallet/walletconnect.svg";
import apiOffChain from "../../../utils/services/apiOffChain";
import Spinner from "../../Spinner/Spinner";
import {
  isBehindOtherElement,
  showError,
} from "../../../utils/helpers/global.helper";
import { useCountdown } from "../../../hooks/useCountDown";
import useValueRef from "../../../hooks/useValueRef";

import { MagicConnectButton } from "./MagicConnectButton";

type ModalConnexionProps = {
  isOpen: boolean;
  setIsOpen: Function;
  onClose: Function;
};

const ModalConnexion: FC<ModalConnexionProps> = ({
  isOpen,
  setIsOpen,
  onClose,
}) => {
  const { connect, connectors, isLoading, reset, pendingConnector } =
    useConnect({
      chainId: optimism.id,
      onSuccess(data) {
        apiOffChain.insertConnexionData(data.account);
      },
      onError() {
        reset();
        resetTimer(true);
      },
    });
  const { isConnected } = useAccount();

  const handleCheckWalletError = () => {
    const modalElement = document.querySelector("#AUTH_MODAL");
    if (!isBehindOtherElement(modalElement)) {
      showError("Can't connect to your wallet. Try to reload the page");
      reset();
    }
  };

  const { resetTimer, startTimer, timeLeftInMs, isCounting } = useCountdown(
    handleCheckWalletError
  );

  const timeLeftInMsRef = useValueRef(timeLeftInMs);
  const isCountingRef = useValueRef(isCounting);

  function closeModal() {
    setIsOpen(false);
    resetTimer(true);

    if (isLoading) {
      reset();
    }
  }

  useEffect(() => {
    const handleBlur = () => {
      if (isCountingRef.current) {
        resetTimer();
      }
    };
    const handleFocus = () => {
      if (Number(timeLeftInMsRef.current) > 0 && !isCountingRef.current) {
        startTimer(Number(timeLeftInMsRef.current));
      }
    };
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        handleFocus();
      } else {
        handleBlur();
      }
    };

    document.addEventListener("mousemove", handleFocus);
    document.addEventListener("mouseleave", handleBlur);
    document.addEventListener("visibilitychange", handleVisibilityChange);

    window.addEventListener("blur", handleBlur);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      document.removeEventListener("mousemove", handleFocus);
      document.removeEventListener("mouseleave", handleFocus);

      window.removeEventListener("blur", handleBlur);
    };
  }, []);

  useEffect(() => {
    if (isConnected) {
      setIsOpen(false);
      resetTimer(true);
    }
  }, [isConnected]);

  const magicConnector = connectors?.find(
    (connector) => connector.name === "Magic"
  );

  const renderButton = (item: Connector) =>
    // Magic button
    item.name === "Magic" ? (
      <MagicConnectButton connect={connect} connector={item} />
    ) : (
      <button
        disabled={isLoading}
        key={item.id}
        onClick={() => {
          connect({ connector: item });
        }}
        className={`flex h-[50px] items-center gap-2 rounded-lg border pl-3 text-gray-700 ${
          isLoading
            ? "pointer-events-none bg-gray-100"
            : "bg-white hover:cursor-pointer hover:bg-gray-100"
        }`}
      >
        {item.name === "MetaMask" ? (
          <MetaMaskLogo />
        ) : item.name === "Coinbase Wallet" ? (
          <CoinbaseLogo />
        ) : (
          <WalletConnectLogo />
        )}
        {item.name}
        {isLoading && item.id === pendingConnector?.id && (
          <div className="ml-auto">
            <Spinner />
          </div>
        )}
      </button>
    );

  return (
    <>
      <Modal
        childContainerId="AUTH_MODAL"
        isOpen={isOpen}
        closeModal={closeModal}
      >
        <Dialog.Panel className="min-h-[280px] w-full max-w-xl transform overflow-hidden rounded-3xl bg-white px-8 py-4 text-left align-middle font-bold shadow-xl transition-all">
          <div className=" col-end-1 flex items-center justify-center text-2xl">
            <h1 className="center flex-1 text-xl sm:text-xl">
              Choose Login Method
            </h1>
            <div
              className="ml-auto flex h-8 w-8 cursor-pointer items-center justify-center rounded-lg border border-slate-100 hover:border-slate-300"
              onClick={() => {
                closeModal();
                onClose?.();
              }}
            >
              <Cross stroke="black" height="10" width="10" />
            </div>
          </div>
          <div className="flex flex-col-reverse sm:flex-row sm:gap-2">
            <div className="mb-5 flex flex-1 flex-col gap-3 text-base sm:mt-5">
              <div className="text-center text-lg">Browser wallet</div>
              {connectors.map((connector) => {
                if (connector.name === "Magic") {
                  return null;
                }
                return renderButton(connector);
              })}
            </div>
            {magicConnector && (
              <>
                <div className="my-5 flex h-auto flex-1 flex-col gap-3">
                  <div className="text-center text-lg">Email</div>
                  {renderButton(magicConnector)}
                </div>
              </>
            )}
          </div>
        </Dialog.Panel>
      </Modal>
    </>
  );
};

export default ModalConnexion;
