import { FC, useContext } from "react";

import { Dialog } from "@headlessui/react";
import { useAccount } from "wagmi";

import Modal from "../../../components/Modal/Modal";
import orderCompletedImagePath from "../../../assets/illustrations/OrderCompleted.svg";
import orderFailureImagePath from "../../../assets/icons/Error.svg";
import { getEtherScanTXLink } from "../../../utils/helpers/global.helper";
import { SwapContext } from "../../../context/Swap.context";
import { COINS_TO_RENDER } from "../../../utils/constants/general";
import useEtherProvider from "../../../hooks/useEtherProvider";

type ModalTransakOrderStatusProps = {
  orderStatusData: Record<string, any> | false;
  setIsOpen: Function;
  setAction: Function;
  setIsOpenModalSwap: Function;
};

type ButtonProps = {
  onClick: () => void;
  title: string;
  filled?: boolean;
};

const Button: FC<ButtonProps> = ({ onClick, title, filled }) => {
  return (
    <button
      onClick={onClick}
      className={`flex h-12 w-full items-center justify-center rounded-lg px-5 py-2 font-semibold  ${
        filled ? "bg-pinkLight text-white hover:bg-[#FF67B2]" : "text-pinkLight"
      }`}
    >
      {title}
    </button>
  );
};

const TXLinkMessage = ({ link }: { link: string }) => {
  return (
    <p className="mt-2 text-center text-base">
      Click the
      <a
        className="mx-1 text-brightGreen text-pinkLight"
        href={link}
        target="_blank"
        rel="noreferrer"
      >
        link
      </a>
      to check transaction details.
    </p>
  );
};

const ModalTransakOrderStatus: FC<ModalTransakOrderStatusProps> = ({
  orderStatusData,
  setIsOpen,
  setAction,
  setIsOpenModalSwap,
}) => {
  const { tokenOutSelected } = useContext(SwapContext);
  const { watchAsset } = useEtherProvider();
  const { connector } = useAccount();
  const isMetamask = connector?.id === "metaMask";

  const getMetaByOrderStatus = (
    status:
      | "TRANSAK_ORDER_SUCCESSFUL"
      | "TRANSAK_ORDER_CANCELLED"
      | "TRANSAK_ORDER_FAILED"
      | "CRYPTO_ORDER_SUCCESSFUL"
      | "CRYPTO_ORDER_FAILED"
  ) => {
    switch (status) {
      case "CRYPTO_ORDER_SUCCESSFUL": {
        const coinInfo = COINS_TO_RENDER.find(
          (item) => item.key === tokenOutSelected
        );
        const handleAddCOToken = () => {
          const { address, decimals, image, title: symbol } = coinInfo || {};
          if (address && decimals && image && symbol) {
            watchAsset({ address, decimals, image, symbol });
          }
        };
        return {
          title: "Success!",
          svg: orderCompletedImagePath,
          renderButton: () => (
            <div className="flex flex-col gap-2">
              {isMetamask && coinInfo && (
                <Button
                  onClick={handleAddCOToken}
                  filled
                  title={`Add ${coinInfo.title} to wallet`}
                />
              )}
              <Button
                onClick={() => {
                  setIsOpen(false);
                }}
                filled={!isMetamask || !coinInfo}
                title="Close!"
              />
            </div>
          ),
          getDescription: (id: string) => {
            const link = getEtherScanTXLink(id);
            return <TXLinkMessage link={link} />;
          },
        };
      }
      case "TRANSAK_ORDER_SUCCESSFUL": {
        const { status } = orderStatusData || {};
        const { walletLink, walletAddress } = status || {};
        const scanLink =
          walletLink ||
          `https://optimistic.etherscan.io/address/${walletAddress}`;
        return {
          title: "Success!",
          svg: orderCompletedImagePath,
          getDescription: (id: string) => (
            <p className="mt-2 text-center text-base">
              Your transaction{" "}
              <a
                className="text-brightGreen"
                href={scanLink}
                target="_blank"
                rel="noreferrer"
              >
                #{id}
              </a>
              <br />
              was successfully completed.
            </p>
          ),
          renderButton: () => (
            <Button
              onClick={() => {
                setIsOpen(false);

                setAction(0);
                setIsOpenModalSwap(true);
              }}
              filled
              title="Lets convert it to some COFI coins!"
            />
          ),
        };
      }
      case "TRANSAK_ORDER_CANCELLED":
      case "TRANSAK_ORDER_FAILED":
        return {
          title: "Failure(",
          svg: orderFailureImagePath,
          getDescription: (id: string) => (
            <p className="mt-2 text-center text-base">
              {id ? (
                <>
                  Unfortunately, we didnt able to proceed transaction{" "}
                  <span className="text-pink">#{id}</span>.
                </>
              ) : (
                <>Unfortunately, we didnt able to proceed your transaction.</>
              )}
            </p>
          ),
          renderButton: () => (
            <Button onClick={() => setIsOpen(false)} title="Close" filled />
          ),
        };
      case "CRYPTO_ORDER_FAILED":
        return {
          title: "Failure(",
          svg: orderFailureImagePath,
          getDescription: (id: string, error?: string) => {
            const link = getEtherScanTXLink(id);
            return (
              <>
                {link && <TXLinkMessage link={link} />}
                <p className="mt-2 text-center text-base font-normal">
                  {error?.toString()}
                </p>
              </>
            );
          },
          renderButton: () => (
            <Button onClick={() => setIsOpen(false)} title="Close" filled />
          ),
        };
    }
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const metaData = orderStatusData
    ? getMetaByOrderStatus(orderStatusData.eventName)
    : null;

  return (
    <>
      <Modal isOpen={Boolean(orderStatusData)} closeModal={closeModal}>
        <Dialog.Panel className="min-h-[280px] w-full max-w-sm transform overflow-hidden rounded-3xl bg-white px-8 py-4 text-left align-middle font-bold shadow-xl transition-all">
          <div className="mb-8 flex flex-col items-center justify-center text-2xl">
            {metaData?.svg && <img src={metaData.svg} className="mb-2 mt-3" />}
            <h1>{metaData?.title}</h1>
            {orderStatusData &&
              metaData?.getDescription?.(
                orderStatusData.status?.referenceCode || orderStatusData?.hash,
                orderStatusData?.error
              )}
          </div>
          {metaData?.renderButton()}
        </Dialog.Panel>
      </Modal>
    </>
  );
};

export default ModalTransakOrderStatus;
