import { FC, Fragment, useContext, useEffect, useState } from "react";

import { Dialog } from "@headlessui/react";
import { useAccount, useSignMessage } from "wagmi";
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { ReactComponent as Cross } from "../../../assets/icons/Cross.svg";
import apiOffChain from "../../../utils/services/apiOffChain";
import { UserOffChainData } from "../../../utils/types/global.types";
import { WalletContext } from "../../../context/Wallet.context";
import Spinner from "../../Spinner/Spinner";
import CRMService from "../../../utils/services/crm";
import { formValidator, showError } from "../../../utils/helpers/global.helper";
import Modal from "../../../components/Modal/Modal";

type ModalModifProfilProps = {
  isOpen: boolean;
  setIsOpen: Function;
};

const ModalModifProfil: FC<ModalModifProfilProps> = ({ isOpen, setIsOpen }) => {
  const [isRequesting, setIsRequesting] = useState<boolean>(false);
  const [isSubmitted, setIssSubmitted] = useState<boolean>(false);

  const { isConnected } = useAccount();
  const { signMessage } = useSignMessage({
    message: "I certify the truthfulness of the information provided.",
    async onSuccess(signature) {
      const data = { ...userData, ...(getValues() || {}) };
      try {
        apiOffChain.modifyOffChainData(data, signature);

        await CRMService.updateContact({
          firstname: data.firstName,
          lastname: data.lastName,
          email: data.email,
        });

        setIssSubmitted(true);
        setUserData(data);
      } catch (error: any) {
        setIsRequesting(false);
        showError(error.message);
      }
    },
    onError(error) {
      setIsRequesting(false);
      showError(error.message);
    },
  });

  const { kycDone, userData, setUserData } = useContext(WalletContext);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<UserOffChainData>({});

  const formHasError = !!Object.keys(errors).length;
  const onSubmit: SubmitHandler<UserOffChainData> = () => {
    setIsRequesting(true);
    signMessage();
  };

  function closeModal() {
    setIsOpen(false);
    setIsRequesting(false);
  }

  useEffect(() => {
    if (isSubmitted && kycDone) {
      setIsOpen(false);
      setIssSubmitted(false);
    }
  }, [isSubmitted, kycDone]);

  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => setIssSubmitted(false), 400);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isConnected && userData.email) {
      setValue("firstName", userData.firstName);
      setValue("lastName", userData.lastName);
      setValue("email", userData.email);
    }
  }, [userData.email, isConnected]);

  return (
    <>
      <Modal isOpen={isOpen} closeModal={closeModal}>
        <Dialog.Panel
          className={`flex ${
            kycDone ? "min-h-[140px]" : "min-h-[160px]"
          }  w-full max-w-md transform flex-col gap-4 overflow-hidden rounded-3xl bg-white text-left align-middle font-bold shadow-xl transition-all`}
        >
          <div className=" mb-2 flex items-center justify-between border-b p-5 text-base">
            <div>Modify Profile</div>
            <div
              className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-lg border border-slate-100 hover:border-slate-300"
              onClick={() => {
                closeModal();
              }}
            >
              <Cross stroke="black" height="10" width="10" />
            </div>
          </div>

          {isSubmitted && !kycDone && (
            <div className="p-5 pt-0 font-normal">
              Thank you for your modification.
              {!kycDone && (
                <span>
                  {" "}
                  Finish final step of identity verification to start using
                  COFI. &nbsp;
                  <span
                    onClick={() => {
                      navigate("/KYC");
                      closeModal();
                    }}
                    className="cursor-pointer text-brightGreen"
                  >
                    Let&apos;s go !
                  </span>
                </span>
              )}
            </div>
          )}
          {!isSubmitted && (
            <>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="text-sm font-normal"
              >
                <div className="flex flex-col gap-4 px-5 ">
                  <div className="flex flex-col gap-4 sm:flex-row sm:gap-4">
                    <label
                      className="flex flex-1 flex-col gap-2 "
                      htmlFor="firstName"
                    >
                      <div>First name*</div>
                      <input
                        placeholder="Enter firstname..."
                        type="text"
                        id="firstName"
                        className="h-[40px] w-[100%] rounded-lg border-[0.5px] border-solid border-[#00000033]  p-[10px]"
                        {...register("firstName", {
                          ...formValidator.firstName,
                        })}
                      />
                      {errors.firstName && (
                        <span className=" text-red-600">
                          {errors.firstName.message}
                        </span>
                      )}
                    </label>
                    <label
                      className="flex flex-1 flex-col gap-2"
                      htmlFor="lastName"
                    >
                      <div>Last name*</div>
                      <input
                        placeholder="Enter lastname..."
                        type="text"
                        id="lastName"
                        className="h-[40px] w-[100%] rounded-lg border-[0.5px] border-solid border-[#00000033]  p-[10px]"
                        {...register("lastName", {
                          ...formValidator.lastName,
                        })}
                      />
                      {errors.lastName && (
                        <span className=" text-red-600">
                          {errors.lastName.message}
                        </span>
                      )}
                    </label>
                  </div>
                  <label className="flex flex-col gap-2" htmlFor="Email">
                    <div>Email</div>
                    <input
                      placeholder="Enter email..."
                      type="text"
                      id="Email"
                      readOnly={!!userData?.email}
                      className={`h-[40px] w-[100%] rounded-lg border-[0.5px] border-solid border-[#00000033] p-[10px] ${
                        !!userData?.email && "text-gray-500"
                      }`}
                      {...register("email", { ...formValidator.email })}
                    />
                    {errors.email && (
                      <span className=" text-red-600">
                        {errors.email.message}
                      </span>
                    )}
                  </label>
                </div>

                <div className="mt-5 border-t">
                  <div className="text-green-400 col-span-1 flex w-full items-center justify-center gap-[8px] p-5 font-semibold  ">
                    <button
                      className={`flex h-12 w-full items-center justify-center rounded-lg ${
                        formHasError ? "bg-gray-300" : "bg-pinkLight"
                      } px-5 py-2 font-semibold text-white hover:bg-[#FF67B2] ${
                        (formHasError || isRequesting) && "pointer-events-none"
                      }`}
                      disabled={formHasError || isRequesting}
                      type="submit"
                    >
                      {isRequesting ? <Spinner /> : "Submit"}
                    </button>
                  </div>
                </div>
              </form>
            </>
          )}
        </Dialog.Panel>
      </Modal>
    </>
  );
};

export default ModalModifProfil;
