import { Magic } from "magic-sdk";
import { Chain, optimism } from "viem/chains";
import { InjectedConnector } from "@wagmi/core";

export const MagicService = new Magic("pk_live_68316234521383B7", {
  network: {
    rpcUrl: optimism.rpcUrls.default.http[0],
    chainId: optimism.id,
  },
});

export const getMagicConnector = (chains: Chain[]) =>
  new InjectedConnector({
    chains,
    options: {
      name: "Magic",
      getProvider: () => (MagicService.rpcProvider as any) || undefined,
    },
  });
