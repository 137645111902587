import { createContext, FC, ReactNode, useEffect, useState } from "react";

import { optimism } from "viem/chains";
import { useNetwork, useSwitchNetwork } from "wagmi";

type MainContextProps = {
  landingView: string;
  setLandingView: Function;
  windowWidth: number;
  showModalConnexion: boolean;
  setShowModalConnexion: Function;
  showModalForm: boolean;
  setShowModalForm: Function;
  showTransak: boolean;
  setShowTransak: Function;
  isOpenModalSwap: boolean;
  setIsOpenModalSwap: Function;
  action: 0 | 1;
  setAction: Function;
  showModalModifyForm: boolean;
  setShowModalModifyForm: Function;
  analyticsData: { clientId?: string; sessionId?: string };
  showModalTransactionDetails: boolean;
  setShowModalTransactionDetails: Function;
  transakOrderStatusDataModal: Record<string, any> | false;
  setTransakOrderStatusDataModal: Function;
  showCoffeeLoader: boolean;
  setShowCoffeeLoader: Function;
  coffeeLoaderTX: string;
  setCoffeeLoaderTX: Function;
  transactionSuccessStatusDataModal: Record<string, any> | false;
  setTransactionSuccessStatusDataModal: Function;
  showModalGetInTouch: boolean;
  setShowModalGetInTouch: Function;
};

type MainProviderProps = {
  children: ReactNode;
};

export const MainContext = createContext({} as MainContextProps);

const MainProvider: FC<MainProviderProps> = ({ children }) => {
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [landingView, setLandingView] = useState<"deposit" | "borrow">(
    "deposit"
  );
  const [showModalGetInTouch, setShowModalGetInTouch] =
    useState<boolean>(false);
  const [transakOrderStatusDataModal, setTransakOrderStatusDataModal] =
    useState<Record<string, any> | false>(false);
  const [
    transactionSuccessStatusDataModal,
    setTransactionSuccessStatusDataModal,
  ] = useState<Record<string, any> | false>(false);
  const [showModalConnexion, setShowModalConnexion] = useState<boolean>(false);
  const [showCoffeeLoader, setShowCoffeeLoader] = useState<boolean>(false);
  const [coffeeLoaderTX, setCoffeeLoaderTX] = useState<string>("");
  const [showModalForm, setShowModalForm] = useState<boolean>(false);
  const [showModalTransactionDetails, setShowModalTransactionDetails] =
    useState<boolean>(false);
  const [showModalModifyForm, setShowModalModifyForm] =
    useState<boolean>(false);
  const [showTransak, setShowTransak] = useState<boolean>(false);
  const [analyticsData, setAnalyticsData] = useState({});
  const [isOpenModalSwap, setIsOpenModalSwap] = useState<boolean>(false);
  const [action, setAction] = useState<0 | 1>(0);

  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();
  useEffect(() => {
    if (chain?.id !== 10) switchNetwork?.(optimism.id);
  }, [chain]);

  useEffect(() => {
    function handleWindowResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    const result = document.cookie
      .split("; ")
      .filter((row) => row.startsWith("_ga"))
      .map((c) => c.split("."));

    const clientId = result[0]?.slice(2)?.join(".");
    const sessionId = result[1]?.[2];

    setAnalyticsData({ clientId, sessionId });
  }, []);

  return (
    <MainContext.Provider
      value={{
        landingView,
        setLandingView,
        windowWidth,
        showModalConnexion,
        setShowModalConnexion,
        showModalForm,
        setShowModalForm,
        showTransak,
        setShowTransak,
        isOpenModalSwap,
        setIsOpenModalSwap,
        action,
        setAction,
        analyticsData,
        showModalModifyForm,
        setShowModalModifyForm,
        showModalTransactionDetails,
        setShowModalTransactionDetails,
        transakOrderStatusDataModal,
        setTransakOrderStatusDataModal,
        showCoffeeLoader,
        setShowCoffeeLoader,
        coffeeLoaderTX,
        setCoffeeLoaderTX,
        transactionSuccessStatusDataModal,
        setTransactionSuccessStatusDataModal,
        showModalGetInTouch,
        setShowModalGetInTouch,
      }}
    >
      {children}
    </MainContext.Provider>
  );
};

export default MainProvider;
