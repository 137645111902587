import { useState } from "react";

import { useAccount, useContractReads } from "wagmi";

import { addressCOBTC } from "../utils/constants/address/addressesCOFI/BTCCOFI";
import { addressCOETH } from "../utils/constants/address/addressesCOFI/ETHCOFI";
import { addressCOUSD } from "../utils/constants/address/addressesCOFI/USDCOFI";
import { DiamondContract } from "../utils/constants/wagmiConfig/wagmiConfig";
import { GlobalConstants } from "../utils/types/global.types";
import { decimalBTC } from "../utils/constants/address/wBTC";
import { decimalUSDC } from "../utils/constants/address/USDC";
import { weiToEth } from "../utils/helpers/global.helper";

const useViewDiamond = () => {
  const { isConnected, address } = useAccount();
  const [constants, setConstants] = useState<GlobalConstants>({
    feeCollectorStatus: undefined,
    points: undefined,
    redeemEnabled: undefined,
    redeemFee: undefined,
    mintEnabled: undefined,
    mintFee: undefined,
    minDepositCOUSD: undefined,
    minDepositCOETH: undefined,
    minDepositCOBTC: undefined,
    minWithdrawCOUSD: undefined,
    minWithdrawCOETH: undefined,
    minWithdrawCOBTC: undefined,
  });
  const percentageFactor = 10 ** 4;
  const arrayAddressFiTokens = [addressCOUSD, addressCOBTC, addressCOETH];

  useContractReads({
    contracts: [
      {
        ...DiamondContract,
        functionName: "getPoints",
        args: [address as `0x${string}`, arrayAddressFiTokens],
      },
      {
        ...DiamondContract,
        functionName: "getRedeemEnabled",
        args: [address as `0x${string}`],
      },
      {
        ...DiamondContract,
        functionName: "getRedeemFee",
        args: [addressCOUSD],
      },
      {
        ...DiamondContract,
        functionName: "getMintEnabled",
        args: [address as `0x${string}`],
      },
      {
        ...DiamondContract,
        functionName: "getMintFee",
        args: [addressCOUSD],
      },
      {
        ...DiamondContract,
        functionName: "getMinDeposit",
        args: [addressCOUSD as `0x${string}`],
      },
      {
        ...DiamondContract,
        functionName: "getMinDeposit",
        args: [addressCOETH as `0x${string}`],
      },
      {
        ...DiamondContract,
        functionName: "getMinDeposit",
        args: [addressCOBTC as `0x${string}`],
      },
      {
        ...DiamondContract,
        functionName: "getMinWithdraw",
        args: [addressCOUSD as `0x${string}`],
      },
      {
        ...DiamondContract,
        functionName: "getMinWithdraw",
        args: [addressCOETH as `0x${string}`],
      },
      {
        ...DiamondContract,
        functionName: "getMinWithdraw",
        args: [addressCOBTC as `0x${string}`],
      },
    ],
    watch: true,
    onSuccess(data: any) {
      setConstants({
        feeCollectorStatus: false,
        points: weiToEth(Number(data[0].result)),
        redeemEnabled: data[1].result,
        redeemFee: Number(data[2].result) / percentageFactor,
        mintEnabled: data[3].result,
        mintFee: Number(data[4].result) / percentageFactor,
        minDepositCOUSD: Number(data[5].result) / 10 ** decimalUSDC,
        minDepositCOETH: weiToEth(Number(data[6].result)),
        minDepositCOBTC: Number(data[7].result) / 10 ** decimalBTC,
        minWithdrawCOUSD: Number(data[8].result) / 10 ** decimalUSDC,
        minWithdrawCOETH: weiToEth(Number(data[9].result)),
        minWithdrawCOBTC: Number(data[10].result) / 10 ** decimalBTC,
      });
    },
    enabled: isConnected,
  });

  return {
    constants,
  };
};

export default useViewDiamond;
