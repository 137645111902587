import { useState, Fragment, useEffect, useContext, useMemo } from "react";

import { Listbox } from "@headlessui/react";
import ReactGA from "react-ga4";
import { useAccount } from "wagmi";

import { ReactComponent as Arrow } from "../../assets/icons/ArrowBlack.svg";
import useValueRef from "../../hooks/useValueRef";
import { WalletContext } from "../../context/Wallet.context";
import {
  FAQ_QUESTIONS,
  FAQ_QUESTIONS_TOPICS,
} from "../../utils/constants/general/faq";

const TOPICS: string[] = Object.values(FAQ_QUESTIONS_TOPICS);

import CardInfo from "./CardInfo/CardInfo";
import CardPreview from "./CardPreview/CardPreview";

const AboutPage = () => {
  const [showCard, setShowCard] = useState<boolean | any>(false);
  const [currentTopic, setCurrentTopic] = useState<string>(
    FAQ_QUESTIONS_TOPICS.GettingStarted
  );
  const { userData, kycDone, registrationDone } = useContext(WalletContext);
  const { connector } = useAccount();

  const filteredQuestions = useMemo(() => {
    return FAQ_QUESTIONS.filter((item) => item.topic === currentTopic);
  }, [currentTopic]);

  const connectorIdRef = useValueRef(connector?.id);
  const emailRef = useValueRef(userData?.email);
  const kycDoneRef = useValueRef(kycDone);
  const registrationDoneRef = useValueRef(registrationDone);

  useEffect(() => {
    if (currentTopic) {
      ReactGA.event("faq_clicked", {
        auth_method: connectorIdRef.current,
        email: emailRef.current,
        faq_topic: currentTopic,
        kyc_done: kycDoneRef.current,
        registration_done: registrationDoneRef.current,
        category: "User",
        action: "question_opened",
        label: "User clicked question on about page",
      });
    }
  }, [currentTopic]);

  const handleTopicChange = (newTopic: string) => {
    if (newTopic !== FAQ_QUESTIONS_TOPICS.TermsConditions) {
      setCurrentTopic(newTopic);
    }
    if (showCard) {
      setShowCard(false);
    }
  };

  return (
    <div className="flex-1 bg-bgCardNavbar py-10 pt-10">
      <div className="mx-w-[1240px] flex flex-col justify-center gap-[16px] px-5 sm:flex-row">
        <div className="">
          <div className="card flex flex-col gap-[6px] p-3 sm:w-[280px]">
            <div className="hidden sm:block">
              {TOPICS.map((topic, index) => {
                if (topic === FAQ_QUESTIONS_TOPICS.TermsConditions) {
                  const { link } =
                    FAQ_QUESTIONS.find((item) => item.topic === topic) || {};
                  return (
                    <a
                      className={`block h-[48px] cursor-pointer rounded-lg p-3 `}
                      href={link}
                      target="_blank"
                      key={index}
                      rel="noreferrer"
                    >
                      {TOPICS[index]}
                    </a>
                  );
                }

                return (
                  <Fragment key={index}>
                    <div
                      className={`h-[48px] rounded-lg ${
                        topic === currentTopic && "bg-[#0000000D]"
                      } cursor-pointer p-3 
                `}
                      onClick={() => {
                        handleTopicChange(topic);
                      }}
                      key={index}
                    >
                      {TOPICS[index]}
                    </div>
                    {index === 7 && (
                      <div className=" my-1 border-t-[0.5px] border-solid border-[#00000033]"></div>
                    )}
                  </Fragment>
                );
              })}
            </div>

            <div className="relative flex flex-col sm:hidden">
              <Listbox
                value={currentTopic}
                defaultValue={FAQ_QUESTIONS_TOPICS.GettingStarted}
                name="topic"
                onChange={handleTopicChange}
              >
                {({ open }) => (
                  <>
                    <Listbox.Button>
                      <div className="flex h-[40px] w-full items-center justify-between gap-4 rounded-lg border-[0.5px] border-gray-300 bg-[#F5F5F5] px-4">
                        {currentTopic}
                        <Arrow
                          className={` cursor-pointer ${open && "rotate-180"}`}
                        />
                      </div>
                    </Listbox.Button>
                    <Listbox.Options>
                      <div className="absolute absolute top-12 z-10 w-full rounded-2xl border bg-bgCardNavbar bg-white p-4">
                        {TOPICS.map((value) => {
                          return (
                            <Listbox.Option key={value} value={value}>
                              <div
                                className={`mt-0.5 rounded-lg p-3 text-base text-gray-700 ${
                                  value === currentTopic &&
                                  "bg-[#0000000D] text-black"
                                }`}
                              >
                                {value}
                              </div>
                            </Listbox.Option>
                          );
                        })}
                      </div>
                    </Listbox.Options>
                  </>
                )}
              </Listbox>
            </div>
          </div>
        </div>

        {!showCard ? (
          <div className="row-auto grid w-full max-w-[904px] auto-rows-min grid-cols-1 gap-[8px] md:grid-cols-2 lg:grid-cols-3">
            {filteredQuestions.map((question: any, index: number) => {
              if (!question.text?.length) {
                return null;
              }
              return (
                <CardPreview
                  key={index}
                  question={question}
                  setShowCard={setShowCard}
                />
              );
            })}
          </div>
        ) : (
          <CardInfo setShowCard={setShowCard} question={showCard} />
        )}
      </div>
    </div>
  );
};

export default AboutPage;
