import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useAccount } from "wagmi";
import { AxiosResponse } from "axios";
import { MagicUserMetadata } from "magic-sdk";

import {
  GlobalConstants,
  UserOffChainData,
  UserReferral,
} from "../utils/types/global.types";
import apiKYC from "../utils/services/apiSumsub";
import {
  ApplicantData,
  ApplicantDataApi,
  Review,
} from "../utils/types/sumsub.types";
import { getKycDone } from "../utils/helpers/global.helper";
import useViewDiamond from "../hooks/useViewDiamond";
import apiOffChain from "../utils/services/apiOffChain";

type WalletContextProps = {
  constants: GlobalConstants;
  review: Review | undefined;
  applicantExist: boolean | undefined;
  applicantData: ApplicantData | undefined;
  isOpenWallet: boolean;
  setIsOpenWallet: Function;
  kycDone: boolean | undefined;
  getApplicantData: Function;
  userData: UserOffChainData;
  resetUserData: Function;
  setUserData: Function;
  registrationDone: boolean;
  setRegistrationDone: Function;
  referrals: UserReferral[];
};

type WalletProviderProps = {
  children: ReactNode;
};

export const WalletContext = createContext({} as WalletContextProps);

const initialUserData = {
  firstName: "",
  lastName: "",
  email: "",
  marketing: false,
  signature: "",
  privacy: false,
};

const WalletProvider = ({ children }: WalletProviderProps) => {
  const [applicantData, setApplicationData] = useState<
    ApplicantData | undefined
  >();

  const [review, setReview] = useState<Review | undefined>();
  const [applicantExist, setApplicantExist] = useState<boolean | undefined>();

  const [isOpenWallet, setIsOpenWallet] = useState<boolean>(false);
  const [userData, setUserData] = useState<UserOffChainData>(initialUserData);
  const [registrationDone, setRegistrationDone] = useState<boolean>(true);

  const [referrals] = useState<UserReferral[]>([]);

  const { isConnected, address, connector } = useAccount();
  const { constants } = useViewDiamond();

  // connector.magicSDK.wallet.showUI();

  const getApplicantData = useCallback(async () => {
    try {
      const applicantData = await apiKYC.getApplicantData({
        externalUserId: address,
      });

      if (applicantData?.data) {
        checkApplicant(applicantData?.data);
      }
    } catch (error) {
      console.log("getApplicantData error: ", error);
    }
  }, [address]);

  const checkApplicant = (applicantData: ApplicantDataApi) => {
    if (applicantData.code) {
      if (applicantData.code === 400) {
        setApplicantExist(false);
      }
    } else {
      setApplicantExist(true);
      setApplicationData(applicantData);
      setReview(applicantData.review);
    }
  };

  useEffect(() => {
    if (isConnected && address) {
      getApplicantData().catch(console.log);
    }
  }, [isConnected]);

  // useEffect(() => {
  //   if (address) {
  //     apiOffChain
  //       .getReferrals(address)
  //       .then((response) => {
  //         setReferrals(response.data);
  //       })
  //       .catch(console.log);
  //   }
  // }, [address]);

  useEffect(() => {
    if (isConnected) {
      apiOffChain
        .getProfilData(address)
        .then((result: AxiosResponse<UserOffChainData, any>) => {
          const profile: UserOffChainData = result.data;

          setUserData({
            firstName: profile.firstName,
            lastName: profile.lastName,
            email: profile.email,
            marketing: false,
            signature: "",
            privacy: false,
          });

          if (!profile.firstName || !profile.lastName || !profile.email) {
            setRegistrationDone(false);
          } else {
            setRegistrationDone(true);
          }
        });
    } else {
      setUserData(initialUserData);
    }
  }, [isConnected]);

  useEffect(() => {
    if (isConnected && connector?.id === "magic" && !userData.email) {
      const magicConnector = connector as any;

      magicConnector?.magicSDK.user
        .getInfo()
        .then((data: MagicUserMetadata) => {
          if (data.email !== null) {
            setUserData(
              (prev) => ({ ...prev, email: data.email } as UserOffChainData)
            );
          }
        });
    }
  }, [isConnected, connector?.id, userData.email]);

  const kycDone: boolean | undefined = useMemo(() => {
    if (isConnected) return getKycDone(review);
  }, [review, isConnected]);

  const resetUserData = useCallback(() => {
    setUserData(initialUserData);
  }, []);

  return (
    <WalletContext.Provider
      value={{
        constants,
        review,
        applicantExist,
        applicantData,
        isOpenWallet,
        setIsOpenWallet,
        kycDone,
        getApplicantData,
        userData,
        resetUserData,
        setUserData,
        registrationDone,
        setRegistrationDone,
        referrals,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export default WalletProvider;
