import { FaqQuestion } from "../../types/global.types";

export const FAQ_QUESTIONS_TOPICS = {
  GettingStarted: "Getting Started",
  Wallet: "Wallet",
  YieldGeneration: "Yield generation",
  IdentityVerification: "Identity verification",
  CofiStablecoins: "COFI stablecoins",
  CofiPoints: "COFI points",
  Network: "Network",
  GeneralQuestions: "General questions",
  TermsConditions: "Terms & Conditions",
};

export const FAQ_QUESTIONS: FaqQuestion[] = [
  {
    title: "How to deposit?",
    topic: FAQ_QUESTIONS_TOPICS.GettingStarted,
    text: [
      "COFI Money offers 2 methods for depositing to suit your needs: depositing with a browser wallet or with your bank. For both deposit methods you will need to complete the following steps beforehand:",
    ],
    list: [
      "Click “Get Started” or “Login” on the top right-hand corner.",
      "A prompt will appear asking for your preferred login method: browser wallet or email. Select your preferred method (if you do not have a browser wallet installed, we recommend email, through our provider Magic, for an easier on-boarding process).",
      "Upon successfully connecting, you will be prompted to accept our Terms & Conditions.",
      "After accepting, you must complete Know Your Customer (KYC) verification (note that we are unable to serve users based in the United States).",
      "Upon completing, your account will be successfully whitelisted and you can proceed to your preferred method of deposit (please refer to the relevant section - “depositing with crypto assets” or “depositing via bank account” - to complete your deposit.",
    ],
    listType: "ol",
  },
  {
    title: "Depositing with crypto assets.",
    topic: FAQ_QUESTIONS_TOPICS.GettingStarted,
    text: [
      "Please ensure you have first completed the steps outlined in the “How to deposit?” section. To deposit with crypto-assets, please do the following:",
    ],
    list: [
      "Ensure you are connected by checking that your address appears in the top right-hand corner and that you are on the “Deposit” page.",
      "Under “Deposit with Wallet”, click “Proceed”.",
      "A pop-up will appear showing the available crypto-assets for depositing and the COFI stablecoins available. Please make your selection and enter the amount you wish to deposit (the estimated amount of COFI stablecoins to receive will be displayed based on this amount).",
      "If you are depositing an ERC20 token (anything besides “ETH”) you will have to first provide approval for the deposit. Please do so by clicking “Approve” and confirming the transaction in your wallet. (Please note that you should also have enough ETH in your Optimism wallet to submit transactions - this varies based on network congestion but should be at most 0.0001 ETH).",
      "You are now free to complete the deposit by clicking “Mint”. After depositing, the COFI stablecoin chosen will be credited to your account. You can see your balance by clicking on your account in the top right-hand corner. Congratulations!",
    ],
    listType: "ol",
  },
  {
    title: "Depositing via bank account.",
    topic: FAQ_QUESTIONS_TOPICS.GettingStarted,
    text: [
      "If you do not have a browser wallet installed or would simply prefer to top up from your bank, then this option is for you. Please ensure you have first completed the steps outlined in the “How to deposit?” section. Once complete, please do the following:",
    ],
    list: [
      "Ensure you are connected by checking that your address appears in the top right-hand corner and that you are on the “Deposit” page.",
      "Under “Deposit with Credit Card”, click “Proceed”.",
      "You will see a pop-up appear from our partner Transak. Please fill out the information presented, which may include an additional KYC check. This is because Transak must verify customers from their end. Enter the amount you wish to deposit in your local or preferred currency and select “ETH” as the asset to receive - this ETH will be used to make the deposit action.",
      "Upon successful completion, you will be directed to make the deposit action (you should also receive an email from Transak confirming your ETH purchase).",
      "Click the button to proceed to mint and enter the amount of ETH you wish to deposit and the COFI stablecoin you wish to receive. You will see a dollar conversion appear, so you know how much you are depositing. Congratulations!",
    ],
    listType: "ol",
  },
  {
    title: "I have deposited funds and received COFI stablecoins - now what?",
    topic: FAQ_QUESTIONS_TOPICS.GettingStarted,
    text: [
      "Congratulations! You are now ready to start earning yield. COFI stablecoins accrue yield through one of our partner venues. By holding COFI stablecoins, you will receive an automated yield across the Optimism ecosystem. COFI Money migrates assets on a weekly basis to optimize for higher rates, so you no longer have to constantly scour to get the best DeFI has to offer.",
    ],
  },
  {
    title:
      "What COFI stablecoins are available and do you plan to release more?",
    topic: FAQ_QUESTIONS_TOPICS.GettingStarted,
    text: [
      "As of launch, the COFI stablecoins available are coUSD, coETH and coBTC. COFI stablecoins mirror their underlying whilst generating a return provided by yield venues we have vetted across the Optimism ecosystem. There are currently no plans to introduce other COFI stablecoins however this is reviewed by the team on an ongoing basis.",
    ],
  },
  {
    title: "How do I fund my account?",
    topic: FAQ_QUESTIONS_TOPICS.GettingStarted,
    text: [
      "In order to get started with COFI, you should have ETH in your account to make transactions. ETH can be obtained on a variety of exchanges such as Coinbase or Kraken (we do not make any such recommendation). If you do not wish to use an exchange, you can obtain ETH by following the “Depositing via bank account” instructions in the “About” section. Lastly, if you have ETH in a wallet existing on another network, such as Ethereum or Polygon, this can be bridged over by navigating to the “Bridge section”.",
    ],
  },
  {
    title: "How do I bridge crypto-assets?",
    topic: FAQ_QUESTIONS_TOPICS.GettingStarted,
    text: [
      "If you do not have any crypto-assets in your Optimism wallet, but do so on another network such as Polygon or Ethereum, you can bridge using our partner Socket by navigating to the “Bridge” section. Simply fill out the required information, such as which crypto-asset you wish to bridge and from which network, and confirm the necessary transactions. Please note that it may take up to 10 minutes for the transaction to be completed.",
    ],
  },
  {
    title: "How do I redeem COFI stablecoins?",
    topic: FAQ_QUESTIONS_TOPICS.GettingStarted,
    text: [
      "COFI stablecoins can be redeemed any time for the crypto-asset requested or your local currency if supported. COFI Money provides two options for redeeming: to your wallet or to your bank account. For both options, you must complete the following steps:",
    ],
    list: [
      "For both options, under “Redeem with Wallet” click “Proceed” (this includes if you want to redeem to your bank, which will be explained shortly).",
      "Enter the amount of COFI stablecoins you wish to redeem and the crypto-asset to receive. If you are intending to redeem to your bank, please select ETH here.",
      "Click “Approve” to provide approval for COFI Money to move COFI stablecoins from your wallet.",
      "Following this, click “Redeem” or confirm the second transaction prompt that appears from your wallet provider.",
      "Upon successful completion, you should see the crypto-asset requested credited to your wallet. This can be seen by opening your connected wallet or clicking the person icon in the top right-hand corner to view your balances. If you wish to redeem to your bank, please proceed to the next step.",
      "Under “Redeem to Bank”, click “Proceed”. A Transak pop-up will appear asking you to provide the required information. Please continue through this form, and upon successful completion you should receive an email from Transak confirming this. Please note that, depending on your country of residence, it may take up to 5 business days to receive funds into your bank account. For more information, please contact sam@cofi.money or reach out to the Transak team.",
    ],
    listType: "ol",
  },
  {
    title: "What wallets are supported?",
    topic: FAQ_QUESTIONS_TOPICS.Wallet,
    text: [
      "COFI Money supported 4 wallets provided by external providers: MetaMask, Coinbase Wallet, WalletConnect and Magic (exclusively email).",
    ],
  },
  {
    title: "Can I access COFI without a browser wallet installed?",
    topic: FAQ_QUESTIONS_TOPICS.Wallet,
    text: [
      "Yes! This is made possible through our partner Magic. If you have not already set up a Magic wallet account, you can do so by choosing Magic as your preferred login method and creating an account from thereon.",
    ],
  },
  {
    title: "How do I get a browser wallet?",
    topic: FAQ_QUESTIONS_TOPICS.Wallet,
    text: [
      "Browser wallets, such as MetaMask, are widely available to install through extension marketplaces. Please ensure that you are installing from the correct partner. Note that we support MetaMask, Coinbase Wallet, and WalletConnect.",
    ],
  },
  {
    title: "Your personal wallet interface provided by COFI Money.",
    topic: FAQ_QUESTIONS_TOPICS.Wallet,
    text: [
      "Whilst not technically a wallet, by clicking on the person button in the top right-hand corner, a pop-up will appear displaying your crypto-assets (only those supported by COFI Money), including your COFI stablecoins. Displayed is your balance and dollar value of holdings for each asset.",
    ],
  },
  {
    title: "Which yield sources does COFI Money use?",
    topic: FAQ_QUESTIONS_TOPICS.YieldGeneration,
    text: [
      "We integrate with yield sources that have undergone multiple audits from various reputed auditors and TVL (Total Value Locked) in excess of $50m. As COFI Money is live only on Optimism network currently, these protocols also have a presence on Optimism. As of launch these are Yearn Finance, Beefy, and Sonne. As new protocols emerge that meet our safety standards, this may adapt on an ongoing basis.",
    ],
  },
  {
    title:
      "What are the benefits of using COFI Money instead of depositing directly to the yield sources?",
    topic: FAQ_QUESTIONS_TOPICS.YieldGeneration,
    text: [
      "COFI Money offers a multitude of benefits over depositing directly in the yield sources it integrates with:",
    ],
    list: [
      "Due to various factors, such as supply and demand, interest rates are constantly in flux and vary across many protocols. Therefore, in order to ensure the highest possible yields, users must proactively monitor their position relative to other rates available. This is a time-consuming process that most users either do not have time for or are unable to determine the best means for optimisation. COFI Money continuously monitors interest rates across protocols that meet our safety standards, to remove this burden and provide a one-stop shop for great rates. This is achieved by monitoring the 7-day trailing APY across protocols we have integrated with, and migrating assets accordingly on a weekly basis.",
      "Whilst by no means we guarantee complete security, COFI Money undergoes a careful vetting process of the protocols we integrate with. For users that are accepting of this, this removes the headache of having to conduct risk assessments when depositing into said protocols.",
      "COFI stablecoins may potentially return a higher amount than directly depositing into the yield venue itself. COFI stablecoins residing at accounts that have not opted in to yield, such as liquidity pools, are not eligible for earnings. Therefore, these earnings are distributed instead to eligible accounts, such as user wallets. This means that users can receive a greater amount of earnings yielded from their deposit.",
    ],
    listType: "ul",
  },
  {
    title: "How does COFI vet the yield sources?",
    topic: FAQ_QUESTIONS_TOPICS.YieldGeneration,
    text: [
      "COFI mandates that yield sources we integrate into our app meet the following criteria:",
    ],
    list: [
      "At least 3 audits have been conducted by auditors highly reputed in the industry.",
      "The protocol has a TVL (“Total Value Locked”) in excess of $50m.",
      "The protocol is upgraded through a MultiSig which is provably distributed amongst various key stakeholders.",
      "There exists ample liquidity for tokens for which we integrate with (e.g., wETH).",
      "The protocol has not suffered a loss of user funds, through whichever means (e.g., hack or mismanagement), totalling more than 2% of said protocol’s TVL within the last 12 months.",
    ],
    listType: "ol",
  },
  {
    title: "Why do I need to verify my identity?",
    topic: FAQ_QUESTIONS_TOPICS.IdentityVerification,
    text: [
      "We are committed to upholding compliance standards to meet the needs of our various stakeholders. This means verifying each user’s identity who visits our platform.",
    ],
  },
  {
    title: "Why am I being asked to provide registration details?",
    topic: FAQ_QUESTIONS_TOPICS.IdentityVerification,
    text: [
      "We ask for registration details to keep you updated on your position and any developments. You are free to unsubscribe from these through email communication or by contacting sam@cofi.money.",
    ],
  },
  {
    title: "Why am I being asked to sign a transaction when undergoing KYC?",
    topic: FAQ_QUESTIONS_TOPICS.IdentityVerification,
    text: [
      "In order to verify that you are indeed the owner of the address being provided to create an account, we require that you sign a transaction to provide this cryptographically.",
    ],
  },
  {
    title: "How long do I have to wait for my KYC submission to be approved?",
    topic: FAQ_QUESTIONS_TOPICS.IdentityVerification,
    text: [
      "Usually KYC verification takes no longer than 1-2 minutes, and you will receive an email to confirm this. If this is not the case, please reach out to sam@cofi.money and we will aim to review your case within 5 working days.",
    ],
  },
  {
    title:
      "Can I transfer COFI stablecoins to accounts that have not undergone KYC?",
    topic: FAQ_QUESTIONS_TOPICS.IdentityVerification,
    text: [
      "Yes. As a stablecoin issuer, we must conduct KYC on users minting and redeeming COFI stablecoins via our platform. Once minted, you are free to transfer COFI stablecoins to any Optimism account. Please note that, if you have received COFI stablecoins and wish to redeem via our platform, you must complete KYC and registration first.",
    ],
  },
  {
    title: "How do you ensure my identification information is protected?",
    topic: FAQ_QUESTIONS_TOPICS.IdentityVerification,
    text: [
      "Your identification information is stored securely with our partner Sumsub and does not exist on any other systems, nor under any circumstances is siad information duplicated from Sumsub.",
    ],
  },
  {
    title: "Why do I need to provide KYC information again when using Transak?",
    topic: FAQ_QUESTIONS_TOPICS.IdentityVerification,
    text: [
      "In order to facilitate fiat on/off-ramping, it is necessary for Transak to verify users from their end. We appreciate that this is another barrier for the onboarding process. This may however be a temporary measure, as we explore Transak leveraging our KYC solution to avoid duplicate verifications. Users will be notified if/when these changes take place.",
    ],
  },
  {
    title: "What are COFI stablecoins?",
    topic: FAQ_QUESTIONS_TOPICS.CofiStablecoins,
    text: [
      "COFI stablecoins are tokens that are backed 1:1 by an underlying asset residing in a partner yield venue, that automatically generate rewards for users in the form of yield and points. Rather than simply holding USDC, for example, that does not provide users with interest, users can instead convert their USDC to coUSD to receive interest earnings automagically.",
    ],
  },
  {
    title: "How frequently do I receive yield earnings?",
    topic: FAQ_QUESTIONS_TOPICS.CofiStablecoins,
    text: [
      "Yield earnings are distributed at least once per day. Your yield is received directly into your account in the form of new COFI stablecoins. For example, if you minted 1,000 coUSD which yielded 5% across 1 year, you would end up with 1,050 coUSD in your account. By navigating to the “Earnings” page, you will see a breakdown of your earnings across each COFI stablecoin you hold.",
    ],
  },
  {
    title: "What can I do with my COFI stablecoins?",
    topic: FAQ_QUESTIONS_TOPICS.CofiStablecoins,
    text: [
      "COFI stablecoins are special in that they grant the holder an automatic interest and maintain a 1:1 correlation with their underlying assets. Aside from being purely a piece for yield transmission, COFI stablecoins can be exchanged just as any other stablecoin, to conduct payments or provide liquidity on exchanges. As a team, we are actively exploring different outlets for COFI stablecoins and encourage community members to provide suggestions through our social media channels.",
    ],
  },
  {
    title: "How are COFI stablecoins pegged 1:1 to their backing assets?",
    topic: FAQ_QUESTIONS_TOPICS.CofiStablecoins,
    text: [
      "COFI stablecoins maintain a 1:1 correlation with the underlying assets residing in the relevant yield venue. For example, if there is 10,000 coUSD in Alice’s wallet, there will also exist 10,000 USDC sitting in Yearn Finance backing Alice’s coUSD. At any time, Alice can redeem her coUSD for the underlying USDC. This near-instant convertibility ensures that coUSD always stays pegged to the price of USDC, which is in turn pegged to USD through a network of liquidity pools and arbitrageurs.",
    ],
  },
  {
    title: "Can I “wrap” my COFI stablecoins?",
    topic: FAQ_QUESTIONS_TOPICS.CofiStablecoins,
    text: [
      "“Wrapping” refers to the process of converting an asset whose redemption value increases as opposed to its balance, in order to distribute yield. This can be useful for certain functions such as liquidity pools, as these do not generally support rebasing. Although wrapping can be achieved by depositing rebasing tokens into any ERC4626 Vault - “The Tokenized Vault Standard” - we will soon be launching our own proprietary vaults to meet this requirement.",
    ],
  },
  {
    title: "What is rebasing?",
    topic: FAQ_QUESTIONS_TOPICS.CofiStablecoins,
    text: [
      "COFI stablecoins are rebasing. This means that any yield harvested is distributed to eligible holders in the form of new COFI stablecoins. Technically, these new COFI stablecoins are not minted, but rather the balance of eligible holders is manipulated such that their new balance is reflective of their yield. This is akin to receiving yield, as all COFI stablecoins maintain a 1:1 correlation with their underlying asset.",
    ],
  },
  {
    title: "What are COFI Points?",
    topic: FAQ_QUESTIONS_TOPICS.CofiPoints,
    text: [
      "You can think of Points as your personal score for participating in COFI Money. Points are distributed in 2 ways:",
    ],
    list: [
      "Through earning yield. For every 1 coUSD of yield earned, users receive 100 COFI Points (specifically, 100 multiplied by 10 to the power of 18, to meet decimal requirements). A target of around 100 COFI Points per $1 of yield distributed is targeted and reflected across all COFI stablecoins.",
      "Through positive contributions. Currently, only referrals are active here, but as the protocol develops we will expand this to other means such as community contributions.",
    ],
  },
  {
    title: "What can I do with my COFI Points?",
    topic: FAQ_QUESTIONS_TOPICS.CofiPoints,
    text: [
      "We have a few ideas which we are keen to actively explore with our community. One use-case could be using Points to earn discounts at selected merchants.",
    ],
  },
  {
    title: "Why are COFI Points non-transferable?",
    topic: FAQ_QUESTIONS_TOPICS.CofiPoints,
    text: [
      "COFI Points are currently non-transferable as we want to ensure only users who have engaged with COFI in some way are eligible and to avoid giving rise to a secondary market and speculation. We also want Points to be reflective of users’ contribution and not just simply a tool to earn a quick reward for.",
    ],
  },
  {
    title: "What blockchain networks is COFI Money available on?",
    topic: FAQ_QUESTIONS_TOPICS.Network,
    text: ["Currently, COFI Money is only available on Optimism network."],
  },
  {
    title: "What is Optimism?",
    topic: FAQ_QUESTIONS_TOPICS.Network,
    text: [
      "Optimism is a layer 2 roll-up network that inherits the security of Ethereum mainnet and enables faster, cheaper transactions.",
    ],
  },
  {
    title: "How do I add the Optimism network to my wallet?",
    topic: FAQ_QUESTIONS_TOPICS.Network,
    text: [
      "If your choice of wallet does not already have Optimism added, we recommend that, using a search engine such as Google, search for “Add Optimism [Wallet]”, replacing “Wallet” with your choice of wallet (for example, MetaMask). If you have any questions, please contact sam@cofi.money.",
    ],
  },
  {
    title: "Will COFI be expanding to other networks?",
    topic: FAQ_QUESTIONS_TOPICS.Network,
    text: [
      "We review networks on an ongoing basis and will gradually add support based on user demand. We will integrate Chainlink Cross-Chain Interoperability Protocol (CCIP) to enable cross-chain transfers of COFI stablecoins between supported blockchain networks. Please stay tuned!",
    ],
  },
  {
    title: "Will you release a platform token?",
    topic: FAQ_QUESTIONS_TOPICS.GeneralQuestions,
    text: ["Currently there are no plans to release a platform token."],
  },
  {
    title: "Where can I reach support or provide feedback?",
    topic: FAQ_QUESTIONS_TOPICS.GeneralQuestions,
    text: [
      "Please feel free to reach out to us at sam@cofi.money. Conversely, you can reach out to us through one of our social media channels such as Discord or Twitter.",
    ],
  },
  {
    title: "Can I get COFI if I am a resident in the United States?",
    topic: FAQ_QUESTIONS_TOPICS.GeneralQuestions,
    text: ["Unfortunately we do not serve residents of the United States."],
  },
  {
    title: "What steps does COFI Money take to keep their system secure?",
    topic: FAQ_QUESTIONS_TOPICS.GeneralQuestions,
    text: [
      "COFI Money utilizes a MultiSig wallet provided by Gnosis - a leading blockchain tooling provider. Any updates to COFI Money must be approved by various individuals, so no one person can make breaking changes to the platform. Furthermore, COFI Money has undergone an audit which can be found in our Technical Docs.",
    ],
  },
  {
    title: FAQ_QUESTIONS_TOPICS.TermsConditions,
    topic: FAQ_QUESTIONS_TOPICS.TermsConditions,
    link: "https://www.cofi.money/kyc-policy",
  },
];
