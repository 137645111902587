import { useContext, useEffect } from "react";

import { useAccount } from "wagmi";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

import usePrevious from "../../hooks/usePrevious";
import { WalletContext } from "../../context/Wallet.context";
import useValueRef from "../../hooks/useValueRef";
import { MainContext } from "../../context/Main.context";

const GoogleAnalytics = () => {
  const { pathname } = useLocation();
  const { connector } = useAccount();
  const prevPathname = usePrevious(pathname);
  const { kycDone, registrationDone, userData } = useContext(WalletContext);
  const { analyticsData } = useContext(MainContext);

  const kycDoneRef = useValueRef(kycDone);
  const emailRef = useValueRef(userData.email);
  const registrationDoneRef = useValueRef(registrationDone);
  const connectorIdRef = useValueRef(connector?.id);
  const sessionIdRef = useValueRef(analyticsData.sessionId);

  useEffect(() => {
    if (connector?.id) {
      ReactGA.event("login", {
        ga_session_id: sessionIdRef.current,
        auth_method: connector?.id,
        category: "User",
        action: "login",
        label: "User Login",
      });
    }
  }, [connector?.id]);

  useEffect(() => {
    if (prevPathname === "/KYC" && connector?.id && !kycDoneRef.current) {
      ReactGA.event("exit_kyc", {
        auth_method: connector?.id,
        ga_session_id: sessionIdRef.current,
        email: emailRef.current,
        category: "User",
        action: "exit_kyc_process",
        label: "User dropped of KYC screen ",
      });
    }
  }, [prevPathname, connector?.id]);

  useEffect(() => {
    if (pathname) {
      ReactGA.event("page_changed", {
        auth_method: connectorIdRef.current,
        kyc_done: kycDoneRef.current,
        registration_done: registrationDoneRef.current,
        ga_session_id: sessionIdRef.current,
        email: emailRef.current,
        page_location: window.location.href,
        page_title: document.title,
        category: "User",
        action: "change_screen",
        label: "User changed page",
      });
    }
  }, [pathname]);

  return <></>;
};

export default GoogleAnalytics;
