import {
  FC,
  // useContext
} from "react";

import { Link } from "react-router-dom";
// import { useAccount } from "wagmi";

import { ReactComponent as CofiFooter } from "../../assets/logos/COFILogo.svg";
import { ReactComponent as TwiterLogo } from "../../assets/logos/TwiterLogo.svg";
import { ReactComponent as DisordLogo } from "../../assets/logos/DiscordLogo.svg";
import { ReactComponent as MediumLogo } from "../../assets/logos/MediumLogo.svg";
// import { MainContext } from "../../context/Main.context";

const Footer: FC = () => {
  // const { setShowModalGetInTouch } = useContext(MainContext);
  // const { isConnected } = useAccount();

  // const handleGetInTouchButtonClick = () => {
  //   setShowModalGetInTouch(true);
  // };

  return (
    <div className=" mt-auto bg-darkgreen">
      {/* {!isConnected && (
        <div className="flex flex-row bg-brightYellow p-5">
          <div className="mx-auto flex w-full max-w-[1200px] flex-col items-center justify-between sm:flex-row">
            <p className="mb-5 mr-0 text-center sm:mb-0 sm:mr-5 sm:text-left">
              We are making finishing touches to COFI. Please leave your email
              and we will get in touch when we are ready for you to give us a
              try!
            </p>
            <button
              className="flex h-12 min-w-[200px] items-center justify-center rounded-lg bg-black px-5 py-2 font-semibold text-white"
              onClick={handleGetInTouchButtonClick}
            >
              Register your interest
            </button>
          </div>
        </div>
      )} */}
      <div className="mx-auto w-full max-w-[1200px] px-4 pb-[24px] pt-[40px] sm:px-5">
        <div className="flex flex-col sm:gap-12">
          <div className="flex flex-col justify-between sm:flex-row">
            <div className=" flex flex-col gap-6 sm:max-w-[255px]">
              <div className="flex flex-col gap-4">
                <Link to="/Deposit" className="w-fit">
                  <CofiFooter />
                </Link>
                <div className="pr-4 text-xs font-normal text-white sm:pr-0 sm:text-sm">
                  <p>
                    COFI is a yield-generation platform powered by our 1:1
                    backed stablecoins. We believe in helping our customers make
                    their crypto work harder for them in a compliant safe
                    environment.
                  </p>
                  <p>Join us!</p>
                </div>
              </div>
              <div className="mt-4 flex flex-row items-center gap-4 sm:mt-0">
                <a
                  href="https://twitter.com/CofiMoney"
                  target="_blank"
                  rel="noreferrer"
                >
                  <TwiterLogo className="cursor-pointer" />
                </a>
                <a
                  href="https://discord.gg/fynM9sFh"
                  target="_blank"
                  rel="noreferrer"
                >
                  <DisordLogo className="cursor-pointer" />
                </a>
                <a
                  href="https://cofimoney.medium.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <MediumLogo className="w-[24px] cursor-pointer" />
                </a>
              </div>
            </div>

            <div className="mt-8 flex flex-row flex-wrap justify-center gap-x-10 gap-y-4 py-2 text-base font-medium text-[#FFFFFFB2] sm:mt-0">
              <div className="flex flex-col">
                <a
                  className="px-2 py-1 hover:text-gray-200"
                  href="https://docs.cofi.money/"
                  target="_blank"
                  rel="noreferrer"
                >
                  User Docs
                </a>
                <a
                  className="px-2 py-1 hover:text-gray-200"
                  href="https://docs.cofi.money/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Technical Docs
                </a>
                <a
                  className="px-2 py-1 hover:text-gray-200"
                  href="https://github.com/Cofi-Money"
                  target="_blank"
                  rel="noreferrer"
                >
                  GitHub
                </a>
              </div>
              <div className="mb-10 flex flex-col sm:mb-0 sm:items-start">
                <a
                  className="px-2 py-1 hover:text-gray-200"
                  href="https://www.cofi.money"
                  target="_blank"
                  rel="noreferrer"
                >
                  Partners
                </a>
                <a
                  className="px-2 py-1 hover:text-gray-200"
                  href="https://ethereum.org/en/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Ethereum
                </a>
              </div>
            </div>
            <div className="borderTop flex items-center justify-between py-2 text-white sm:hidden">
              <div className="opacity-40">Contact us at</div>
              <a
                className="cursor-pointer p-2 text-pinkLight underline sm:text-white sm:no-underline sm:opacity-70"
                href="mailto:sam@cofi.money"
              >
                sam@cofi.money
              </a>
            </div>
          </div>

          <div className="borderTop flex flex-col-reverse items-center justify-between gap-2 pt-6 text-sm font-normal sm:flex-row">
            <div className="text-white opacity-40">
              Copyright © 2023 Cofi. All rights reserved.
            </div>
            <a
              className="text-white opacity-40 sm:opacity-70"
              href="https://www.cofi.money/terms-of-use"
              target="_blank"
              rel="noreferrer"
            >
              Terms and Conditions
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
