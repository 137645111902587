import { Coins, FITokens } from "../types/swap.types";

export const getTotalBalance = (
  balanceCoins: FITokens | undefined,
  pricesCoins: Coins
) => {
  try {
    if (balanceCoins !== undefined) {
      return (
        balanceCoins.COUSD * pricesCoins.COUSD +
        balanceCoins.COETH * pricesCoins.COETH +
        balanceCoins.COBTC * pricesCoins.COBTC
      );
    } else {
      return 0;
    }
  } catch (error) {
    throw new Error("getTotalBalance failed : " + error);
  }
};

export const getTotalDeposit = (arrayDeposit: FITokens, pricesCoins: Coins) => {
  try {
    return (
      arrayDeposit.COUSD * pricesCoins.COUSD +
      arrayDeposit.COETH * pricesCoins.COETH +
      arrayDeposit.COBTC * pricesCoins.COBTC
    );
  } catch (error) {
    throw new Error("getTotalBalance failed : " + error);
  }
};

export const getEatchFIBalance = (
  balanceCoins: FITokens | undefined,
  pricesCoins: Coins
) => {
  try {
    if (balanceCoins !== undefined) {
      return {
        COUSD: balanceCoins.COUSD * pricesCoins.COUSD,
        COETH: balanceCoins.COETH * pricesCoins.COETH,
        COBTC: balanceCoins.COBTC * pricesCoins.COBTC,
      };
    } else {
      return {
        COUSD: 0,
        COETH: 0,
        COBTC: 0,
      };
    }
  } catch (error) {
    throw new Error("getEatchFIBalance failed : " + error);
  }
};

export const getDailyYield = (
  balanceFi: number,
  apy: number,
  price: number
) => {
  try {
    return ((balanceFi * apy) / 365) * price;
  } catch (error) {
    throw new Error("getDailyYield failed : " + error);
  }
};

export const getEarnings = (balanceFi: number, deposit: number) => {
  try {
    return balanceFi - deposit < 0.1 ? 0 : balanceFi - deposit;
  } catch (error) {
    throw new Error("getEarnings failed : " + error);
  }
};

export const getYield = (getTotalBalance: number, deposit: number) => {
  try {
    return getTotalBalance - deposit;
  } catch (error) {
    throw new Error("getYield failed : " + error);
  }
};
export const getPercentageEarned = (
  getTotalBalance: number,
  deposit: number
) => {
  try {
    return (getTotalBalance - deposit) / getTotalBalance;
  } catch (error) {
    throw new Error("getPercentageEarned failed : " + error);
  }
};
