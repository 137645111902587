import { useContext, useEffect } from "react";

import { useNavigate, Link } from "react-router-dom";
import { useAccount } from "wagmi";

import { ReactComponent as COFILogo } from "../../assets/logos/COFILogo.svg";
import { ReactComponent as Swap } from "../../assets/icons/swap.svg";
import { ReactComponent as BridgeLogo } from "../../assets/icons/bridge.svg";
import { ReactComponent as VaultsLogo } from "../../assets/icons/Frame.svg";
import { ReactComponent as AboutLogo } from "../../assets/icons/Vector-2.svg";
import { ReactComponent as COFIPointLogo } from "../../assets/logos/COFIPointLogo.svg";
import { ReactComponent as ETHLogo } from "../../assets/logos/optimism-logo.svg";
import Dropdown from "../Dropdown/Dropdown";
import HeaderDropdown from "../Dropdown/HeaderDropdown";
import { MainContext } from "../../context/Main.context";

const NAVIGATION_LINKS = [
  {
    link: "/Deposit",
    title: "Deposit",
    icon: Swap,
  },
  {
    link: "/Earnings",
    title: "Earnings",
    icon: VaultsLogo,
  },
  {
    link: "/Points",
    title: "Points",
    icon: COFIPointLogo,
  },
  {
    link: "/About",
    title: "About",
    icon: AboutLogo,
  },
  {
    link: "/Bridge",
    title: "Bridge",
    icon: BridgeLogo,
    fill: true,
  },
];

const Header = () => {
  const navigate = useNavigate();
  const { isConnected } = useAccount();
  const { setShowModalConnexion } = useContext(MainContext);

  useEffect(() => {
    if (!isConnected) {
      navigate("/Deposit");
    }
  }, [isConnected]);

  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/Deposit");
    }
  }, [location.pathname]);

  return (
    <div
      className={`relative grid h-16 grid-cols-2 border-b-[0.5px] border-b-borderBottomConnectedCard bg-darkgreen px-2 sm:px-6 ${
        isConnected ? "lg:grid-cols-3" : "md:grid-cols-3"
      }`}
    >
      <div className="col-span-1 flex w-[80px] items-center gap-2 sm:w-auto">
        <Link to="/Deposit">
          <COFILogo />
        </Link>
      </div>

      <div
        className={`hidden items-center justify-center gap-[12px] space-x-2 ${
          isConnected ? "lg:flex" : "md:flex"
        }`}
      >
        {NAVIGATION_LINKS.map(({ link, title, icon: Icon, fill }) => {
          if ("/Earnings" === link && !isConnected) {
            return null;
          }

          const handleLinkClick = () => {
            if (link === "/Bridge" && isConnected) {
              //   We need to reinit the bridge module on every page mount (to update the default params)
              window.location.replace("/Bridge");
            } else {
              navigate(link);
            }
          };

          const isActive = location.pathname === link;
          const iconColor = isActive ? "#EF2A89" : "white";

          const iconStyle = fill ? { fill: iconColor } : { stroke: iconColor };

          return (
            <div
              key={title}
              className="relative flex h-[100%] cursor-pointer items-center gap-[8px]"
              onClick={handleLinkClick}
            >
              <Icon {...iconStyle} />
              <div className={`${isActive ? "text-pink" : "text-white"}`}>
                {title}
              </div>
              {isActive && (
                <span className="absolute bottom-0 w-full rounded-2xl border-b-[3px] border-b-pink" />
              )}
            </div>
          );
        })}
      </div>

      <div className="col-span-1 flex items-center justify-end gap-[8px]">
        <HeaderDropdown links={NAVIGATION_LINKS} />
        {isConnected && (
          <div
            className={`hidden rounded-lg border bg-bgCardNavbar p-2 ${
              isConnected ? "lg:block" : "md:block"
            }`}
          >
            <ETHLogo />
          </div>
        )}
        {!isConnected && (
          <div
            onClick={() => setShowModalConnexion(true)}
            className="ml-3 hidden w-fit cursor-pointer items-center justify-center whitespace-nowrap rounded-lg bg-pinkLight px-4 py-2 text-base font-semibold text-white hover:bg-[#FF67B2] hover:text-opacity-100 md:block"
          >
            Get started
          </div>
        )}
        <Dropdown />
      </div>
    </div>
  );
};

export default Header;
