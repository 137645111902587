import { FC } from "react";

import { Dialog } from "@headlessui/react";
import moment from "moment/moment";

import { ReactComponent as Cross } from "../../../assets/icons/Cross.svg";
import { Chain, SocketTransaction } from "../../../utils/types/bridge.types";
import AssetItem from "../../../pages/BridgePage/Transaction/AssetItem/AssetItem";
import { getTransactionStatus } from "../../../utils/helpers/global.helper";
import Modal from "../../../components/Modal/Modal";

type ModalTransactionDetailsProps = {
  isOpen: boolean;
  setIsOpen: Function;
  transaction: SocketTransaction;
  chains?: Chain[];
};

const ModalTransactionDetails: FC<ModalTransactionDetailsProps> = ({
  isOpen,
  setIsOpen,
  transaction,
  chains,
}) => {
  const { userTxs, createdAt } = transaction || {};
  const transactionData = userTxs?.[0];

  if (!transactionData) {
    return null;
  }

  const createdAtRender = moment(createdAt).format("DD MMM YY, HH:mm");
  const protocol =
    transactionData.protocol || transactionData?.steps?.[0]?.protocol;

  const { TIcon, TStatus, TColor } = getTransactionStatus(
    transaction.routeStatus
  );

  const closeModal = () => setIsOpen(false);

  return (
    <Modal isOpen={isOpen} closeModal={closeModal}>
      <Dialog.Panel className="card w-[500px] transform text-left align-middle shadow-xl transition-all ">
        <Dialog.Title
          as="h3"
          className="flex flex-row items-center gap-2 border-b-[0.5px] border-solid border-borderCardAbout px-6 py-3 text-lg font-medium leading-6 text-gray-900"
        >
          <TIcon className={`h-6 w-6 fill-${TColor}`} />
          <p className="text-center text-xl">{TStatus}</p>
          <Cross
            stroke="black"
            className="ml-auto cursor-pointer"
            onClick={() => setIsOpen(false)}
          />
        </Dialog.Title>
        <div className="px-6 py-6">
          <div className="flex flex-wrap gap-4">
            <div className="flex min-w-[130px] flex-1 flex-col">
              <span className="mb-2">From</span>
              <AssetItem
                asset={transaction.fromAsset}
                amount={transaction.fromAmount}
                decimals={transaction.fromAsset?.decimals}
                chains={chains}
              />
            </div>
            <div className="flex min-w-[130px] flex-1 flex-col">
              <span className="mb-2">To</span>
              <AssetItem
                asset={transaction.toAsset}
                amount={transaction.toAmount}
                decimals={transaction.toAsset?.decimals}
                chains={chains}
              />
            </div>
          </div>
          <div className="relative mt-4 flex flex-wrap items-center gap-2 rounded-xl border-[0.5px] p-3 pr-24">
            <span>Via</span>
            <img
              src={protocol?.icon}
              className="h-6 w-6 rounded-[50%] sm:h-7 sm:w-7"
            />
            <span className="text-sm font-bold leading-4">
              {protocol?.displayName}
            </span>
            <span className="text-xs font-bold leading-4 text-gray-500">
              {createdAtRender}
            </span>
            {transaction?.bridgeTxHash && (
              <a
                className="absolute right-4 rounded-xl bg-pinkLight px-2 py-[2px] text-sm text-white"
                href={`https://optimistic.etherscan.io/tx/${transaction?.bridgeTxHash}`}
                target="_blank"
                rel="noreferrer"
              >
                View TX
              </a>
            )}
          </div>
        </div>
      </Dialog.Panel>
    </Modal>
  );
};

export default ModalTransactionDetails;
