import { FC, ReactNode, Fragment } from "react";

import { Dialog, Transition } from "@headlessui/react";

type ModalProps = {
  isOpen: boolean;
  dialogClassname?: string;
  childContainerId?: string;
  closeModal: (value: boolean) => void;
  children: ReactNode;
};

const Modal: FC<ModalProps> = ({
  isOpen,
  dialogClassname,
  childContainerId,
  closeModal,
  children,
}) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={
          "relative z-10" + (dialogClassname ? ` ${dialogClassname}` : "")
        }
        onClose={closeModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto bg-gray800 bg-opacity-30">
          <div
            className="flex min-h-full items-center justify-center p-4 text-center"
            id={childContainerId}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              {children}
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
