import { ReactNode, FC } from "react";

import WalletProvider from "../context/Wallet.context";
import MainProvider from "../context/Main.context";
import SwapProvider from "../context/Swap.context";
import APYProvider from "../context/APY.context";

type ProvidersProps = {
  children: ReactNode;
};

const Providers: FC<ProvidersProps> = ({ children }) => {
  return (
    <MainProvider>
      <APYProvider>
        <WalletProvider>
          <SwapProvider>{children}</SwapProvider>
        </WalletProvider>
      </APYProvider>
    </MainProvider>
  );
};

export default Providers;
