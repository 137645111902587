import { FC } from "react";

import { Dialog } from "@headlessui/react";

import { ReactComponent as Cross } from "../../../assets/icons/Cross.svg";
import Modal from "../../../components/Modal/Modal";
import CoffeeLoader from "../../../assets/illustrations/CoffeeLoader";
import { getEtherScanTXLink } from "../../../utils/helpers/global.helper";

type ModalVaultProps = {
  isOpen: boolean;
  setIsOpen: Function;
  hash?: string;
};

const ModalCoffeeLoader: FC<ModalVaultProps> = ({
  isOpen,
  setIsOpen,
  hash,
}) => {
  const closeModal = () => {
    setIsOpen(false);
  };

  const link = hash ? getEtherScanTXLink(hash) : "";

  return (
    <Modal isOpen={isOpen} closeModal={closeModal}>
      <Dialog.Panel className="card w-full max-w-sm transform overflow-hidden text-left align-middle shadow-xl transition-all">
        <div className="relative flex flex-col items-center justify-center border-b-[0.5px] border-solid border-borderCardAbout p-6 pt-10">
          <div
            className="absolute right-5 top-5 flex h-8 w-8 cursor-pointer items-center justify-center rounded-lg border border-slate-100 hover:border-slate-300"
            onClick={() => {
              closeModal();
            }}
          >
            <Cross stroke="black" height="10" width="10" />
          </div>
          <CoffeeLoader />
          <div className="mt-8 text-center text-[#1F2029]">
            {link && (
              <p className="mb-4">
                Follow the
                <a
                  href={link}
                  className="mx-1 cursor-pointer text-pink underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  link
                </a>
                to check status on block explorer.
              </p>
            )}
            <h3 className="text-xl font-bold">Waiting for confirmation</h3>
            <p className="mt-2 text-sm">
              Confirm this transaction in your wallet
            </p>
          </div>
        </div>
      </Dialog.Panel>
    </Modal>
  );
};

export default ModalCoffeeLoader;
