import { useContext, useEffect, useState } from "react";

import {
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";

import { SwapContext } from "../context/Swap.context";
import { CoinsString } from "../utils/types/swap.types";
import { addressDiamond } from "../utils/constants/address/Diamond";
import { abi } from "../utils/constants/abi/CofiAndWAddresses";
import { MainContext } from "../context/Main.context";

const useApprove = (cryptoAmount: bigint, refetch: Function) => {
  const [skipApproval, setSkipApproval] = useState(false);

  const { tokenInSelected, tokenOutSelected, addressesTokens } =
    useContext(SwapContext);

  const { setCoffeeLoaderTX, setTransakOrderStatusDataModal } =
    useContext(MainContext);

  const { data: allowanceData }: { data?: bigint } = useContractRead({
    abi,
    address: addressesTokens[
      tokenInSelected as keyof CoinsString
    ] as `0x${string}`,
    functionName: "allowance",
    enabled: Boolean(
      cryptoAmount !== BigInt(0) && tokenInSelected && tokenOutSelected
    ),
    args: [
      addressesTokens[tokenInSelected as keyof CoinsString],
      addressesTokens[tokenOutSelected as keyof CoinsString],
    ],
  });

  const { config: configApprove } = usePrepareContractWrite({
    abi,
    address: addressesTokens[
      tokenInSelected as keyof CoinsString
    ] as `0x${string}`,
    functionName: "approve",
    args: [addressDiamond, cryptoAmount],
    enabled: cryptoAmount !== BigInt(0),
    onError(error) {
      console.log("Error approve", error);
    },
  });

  const {
    data: approveData,
    write: writeApprove,
    reset,
    error,
    isLoading,
  } = useContractWrite(configApprove);

  const {
    isLoading: isLoadingApprove,
    isSuccess: isSuccessApprove,
    isError: isErrorApprove,
  } = useWaitForTransaction({
    hash: approveData?.hash,
    cacheTime: 0,
  });

  useEffect(() => {
    if (
      (allowanceData && cryptoAmount && allowanceData >= cryptoAmount) ||
      tokenInSelected === "ETH"
    ) {
      setSkipApproval(true);
    } else {
      setSkipApproval(false);
    }
  }, [allowanceData, cryptoAmount, tokenInSelected, tokenOutSelected]);

  useEffect(() => {
    setSkipApproval(false);

    if (isSuccessApprove) {
      refetch();

      reset();
    }

    if (isErrorApprove) {
      const message =
        error?.message.split("Contract Call")?.[0]?.trim?.() || error;

      setTransakOrderStatusDataModal({
        id: "APPROVE_FAILED",
        eventName: "CRYPTO_ORDER_FAILED",
        hash: approveData?.hash,
        error: message,
      });
    }
  }, [isSuccessApprove, isLoadingApprove, isErrorApprove]);

  useEffect(() => {
    setCoffeeLoaderTX(approveData?.hash);
  }, [approveData?.hash]);

  return {
    approveSuccess: isSuccessApprove,
    skipApproval,
    writeApprove,
    error,
    isApproveLoading: isLoading || isLoadingApprove,
  };
};
export default useApprove;
