import { useState } from "react";

import { useContractReads } from "wagmi";
import moment from "moment";
import { bignumber as MathBigNumber } from "mathjs";

import { addressRCPT } from "../utils/constants/address/rcpt";
import { abiRCPT } from "../utils/constants/abi/RCPT";
import { ApyDataType } from "../context/APY.context";

type RCPTDataType = bigint[];

type UseApyPercentageDataConfigType = {
  address: string;
  period: number;
}[];

// const points = 7;

const getPercentage = (data: RCPTDataType, days: number): number => {
  const rcptA = MathBigNumber(Number(data[0]));
  const rcptB = MathBigNumber(Number(data[1]));

  const value =
    rcptA
      .div(rcptB)
      .pow(365.25 / days)
      .toNumber() - 1;

  return value;
};

const getPointPeriod = (index: number, period: number) => {
  const slots = Array(period + 1).fill("");

  return index === slots.length - 1 ? 1 : period - index;
};

export const useApyPercentageData = (
  config: UseApyPercentageDataConfigType
): ApyDataType => {
  const [data, setData] = useState({});

  const parseConfig = (config: UseApyPercentageDataConfigType) => {
    return config.flatMap(({ address, period }) => {
      return Array(period + 1)
        .fill("")
        .map((item, index) => ({
          address: addressRCPT as `0x${string}`,
          abi: abiRCPT as any,
          functionName: "getRebasingCreditsPerToken",
          args: [address, getPointPeriod(index, period)],
        }));
    });
  };

  useContractReads({
    contracts: parseConfig(config),
    cacheTime: moment().endOf("day").diff(moment(), "millisecond"),
    onSuccess: (data: { result: RCPTDataType }[]) => {
      const rawData: Record<number, Record<string, number>> = {};

      config.map((configItem, configIndex) => {
        Array(configItem.period + 1)
          .fill("")
          .map((pointsItem, pointsIndex) => {
            const index = configIndex + pointsIndex;
            const percentage = getPercentage(
              data[index].result,
              getPointPeriod(pointsIndex, configItem.period)
            );

            rawData[configItem.period] = {
              ...(rawData[configItem.period] || {}),
              [moment()
                .subtract(getPointPeriod(pointsIndex, configItem.period), "day")
                .format("DD/MM/YYYY")]: percentage,
            };

            setData(rawData);
          });
      });
    },
  });

  return data;
};
