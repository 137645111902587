import { useState } from "react";

import { useAccount, useContractReads } from "wagmi";

import { abi } from "../utils/constants/abi/CofiAndWAddresses";
import { addressCOETH } from "../utils/constants/address/addressesCOFI/ETHCOFI";
import { addressCOUSD } from "../utils/constants/address/addressesCOFI/USDCOFI";
import { addressCOBTC } from "../utils/constants/address/addressesCOFI/BTCCOFI";
import { BalanceCoins } from "../utils/types/swap.types";

const useViewDiamond = () => {
  const { isConnected, address } = useAccount();

  const [yieldEarned, setYieldEarned] = useState<
    Pick<BalanceCoins, "COUSD" | "COBTC" | "COETH">
  >({
    COUSD: BigInt(0),
    COETH: BigInt(0),
    COBTC: BigInt(0),
  });

  useContractReads({
    contracts: [
      {
        abi: abi as any,
        functionName: "getYieldEarned",
        address: addressCOUSD as `0x${string}`,
        args: [address as `0x${string}`],
      },
      {
        abi: abi as any,
        functionName: "getYieldEarned",
        address: addressCOETH as `0x${string}`,
        args: [address as `0x${string}`],
      },
      {
        abi: abi as any,
        functionName: "getYieldEarned",
        address: addressCOBTC as `0x${string}`,
        args: [address as `0x${string}`],
      },
    ],
    onSuccess(data: any) {
      setYieldEarned({
        COUSD: data[0]?.result || BigInt(0),
        COETH: data[1]?.result || BigInt(0),
        COBTC: data[2]?.result || BigInt(0),
      });
    },
    enabled: isConnected,
  });

  return {
    yieldEarned,
  };
};

export default useViewDiamond;
