import {
  createContext,
  FC,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useAccount } from "wagmi";
import { ethers } from "ethers";

import { Optional } from "../utils/types/global.types";
import {
  CoinsString,
  Coins,
  TokenSelected,
  BalanceCoins,
  FITokens,
} from "../utils/types/swap.types";
// import { addressDai } from "../utils/constants/address/Dai";
// import { addressETH } from "../utils/constants/address/ETH";
import { addressUSDC, decimalUSDC } from "../utils/constants/address/USDC";
import { addresswETH, decimalETH } from "../utils/constants/address/wETH";
import { addresswBTC, decimalBTC } from "../utils/constants/address/wBTC";
import { addressCOUSD } from "../utils/constants/address/addressesCOFI/USDCOFI";
import {
  addressCOETH,
  decimalsFI,
} from "../utils/constants/address/addressesCOFI/ETHCOFI";
import { addressCOBTC } from "../utils/constants/address/addressesCOFI/BTCCOFI";
import { getPrices } from "../utils/helpers/swap.helpers";
import useBalancesCoins from "../hooks/useBalancesCoins";
import { bigIntToDecimal } from "../utils/helpers/global.helper";
import { addressDAI, decimalDAI } from "../utils/constants/address/Dai";
import { addressUSDT, decimalUSDT } from "../utils/constants/address/USDT";

type SwapContextProps = {
  tokenInSelected: TokenSelected | null;
  setTokenInSelected: Function;
  tokenOutSelected: TokenSelected | null;
  setTokenOutSelected: Function;
  addressesTokens: Optional<CoinsString, "ETH">;
  decimalsTokens: Coins;
  pricesCoins: Coins;
  balanceCoins: Optional<BalanceCoins, "ETH">;
  convertTokenList: Optional<CoinsString, "ETH">;
  balanceCoinsFormatted: FITokens;
};

type SwapProviderProps = {
  children: ReactNode;
};

export const addressesTokens: CoinsString = {
  DAI: addressDAI,
  USDT: addressUSDT,
  ETH: ethers.constants.AddressZero,
  USDC: addressUSDC,
  wETH: addresswETH,
  wBTC: addresswBTC,
  COUSD: addressCOUSD,
  COETH: addressCOETH,
  COBTC: addressCOBTC,
};

export const decimalsTokens: Coins = {
  DAI: decimalDAI,
  USDT: decimalUSDT,
  COUSD: decimalsFI,
  USDC: decimalUSDC,
  ETH: decimalETH,
  wETH: decimalETH,
  wBTC: decimalBTC,
  COETH: decimalsFI,
  COBTC: decimalsFI,
};

export const convertTokenList: Optional<CoinsString, "ETH"> = {
  USDC: "COUSD",
  wETH: "COETH",
  wBTC: "COBTC",
  DAI: "COUSD",
  USDT: "COUSD",
  COUSD: "USDC",
  COETH: "wETH",
  COBTC: "wBTC",
};

export const SwapContext = createContext({} as SwapContextProps);

const PRICES_REFETCH_DELAY = 1000 * 60;

const SwapProvider: FC<SwapProviderProps> = ({ children }) => {
  const [tokenInSelected, setTokenInSelected] = useState<TokenSelected | null>(
    null
  );
  const [tokenOutSelected, setTokenOutSelected] =
    useState<TokenSelected | null>(null);

  const [pricesCoins, setPricesCoins] = useState<Coins>({
    DAI: 0,
    USDT: 0,
    COUSD: 0,
    USDC: 0,
    wETH: 0,
    wBTC: 0,
    ETH: 0,
    COETH: 0,
    COBTC: 0,
  });

  const { isConnected } = useAccount();
  const { balanceCoins } = useBalancesCoins();

  const balanceCoinsFormatted = useMemo(() => {
    return {
      COUSD: bigIntToDecimal(balanceCoins.COUSD, decimalsTokens["COUSD"]) || 0,
      COETH: bigIntToDecimal(balanceCoins.COETH, decimalsTokens["COETH"]) || 0,
      COBTC: bigIntToDecimal(balanceCoins.COBTC, decimalsTokens["COBTC"]) || 0,
    };
  }, [balanceCoins]);

  useEffect(() => {
    let timerId: ReturnType<typeof setInterval>;

    const fetchPrices = async () => {
      getPrices().then((prices: Coins) => {
        setPricesCoins(prices);
      });
    };

    if (isConnected) {
      fetchPrices().catch(console.log);
      timerId = setInterval(fetchPrices, PRICES_REFETCH_DELAY);
    }
    return () => {
      clearInterval(timerId);
    };
  }, [isConnected]);

  return (
    <SwapContext.Provider
      value={{
        tokenInSelected,
        setTokenInSelected,
        tokenOutSelected,
        setTokenOutSelected,
        addressesTokens,
        decimalsTokens,
        pricesCoins,
        balanceCoins,
        convertTokenList,
        balanceCoinsFormatted,
      }}
    >
      {children}
    </SwapContext.Provider>
  );
};

export default SwapProvider;
