import { FC } from "react";

import { Dialog } from "@headlessui/react";

import Modal from "../../../components/Modal/Modal";
import orderCompletedImagePath from "../../../assets/illustrations/OrderCompleted.svg";

type ModalTransactionSuccessStatusProps = {
  transactionDetails: Record<string, any> | false;
  setIsOpen: Function;
};

const ModalTransactionSuccessStatus: FC<ModalTransactionSuccessStatusProps> = ({
  transactionDetails,
  setIsOpen,
}) => {
  const closeModal = () => {
    setIsOpen(false);
  };

  const handleButtonClick = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Modal isOpen={Boolean(transactionDetails)} closeModal={closeModal}>
        <Dialog.Panel className="min-h-[280px] w-full max-w-sm transform overflow-hidden rounded-3xl bg-white px-8 py-4 text-left align-middle font-bold shadow-xl transition-all">
          <div className="mb-8 flex flex-col items-center justify-center text-2xl">
            <img src={orderCompletedImagePath} className="mb-2 mt-3" />
            <h1>Success!</h1>
            {transactionDetails && (
              <>
                <p className="mt-2 text-center text-base">
                  Your transaction{" "}
                  <a
                    className="text-brightGreen"
                    href={`https://optimistic.etherscan.io/tx/${transactionDetails.hash}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {transactionDetails.hash?.slice(-5)}...
                    {transactionDetails.hash?.slice(-5)}
                  </a>
                  <br />
                  was successfully completed.
                </p>
              </>
            )}
          </div>
          <button
            onClick={handleButtonClick}
            className={
              "flex h-12 w-full items-center justify-center rounded-lg bg-[#FF87B2] px-5 py-2 font-semibold text-white hover:bg-[#FF67B2]"
            }
          >
            Done
          </button>
        </Dialog.Panel>
      </Modal>
    </>
  );
};

export default ModalTransactionSuccessStatus;
