import { useContext, useMemo, useEffect, useState } from "react";

import SumsubWebSdk from "@sumsub/websdk-react";
import { useAccount, useSignMessage } from "wagmi";
import { Link } from "react-router-dom";

import { WalletContext } from "../../context/Wallet.context";
import apiKYC from "../../utils/services/apiSumsub";
import { MainContext } from "../../context/Main.context";
import Spinner from "../../components/Spinner/Spinner";
import { ReactComponent as SuccessIcon } from "../../assets/icons/Validate.svg";

const KYC = () => {
  const { applicantExist, kycDone, getApplicantData } =
    useContext(WalletContext);
  const { setShowModalConnexion, setIsOpenModalSwap } = useContext(MainContext);

  const { isConnected, address } = useAccount();
  const { signMessage } = useSignMessage({
    message: "Verify your account",
    onSuccess(data) {
      Verify(data);
    },
    onError(error) {
      setVerificationError(error.message?.split(".")[0]);
    },
  });

  const [accessSDKToken, setAccessSDKToken] = useState<string>();
  const [verificationError, setVerificationError] = useState<string>();

  const handler = () => Promise.resolve<string>("");

  const options = { addViewportTag: false, adaptIframeHeight: true };
  const messageHandler = (type: string, payload: Record<string, any>) => {
    window.scrollTo(0, 0);
    console.log("SumsubWebSdk onMessage: ", type, payload);

    if (type === "idCheck.applicantStatus") {
      if (payload?.reviewResult?.reviewAnswer === "GREEN") {
        getApplicantData().catch(console.log);
      }
    }
  };
  const errorHandler = (data: any) => console.log("onError: ", data);

  const config = useMemo(
    () => ({
      lang: "en",
      email: address,
      i18n: {
        document: {
          subTitles: {
            IDENTITY: "Upload a document that proves your identity",
          },
        },
        status: {
          pendingTitle:
            "Thank you. \n\n You have completed the identity verification process.",
          pendingText:
            " The verification status will update below automatically. You can now close this page. We will follow-up with you if we need anything else or have any questions.",
        },
      },
      uiConf: {
        customCss: `${window.location.origin}/sumsubWebsdk.css`,
      },
      onError: (error: String) => {
        console.error("WebSDK onError", error);
      },
    }),
    [address]
  );

  const Verify = async (data: string) => {
    const signature = data;

    if (!applicantExist) {
      await apiKYC.createApplicant({
        externalUserId: address,
      });
    }
    const token = await apiKYC.createToken({
      externalUserId: signature as any,
    });
    setAccessSDKToken(token.data.token);
  };

  const openWalletPopup = () => {
    setShowModalConnexion(true);
  };

  useEffect(() => {
    if (isConnected && !accessSDKToken && !kycDone) {
      signMessage();
    }
  }, []);

  return (
    <div className=" flex min-h-[calc(100%-64px)] w-[100] flex-1 flex-col justify-center gap-8  bg-bgCardNavbar sm:min-h-[400px]">
      {isConnected && !accessSDKToken && !kycDone && !verificationError && (
        <div className="flex flex-col items-center justify-center gap-4">
          <div className=" mt-6 text-center">Verifying your Identity</div>
          <Spinner />
        </div>
      )}
      {isConnected && kycDone && (
        <>
          <div className="flex flex-col items-center justify-center gap-2">
            <p className="flex items-center gap-1 text-lg">
              <SuccessIcon width={24} height={24} />
              Verification completed!
            </p>
            <p className="text-lg">
              Take Me to{" "}
              <Link
                to="/Deposit"
                className="text-pinkLight underline"
                onClick={() => setIsOpenModalSwap(true)}
              >
                Deposit
              </Link>
              .
            </p>
          </div>
        </>
      )}

      {verificationError && !kycDone && (
        <div className="flex flex-col items-center justify-center gap-4">
          <span className="text-red-600">{verificationError}</span>
          <div
            className="flex h-[48px] cursor-pointer items-center justify-center rounded-lg bg-pink p-3 text-base font-normal text-white"
            onClick={() => {
              setVerificationError("");
              signMessage();
            }}
          >
            Click to try again
          </div>
        </div>
      )}

      {!isConnected && (
        <div className="center">
          <div
            className="flex h-[48px] cursor-pointer items-center justify-center rounded-lg bg-pink p-3 text-base font-normal text-white"
            onClick={() => {
              openWalletPopup();
            }}
          >
            Connect Wallet
          </div>
        </div>
      )}

      {isConnected && accessSDKToken && !kycDone && (
        <SumsubWebSdk
          accessToken={accessSDKToken}
          expirationHandler={handler}
          config={config}
          options={options}
          onMessage={messageHandler}
          onError={errorHandler}
        />
      )}
    </div>
  );
};

export default KYC;
