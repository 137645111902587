import { useState } from "react";

import { useInterval } from "./useInterval";

export const useCountdown = (onEnd: () => void, resetMilliseconds = false) => {
  const [isCounting, setIsCounting] = useState(false);
  const [end, setEnd] = useState<number | null>(null);
  const [timeLeftInMs, setTimeLeftInMs] = useState<number | null>(null);

  const startTimer = (time: number) => {
    const newEnd = Date.now() + time;
    setEnd(newEnd);
    setIsCounting(true);
  };

  const resetTimer = (resetRestMilliseconds = false) => {
    setIsCounting(false);
    setEnd(null);
    if (resetMilliseconds || resetRestMilliseconds) {
      setTimeLeftInMs(null);
    }
  };

  const onTick = () => {
    if (!isCounting || !end) return;
    const diff = end - Date.now();

    setTimeLeftInMs(diff);

    if (diff < 0) {
      onEnd();
      resetTimer();
    }
  };

  useInterval(500, onTick);

  return { startTimer, resetTimer, isCounting, timeLeftInMs };
};
