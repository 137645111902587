import { FC } from "react";

import { ReactComponent as ArrowBis } from "../../../assets/icons/ArrowBis.svg";
import { FaqQuestion } from "../../../utils/types/global.types";

type CardPreviewProps = {
  setShowCard: Function;
  question: Required<FaqQuestion>;
};

const BODY_LIMIT = 140;

const CardPreview: FC<CardPreviewProps> = ({ setShowCard, question }) => {
  const bodyText = question.text.join("");

  return (
    <div className="card relative flex flex-col justify-between p-[20px] pb-12">
      <div>
        <div className="text-base font-semibold">{question.title}</div>
        <div className="mt-2 text-sm font-normal leading-[18px] text-[#000000B2]">
          {bodyText.length > BODY_LIMIT
            ? bodyText.slice(0, BODY_LIMIT) + "..."
            : bodyText}
        </div>
      </div>

      <div
        className="absolute bottom-4 flex cursor-pointer items-center  gap-[6px] text-sm font-normal text-pink opacity-60"
        onClick={() => {
          setShowCard(question);
        }}
      >
        Learn More
        <ArrowBis />
      </div>
    </div>
  );
};

export default CardPreview;
