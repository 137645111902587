import { Coins } from "../../../utils/types/swap.types";
import { ReactComponent as W_ETH_Logo } from "../../../assets/logos/tokens/wEtherLogo.svg";
import { ReactComponent as W_BTC_Logo } from "../../../assets/logos/tokens/wBTCLogo.svg";
import { ReactComponent as USDC_Logo } from "../../../assets/logos/tokens/USDC.svg";
import { ReactComponent as USDT_Logo } from "../../../assets/logos/tokens/USDT.svg";
import { ReactComponent as Dai_Logo } from "../../../assets/logos/tokens/DAILogo.svg";
import { ReactComponent as CO_USDC_Logo } from "../../../assets/logos/tokens/COUSDCLogo.svg";
import { ReactComponent as CO_ETH_Logo } from "../../../assets/logos/tokens/COETHLogo.svg";
import { ReactComponent as CO_BTC_Logo } from "../../../assets/logos/tokens/COBTCLogo.svg";
import { addressCOETH, decimalsFI } from "../address/addressesCOFI/ETHCOFI";
import { addressCOUSD } from "../address/addressesCOFI/USDCOFI";
import { addressCOBTC } from "../address/addressesCOFI/BTCCOFI";
import { addresswBTC, decimalBTC } from "../address/wBTC";
import { addresswETH, decimalETH } from "../address/wETH";
import { addressDAI, decimalDAI } from "../address/Dai";
import { addressUSDT, decimalUSDT } from "../address/USDT";
import { addressUSDC, decimalUSDC } from "../address/USDC";

export const COINS_DISPLAY_DIGITS: Record<keyof Coins, number> = {
  ETH: 6,
  wETH: 6,
  wBTC: 8,
  USDC: 4,
  DAI: 4,
  USDT: 4,
  COUSD: 4,
  COETH: 6,
  COBTC: 8,
};

export const COINS_TO_RENDER = [
  {
    key: "USDC",
    Logo: USDC_Logo,
    title: "USDC",
    maxDigits: COINS_DISPLAY_DIGITS["USDC"],
    image: "https://static.optimism.io/data/USDC/logo.png",
    decimals: decimalUSDC,
    address: addressUSDC,
  },
  {
    key: "USDT",
    Logo: USDT_Logo,
    title: "USDT",
    maxDigits: COINS_DISPLAY_DIGITS["USDT"],
    image: "https://static.optimism.io/data/USDT/logo.png",
    decimals: decimalUSDT,
    address: addressUSDT,
  },
  {
    key: "DAI",
    Logo: Dai_Logo,
    title: "Dai",
    maxDigits: COINS_DISPLAY_DIGITS["DAI"],
    image:
      "https://cryptologos.cc/logos/multi-collateral-dai-dai-logo.png?v=026",
    decimals: decimalDAI,
    address: addressDAI,
  },
  {
    key: "wETH",
    Logo: W_ETH_Logo,
    title: "wETH",
    maxDigits: COINS_DISPLAY_DIGITS["wETH"],
    image: "https://static.optimism.io/data/WETH/logo.png",
    decimals: decimalETH,
    address: addresswETH,
  },
  {
    key: "wBTC",
    Logo: W_BTC_Logo,
    title: "wBTC",
    maxDigits: COINS_DISPLAY_DIGITS["wBTC"],
    image:
      "https://d3r81g40ycuhqg.cloudfront.net/wallet/wais/87/f7/87f73687df1584a0e11a7a878ab526f95560fe41f85ee75217a535eaa84b78f0-NjgyMjVhNzYtYmNjMC00OGQ2LWIyNWEtZGRlNWExMTg1YmIy",
    decimals: decimalBTC,
    address: addresswBTC,
  },
  {
    key: "COUSD",
    Logo: CO_USDC_Logo,
    image: "https://optimistic.etherscan.io/images/main/empty-token.png",
    title: "coUSD",
    decimals: decimalsFI,
    address: addressCOUSD,
    maxDigits: COINS_DISPLAY_DIGITS["COUSD"],
  },
  {
    key: "COETH",
    Logo: CO_ETH_Logo,
    image: "https://optimistic.etherscan.io/images/main/empty-token.png",
    title: "coETH",
    decimals: decimalsFI,
    address: addressCOETH,
    maxDigits: COINS_DISPLAY_DIGITS["COETH"],
  },
  {
    key: "COBTC",
    Logo: CO_BTC_Logo,
    image: "https://optimistic.etherscan.io/images/main/empty-token.png",
    title: "coBTC",
    decimals: decimalsFI,
    address: addressCOBTC,
    maxDigits: COINS_DISPLAY_DIGITS["COBTC"],
  },
];
