import { FC, useEffect, useState } from "react";

import { Dialog } from "@headlessui/react";
import { useForm, SubmitHandler } from "react-hook-form";

import { ReactComponent as Cross } from "../../../assets/icons/Cross.svg";
import Spinner from "../../Spinner/Spinner";
import CRMService from "../../../utils/services/crm";
import { formValidator, showError } from "../../../utils/helpers/global.helper";
import Modal from "../../Modal/Modal";

type ModalGetInTouchProps = {
  isOpen: boolean;
  setIsOpen: Function;
};

const ModalGetInTouch: FC<ModalGetInTouchProps> = ({ isOpen, setIsOpen }) => {
  const [isRequesting, setIsRequesting] = useState<boolean>(false);
  const [isSubmitted, setIssSubmitted] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<{ email: string }>({});

  const formHasError = !!Object.keys(errors).length;

  const onSubmit: SubmitHandler<{ email: string }> = async ({ email }) => {
    setIsRequesting(true);

    try {
      await CRMService.addContact({ email });

      setIssSubmitted(true);
    } catch (error: any) {
      setIsRequesting(false);
      showError(error.message);
    }
  };

  function closeModal() {
    setIsOpen(false);
    setIsRequesting(false);
    setIsRequesting(false);
    setIssSubmitted(false);

    reset();
  }

  useEffect(() => {
    if (isSubmitted) {
      closeModal();
    }
  }, [isSubmitted]);

  return (
    <>
      <Modal isOpen={isOpen} closeModal={closeModal}>
        <Dialog.Panel className="flex min-h-[140px] w-full max-w-md transform flex-col gap-4 overflow-hidden rounded-3xl bg-white text-left align-middle font-bold shadow-xl transition-all">
          <div className="mb-2 flex items-center justify-between border-b p-5 text-base">
            <div>Get In Touch</div>
            <div
              className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-lg border border-slate-100 hover:border-slate-300"
              onClick={() => {
                closeModal();
              }}
            >
              <Cross stroke="black" height="10" width="10" />
            </div>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="text-sm font-normal"
          >
            <div className="flex flex-col gap-4 px-5 ">
              <label className="flex flex-col gap-2" htmlFor="Email">
                <div>Email</div>
                <input
                  placeholder="Enter email..."
                  type="text"
                  id="Email"
                  className="h-[40px] w-[100%] rounded-lg border-[0.5px] border-solid border-[#00000033] p-[10px]"
                  {...register("email", { ...formValidator.email })}
                />
                {errors.email && (
                  <span className=" text-red-600">{errors.email.message}</span>
                )}
              </label>
            </div>

            <div className="mt-5 border-t">
              <div className="text-green-400 col-span-1 flex w-full items-center justify-center gap-[8px] p-5 font-semibold  ">
                <button
                  className={`flex h-12 w-full items-center justify-center rounded-lg ${
                    formHasError ? "bg-gray-300" : "bg-pinkLight"
                  } px-5 py-2 font-semibold text-white hover:bg-[#FF67B2] ${
                    (formHasError || isRequesting) && "pointer-events-none"
                  }`}
                  disabled={formHasError || isRequesting}
                  type="submit"
                >
                  {isRequesting ? <Spinner /> : "Submit"}
                </button>
              </div>
            </div>
          </form>
        </Dialog.Panel>
      </Modal>
    </>
  );
};

export default ModalGetInTouch;
