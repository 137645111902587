import { useContext, useEffect } from "react";

import { useAccount, useContractWrite } from "wagmi";

import { CoinsString } from "../utils/types/swap.types";
import { SwapContext } from "../context/Swap.context";
import { MainContext } from "../context/Main.context";
import { DiamondContract } from "../utils/constants/wagmiConfig/wagmiConfig";

const useExitCofi = (amount: BigInt) => {
  const { address } = useAccount();

  const { tokenInSelected, tokenOutSelected, addressesTokens } =
    useContext(SwapContext);
  const { setIsOpenModalSwap, setTransakOrderStatusDataModal } =
    useContext(MainContext);

  const { data, isLoading, isSuccess, isError, write, error } =
    useContractWrite({
      ...DiamondContract,
      functionName: "exitCofi",
      args: [
        amount,
        addressesTokens[tokenOutSelected as keyof CoinsString],
        addressesTokens[tokenInSelected as keyof CoinsString],
        address,
        address,
      ],
      value: tokenInSelected === "ETH" ? (amount as bigint) : BigInt(0),
    });

  useEffect(() => {
    if (isError) {
      const message =
        error?.message.split("Contract Call")?.[0]?.trim?.() || error;

      setTransakOrderStatusDataModal({
        id: "APPROVE_FAILED",
        eventName: "CRYPTO_ORDER_FAILED",
        hash: data?.hash,
        error: message,
      });
    }

    if (isSuccess) {
      setTransakOrderStatusDataModal({
        id: "APPROVE_SUCCESS",
        eventName: "CRYPTO_ORDER_SUCCESSFUL",
        hash: data?.hash,
      });
      setIsOpenModalSwap(false);
    }
  }, [isLoading, isError, isSuccess]);

  return {
    writeExitCofi: () => {
      write();
    },
    exitCofiSuccess: isSuccess,
    exitCofiLoading: isLoading,
  };
};

export default useExitCofi;
