import { FC, useState } from "react";

import { Connector } from "wagmi";

import { ReactComponent as MagicLogo } from "../../../assets/logos/wallet/1-Icon_Magic_Color.svg";
import { MagicService } from "../../../utils/services/magic";
import Spinner from "../../../components/Spinner/Spinner";

interface MagicConnectButtonProps {
  connector: Connector;
  connect: (args: { chainId?: number; connector: Connector }) => void;
}

export const MagicConnectButton: FC<MagicConnectButtonProps> = ({
  connector,
  connect,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleMagicAuthError = () => {
    setIsLoading(false);
  };

  const handleMagicAuthDone = () => {
    setIsLoading(false);

    connect({ connector });
  };

  const handleButtonClick = () => {
    setIsLoading(true);

    MagicService.wallet
      .connectWithUI()
      .on("error", handleMagicAuthError)
      .on("done", handleMagicAuthDone);
  };

  return (
    <button
      key="Magic"
      onClick={handleButtonClick}
      className="relative flex h-[50px] items-center gap-2 rounded-lg border bg-white pl-3 text-gray-700 hover:cursor-pointer hover:bg-gray-100 sm:h-[100%] sm:flex-col sm:justify-center sm:pl-0"
    >
      <MagicLogo />
      Magic
      {isLoading && (
        <div className="bottom-2 sm:absolute sm:mr-[-8px]">
          <Spinner />
        </div>
      )}
    </button>
  );
};
