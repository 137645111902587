import axios from "axios";

import { Coins } from "../types/swap.types";
import { GetCoinsPricesResponse } from "../types/global.types";

export const getPrices = async (): Promise<Coins> => {
  const prices = await axios
    .get<GetCoinsPricesResponse>(
      "https://api.coingecko.com/api/v3/simple/price?ids=ethereum,bitcoin,usd-coin,tether,dai&vs_currencies=usd"
    )
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error);
    });

  const usdcPrice = prices["usd-coin"].usd;
  const ethPrice = prices["ethereum"].usd;
  const btcPrice = prices["bitcoin"].usd;
  const daiPrice = prices["dai"].usd;
  const udstPrice = prices["tether"].usd;

  return {
    USDC: usdcPrice,
    USDT: udstPrice,
    DAI: daiPrice,
    COUSD: usdcPrice,
    ETH: ethPrice,
    wETH: ethPrice,
    wBTC: btcPrice,
    COETH: ethPrice,
    COBTC: btcPrice,
  };
};

export const getReceiveAmount = (
  depositAmount: bigint,
  fee: number | undefined,
  decimalIn: number | undefined,
  decimalOut: number | undefined
): bigint | undefined => {
  try {
    const percentageFactor = 10 ** 4;
    if (fee && decimalOut && decimalIn) {
      return (
        (depositAmount *
          BigInt((1 - fee) * percentageFactor) *
          BigInt(10 ** decimalOut)) /
        (BigInt(percentageFactor) * BigInt(10 ** decimalIn))
      );
    }
  } catch (error) {
    throw new Error("Error : " + error);
  }
};
