import { FC, useContext } from "react";

import ModalOffChainData from "../components/Modals/ModalOffChainData/ModalOffChainData";
import { MainContext } from "../context/Main.context";
import ModalModifProfil from "../components/Modals/ModalModifProfil/ModalModifProfil";
import ModalSwap from "../components/Modals/ModalSwap/ModalSwap";
import ModalTransak from "../components/Modals/ModalTransak/ModalTransak";
import ModalTransakOrderStatus from "../components/Modals/ModalTransakOrderStatus/ModalTransakOrderStatus";
import ModalCoffeeLoader from "../components/Modals/ModalCoffeeLoader/ModalCoffeeLoader";
import ModalTransactionSuccessStatus from "../components/Modals/ModalTransactionSuccessStatus/ModalTransactionSuccessStatus";
import ModalGetInTouch from "../components/Modals/ModalGetInTouch/ModalGetInTouch";

const Modals: FC = () => {
  const {
    action,
    setAction,
    showModalForm,
    setShowModalForm,
    showModalModifyForm,
    setShowModalModifyForm,
    isOpenModalSwap,
    setIsOpenModalSwap,
    showTransak,
    setShowTransak,
    transakOrderStatusDataModal,
    setTransakOrderStatusDataModal,
    showCoffeeLoader,
    setShowCoffeeLoader,
    coffeeLoaderTX,
    transactionSuccessStatusDataModal,
    setTransactionSuccessStatusDataModal,
    showModalGetInTouch,
    setShowModalGetInTouch,
  } = useContext(MainContext);

  return (
    <>
      <ModalOffChainData isOpen={showModalForm} setIsOpen={setShowModalForm} />
      <ModalModifProfil
        isOpen={showModalModifyForm && !showModalForm}
        setIsOpen={setShowModalModifyForm}
      />
      <ModalSwap
        isOpen={isOpenModalSwap}
        setIsOpen={setIsOpenModalSwap}
        action={action}
        setAction={setAction}
      />
      <ModalTransak
        showTransak={showTransak}
        setShowTransak={setShowTransak}
        setTransakOrderStatusDataModal={setTransakOrderStatusDataModal}
        productsAvailed={action === 0 ? "BUY" : "SELL"}
      />
      <ModalTransakOrderStatus
        orderStatusData={transakOrderStatusDataModal}
        setIsOpen={setTransakOrderStatusDataModal}
        setAction={setAction}
        setIsOpenModalSwap={setIsOpenModalSwap}
      />
      <ModalCoffeeLoader
        isOpen={showCoffeeLoader}
        setIsOpen={setShowCoffeeLoader}
        hash={coffeeLoaderTX}
      />
      <ModalTransactionSuccessStatus
        transactionDetails={transactionSuccessStatusDataModal}
        setIsOpen={setTransactionSuccessStatusDataModal}
      />
      <ModalGetInTouch
        isOpen={showModalGetInTouch}
        setIsOpen={setShowModalGetInTouch}
      />
    </>
  );
};

export default Modals;
