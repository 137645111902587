import { useEffect, useRef, useState } from "react";

import { useAccount, useBalance, useContractReads } from "wagmi";

import { addressUSDC } from "../utils/constants/address/USDC";
import { abi } from "../utils/constants/abi/CofiAndWAddresses";
import { addressCOUSD } from "../utils/constants/address/addressesCOFI/USDCOFI";
import { addresswETH } from "../utils/constants/address/wETH";
import { addresswBTC } from "../utils/constants/address/wBTC";
import { addressCOETH } from "../utils/constants/address/addressesCOFI/ETHCOFI";
import { addressCOBTC } from "../utils/constants/address/addressesCOFI/BTCCOFI";
import { BalanceCoins } from "../utils/types/swap.types";
import { Optional } from "../utils/types/global.types";
import { addressDAI } from "../utils/constants/address/Dai";
import { addressUSDT } from "../utils/constants/address/USDT";

const FETCH_BALANCE_DELAY = 1000 * 15;

const useBalancesCoins = () => {
  const { address, isConnected } = useAccount();
  const timerRef = useRef<ReturnType<typeof setInterval>>();
  const { data, refetch: refetchEth } = useBalance({
    address,
  });

  const [balanceCoins, setBalanceCoins] = useState<
    Optional<BalanceCoins, "ETH">
  >({
    DAI: BigInt(0),
    USDT: BigInt(0),
    COUSD: BigInt(0),
    USDC: BigInt(0),
    ETH: BigInt(0),
    wETH: BigInt(0),
    wBTC: BigInt(0),
    COETH: BigInt(0),
    COBTC: BigInt(0),
  });

  useEffect(() => {
    setBalanceCoins((prevState) => ({
      ...prevState,
      ETH: data?.value,
    }));
  }, [data]);

  const { refetch: refetchCoints } = useContractReads({
    contracts: [
      {
        abi: abi as any,
        address: addressUSDC as `0x${string}`,
        functionName: "balanceOf",
        args: [address as `0x${string}`],
      },
      {
        abi: abi as any,
        address: addressDAI as `0x${string}`,
        functionName: "balanceOf",
        args: [address as `0x${string}`],
      },
      {
        abi: abi as any,
        address: addressUSDT as `0x${string}`,
        functionName: "balanceOf",
        args: [address as `0x${string}`],
      },
      {
        abi: abi as any,
        address: addressCOUSD as `0x${string}`,
        functionName: "balanceOf",
        args: [address as `0x${string}`],
      },
      {
        abi: abi as any,
        address: addresswETH as `0x${string}`,
        functionName: "balanceOf",
        args: [address as `0x${string}`],
      },
      {
        abi: abi as any,
        address: addresswBTC as `0x${string}`,
        functionName: "balanceOf",
        args: [address as `0x${string}`],
      },
      {
        abi: abi as any,
        address: addressCOETH as `0x${string}`,
        functionName: "balanceOf",
        args: [address as `0x${string}`],
      },
      {
        abi: abi as any,
        address: addressCOBTC as `0x${string}`,
        functionName: "balanceOf",
        args: [address as `0x${string}`],
      },
    ],
    onSuccess(data: any) {
      setBalanceCoins((prevState) => ({
        ...prevState,
        USDC: data[0].result || BigInt(0),
        DAI: data[1].result || BigInt(0),
        USDT: data[2].result || BigInt(0),
        COUSD: data[3].result || BigInt(0),
        wETH: data[4].result || BigInt(0),
        wBTC: data[5].result || BigInt(0),
        COETH: data[6].result || BigInt(0),
        COBTC: data[7].result || BigInt(0),
      }));
    },
    enabled: isConnected,
  });

  useEffect(() => {
    const refetchBalance = () => {
      refetchEth && refetchEth();
      refetchCoints && refetchCoints();
    };

    if (isConnected) {
      timerRef.current = setInterval(refetchBalance, FETCH_BALANCE_DELAY);
    }
    return () => {
      clearInterval(timerRef.current);
    };
  }, [isConnected]);

  return {
    balanceCoins,
  };
};

export default useBalancesCoins;
