import { FC } from "react";

import moment from "moment/moment";

import { ReactComponent as ArrowSwap } from "../../../assets/icons/arrowSwap.svg";
import { Chain, SocketTransaction } from "../../../utils/types/bridge.types";
import { getTransactionStatus } from "../../../utils/helpers/global.helper";

import AssetItem from "./AssetItem/AssetItem";

type TransactionProps = {
  item: SocketTransaction;
  chains?: Chain[];
  onClick: Function;
};

const Transaction: FC<TransactionProps> = ({ item, chains, onClick }) => {
  const { userTxs, createdAt, routeStatus } = item || {};
  const transactionData = userTxs?.[0];

  if (!transactionData) {
    return null;
  }

  const protocol = userTxs[0]?.protocol || userTxs[0]?.steps?.[0]?.protocol;
  const isBridge = userTxs.some(({ steps }) =>
    steps?.some(({ type }) => type === "bridge")
  );
  const isSwap = userTxs.some(({ steps }) =>
    steps?.some(({ type }) => type === "swap")
  );

  const protocolStatus = isBridge
    ? isSwap
      ? "Bridge-Swap"
      : "Bridge"
    : "Swap";

  const createdAtRender = moment(createdAt)?.format("DD MMM YY, HH:mm");

  const { TStatus, TIcon, TColor } = getTransactionStatus(routeStatus);

  const Protocol = () => (
    <>
      <img
        src={protocol?.icon}
        className="h-5 w-5 rounded-[50%] sm:h-7 sm:w-7"
      />
      <div className="flex flex-col">
        <span className="text-sm font-bold leading-4">
          {protocol?.displayName}
        </span>
        <span className="text-xs font-bold leading-4 text-gray-500">
          {protocolStatus}
        </span>
      </div>
    </>
  );

  return (
    <div className="my-2 flex flex-col border-[0.5px] sm:my-0 sm:flex-row sm:border-x-[0] sm:border-b-[0] sm:p-4 sm:px-0">
      <div
        className="relative flex flex-col gap-2 p-4 sm:hidden"
        onClick={() => onClick(item as any as SocketTransaction)}
      >
        <p className="flex items-center gap-1 text-sm">
          <TIcon className={`h-[14px] w-[14px] fill-${TColor}`} />
          <AssetItem
            asset={item.fromAsset}
            amount={item.fromAmount}
            decimals={item.fromAsset?.decimals}
            hideIcon
          />
          {"-"}
          <AssetItem
            asset={item.toAsset}
            amount={item.toAmount}
            decimals={item.toAsset?.decimals}
            hideIcon
          />
        </p>
        <div className="flex flex-1 items-center gap-2">
          {protocol && <Protocol />}
        </div>
        <div className="absolute right-4 top-[50%] flex h-6 w-6 translate-y-[-50%] items-center justify-center rounded-[50%] bg-gray-200">
          <ArrowSwap className="rotate-[-90deg] scale-125" />
        </div>
      </div>

      <div className="hidden flex-1 sm:flex">
        <AssetItem
          asset={item.fromAsset}
          chains={chains}
          amount={item.fromAmount}
          decimals={item.fromAsset?.decimals}
        />
      </div>
      <div className="hidden flex-1 items-center sm:flex">
        <AssetItem
          asset={item.toAsset}
          amount={item.toAmount}
          decimals={item.toAsset?.decimals}
          chains={chains}
        />
      </div>
      <div className="hidden flex-1 gap-2 sm:flex">
        {protocol && <Protocol />}
      </div>
      <div className="hidden flex-1 flex-col sm:flex">
        <span className={`flex items-center gap-1 text-sm text-${TColor}`}>
          <TIcon className={`h-[14px] w-[14px] fill-${TColor}`} />
          {TStatus}
        </span>
        <span className="text-xs font-bold leading-4 text-gray-500">
          {createdAtRender}
        </span>
      </div>
      <div className="hidden w-[75px] items-center sm:flex">
        <a
          className="rounded-xl bg-pinkLight px-2 py-[2px] text-sm text-white"
          href={`https://optimistic.etherscan.io/tx/${item?.bridgeTxHash}`}
          target="_blank"
          rel="noreferrer"
        >
          View TX
        </a>
      </div>
    </div>
  );
};

export default Transaction;
