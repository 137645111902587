import { toast, ToastOptions } from "react-toastify";
import { formatUnits, parseUnits } from "viem";

import { Review } from "../types/sumsub.types";
import { TransactionStatus } from "../types/bridge.types";
import { ReactComponent as InfoIcon } from "../../assets/icons/InfoRounded.svg";
import { ReactComponent as ErrorIcon } from "../../assets/icons/Error.svg";
import { ReactComponent as SuccessIcon } from "../../assets/icons/Validate.svg";

export const getKycDone = (review: Review | undefined) => {
  try {
    if (review && review.reviewStatus === "completed") {
      return review.reviewResult.reviewAnswer === "GREEN" &&
        review.reviewStatus === "completed"
        ? true
        : false;
    } else if (review && review.reviewStatus === "init") {
      return false;
    } else {
      return undefined;
    }
  } catch (error) {
    throw new Error("getKycDone : " + error);
  }
};

export const copyToClipboard = async (text?: string) => {
  try {
    if (text) {
      return await toast.promise(() => navigator.clipboard.writeText(text), {
        success: "Copied !",
        error: "Error while copying address : " + text,
      });
    }
  } catch (error) {
    throw new Error("Error while copying address");
  }
};

export const showError = (text?: string, options?: ToastOptions) => {
  toast.error(text || "Something went wrong", {
    type: "error",
    isLoading: false,
    className: "rotateY animated",
    autoClose: 5000,
    ...(options || {}),
  });
};

export const getFloor = (
  num: number | undefined,
  decimal: number | undefined
) => {
  try {
    if (num !== undefined && decimal !== undefined)
      return Math.floor(num * 10 ** decimal) / 10 ** decimal;
  } catch (error) {
    throw new Error("getFloor failed : " + error);
  }
};
export const displayBalance = (
  num: bigint | undefined,
  decimal: number | undefined
): string | undefined => {
  try {
    if (num !== undefined && decimal !== undefined) {
      return formatUnits(num, decimal);
    }
  } catch (error) {
    throw new Error("displayBalance  failed: " + error);
  }
};

export const bigIntToDecimal = (
  num: bigint | undefined,
  decimal: number | undefined
): number | undefined => {
  try {
    if (num !== undefined && decimal !== undefined) {
      return Number(formatUnits(num, decimal));
    }
  } catch (error) {
    throw new Error("bigIntToDecimal failed : " + error);
  }
};

export const stringToBigInt = (
  num: string | undefined,
  decimal: number | undefined
): bigint | undefined => {
  try {
    if (num !== undefined && decimal !== undefined) {
      return parseUnits(`${Number(num)}`, decimal);
    }
  } catch (error) {
    throw new Error("bigIntToDecimal failed : " + error);
  }
};

export const getMinAmountOut = (
  num: bigint | undefined
): bigint | undefined => {
  try {
    const percentageFactor = 10000;
    if (num !== undefined) {
      const factor = 0.9975 * percentageFactor;
      return BigInt(Number(num) * factor) / BigInt(percentageFactor);
    }
  } catch (error) {
    throw new Error("bigIntToDecimal failed : " + error);
  }
};

export const weiToEth = (num: number) => {
  return num / 10 ** 18;
};

export const renderAddress = (address: string | undefined) => {
  if (typeof address !== "string") {
    return address;
  }

  return `${address.slice(0, 6)}...${address.slice(38)}`;
};

export const getReferralLink = (
  address: string | undefined,
  fullAddress?: boolean
) => {
  const formattedAddress =
    (fullAddress ? address : renderAddress(address)) || "";

  return `https://app.cofi.money${
    fullAddress ? "?" : "/"
  }ref=${formattedAddress}`;
};

export const getGreeting = (hours: number | undefined) => {
  if (!hours) {
    return "Hi";
  }

  let greeting;
  if (hours >= 5 && hours < 12) {
    greeting = "Good Morning";
  } else if (hours >= 12 && hours < 17) {
    greeting = "Good Afternoon";
  } else if (hours >= 17 && hours < 21) {
    greeting = "Good Evening";
  } else {
    greeting = "Good Night";
  }

  return greeting;
};

export const parseBlockchainNumber = ({
  amount,
  decimals,
}: {
  amount: string | number;
  decimals: number;
}) => {
  const formatted = formatUnits(BigInt(amount), decimals);

  const parsedNumber =
    typeof formatted === "string" ? parseFloat(formatted) : formatted;

  return new Intl.NumberFormat("en-GB", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 4,
  }).format(parsedNumber);
};

export const getTransactionStatus = (status: TransactionStatus) => {
  switch (status) {
    case TransactionStatus.READY:
      return {
        TStatus: "Ready",
        TColor: "orange-300",
        TIcon: InfoIcon,
      };
    case TransactionStatus.PENDING:
      return {
        TStatus: "Pending",
        TColor: "orange-300",
        TIcon: InfoIcon,
      };
    case TransactionStatus.FAILED:
      return {
        TStatus: "Failed",
        TColor: "red-600",
        TIcon: ErrorIcon,
      };
    case TransactionStatus.COMPLETED:
      return {
        TStatus: "Completed",
        TColor: "[#33cc59]",
        TIcon: SuccessIcon,
      };
    default: {
      return {
        TStatus: "Swap",
        TColor: "white",
        TIcon: SuccessIcon,
      };
    }
  }
};

export const formValidator = {
  firstName: {
    required: {
      value: true,
      message: "First name is required.",
    },
    pattern: {
      value: /^[A-Za-z0-9 ]+$/,
      message: "Allowed only [a-z 0-9] symbols.",
    },
    maxLength: {
      value: 48,
      message: "Max length is 48 symbols.",
    },
  },
  lastName: {
    required: {
      value: true,
      message: "Last name is required.",
    },
    pattern: {
      value: /^[A-Za-z0-9 ]+$/,
      message: "Allowed only [a-z 0-9] symbols.",
    },
    maxLength: {
      value: 48,
      message: "Max length is 48 symbols.",
    },
  },
  email: {
    required: {
      value: true,
      message: "Please enter your email address.",
    },
    pattern: {
      value:
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/,
      message: "Please enter a valid email address.",
    },
    maxLength: {
      value: 64,
      message: "Max length is 64 symbols.",
    },
  },
};

export const dayToSeconds = (days: number) => {
  if (!days) {
    return 0;
  }

  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - days);
  currentDate.setHours(0, 0, 0, 0);
  return currentDate.getTime() / 1000;
};

export const isBehindOtherElement = (element: Element | null) => {
  const boundingRect = element?.getBoundingClientRect();

  if (!boundingRect) {
    return;
  }

  const left = boundingRect.left + 1;
  const right = boundingRect.right - 1;
  const top = boundingRect.top + 1;
  const bottom = boundingRect.bottom - 1;

  if (document.elementFromPoint(left, top) !== element) return true;
  if (document.elementFromPoint(right, top) !== element) return true;
  if (document.elementFromPoint(left, bottom) !== element) return true;
  if (document.elementFromPoint(right, bottom) !== element) return true;

  return false;
};

export const roundNumber = (value: string | undefined, digits: number = 3) => {
  if (!value?.length) {
    return value;
  }
  const [wholePart, fraction] = value.split(".");
  if (!fraction?.length) {
    return Number(wholePart).toFixed(2);
  }
  const result = Number(`${wholePart}.${fraction.slice(0, digits)}`);

  if (digits > 2) {
    return result;
  }

  return Number(result.toFixed(digits)).toLocaleString("en-US");
};

export const getEtherScanTXLink = (id: string | undefined) => {
  if (!id) {
    return "";
  }
  return `https://optimistic.etherscan.io/tx/${id}`;
};

export const getCoName = (str: string) => {
  if (!str) {
    return str;
  }
  return `${str.slice(0, 2).toLowerCase()}${str.slice(2)}`;
};
