import { useCallback, useContext, useEffect, useState } from "react";

import { Bridge } from "@socket.tech/plugin";
import { optimism } from "viem/chains";
import { useLocation } from "react-router-dom";

import { MainContext } from "../../context/Main.context";
import { showError } from "../../utils/helpers/global.helper";
import ModalTransactionDetails from "../../components/Modals/ModalTransactionDetails/ModalTransactionDetails";
import useTransactions from "../../hooks/useTransactions";
import useEtherProvider from "../../hooks/useEtherProvider";
import { SocketTransaction } from "../../utils/types/bridge.types";

import Transaction from "./Transaction/Transaction";

const DEFAULT_MAIN_TOKEN = "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee";

const BridgePage = () => {
  const location = useLocation();

  const { provider } = useEtherProvider();
  const [activeTransaction, setActiveTransaction] =
    useState<SocketTransaction | null>(null);

  const { transactions, refetch, chains } = useTransactions({ limit: 30 });

  const { showModalTransactionDetails, setShowModalTransactionDetails } =
    useContext(MainContext);

  useEffect(() => {
    const bridgeElement = document.querySelector(
      "#bridgeWrapper > div"
    ) as HTMLDivElement;
    if (bridgeElement) {
      bridgeElement.style.minWidth = "auto";
    }
  }, []);

  const handleTransactionClicked = useCallback(
    (transaction: SocketTransaction) => {
      setShowModalTransactionDetails(true);
      setActiveTransaction(transaction);
    },
    []
  );

  return (
    <div className="flex-1 bg-bgCardNavbar pb-16 pt-8">
      <div className="container flex-col gap-3">
        <div className="flex w-full flex-col gap-4 lg:flex-row">
          <div
            className="card top-4 mx-auto h-fit w-full max-w-[400px] lg:sticky"
            id="bridgeWrapper"
          >
            <Bridge
              API_KEY={process.env.REACT_APP_SOCKET_API_KEY || ""}
              provider={provider}
              onBridgeSuccess={() => {
                refetch();
              }}
              onError={(error) => {
                showError(error?.message || "Transaction error");
              }}
              customize={{
                responsiveWidth: true,
              }}
              defaultDestToken={
                new URLSearchParams(location.search).get("destinationToken") ||
                DEFAULT_MAIN_TOKEN
              }
              destNetworks={[optimism.id]}
            />
          </div>
          <div className="card w-full p-4">
            <p className="mb-6 text-lg sm:mb-0">Transaction History</p>
            {!transactions.length && (
              <div className="flex h-full min-h-[140px] items-center justify-center">
                <p className="text-lg font-bold">No Transactions yet.</p>
              </div>
            )}
            {!!transactions.length && (
              <>
                <div className="mt-6 hidden w-full pb-4 sm:flex">
                  <div className="flex-1">From</div>
                  <div className="flex-1">To</div>
                  <div className="flex-1">Via</div>
                  <div className="flex-1">Status</div>
                  <div className="w-[75px]" />
                </div>
                <div>
                  {transactions.map((item, index) => (
                    <Transaction
                      item={item}
                      key={index}
                      onClick={handleTransactionClicked}
                      chains={chains}
                    />
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
        <ModalTransactionDetails
          isOpen={showModalTransactionDetails}
          setIsOpen={setShowModalTransactionDetails}
          transaction={activeTransaction || ({} as SocketTransaction)}
          chains={chains}
        />
      </div>
    </div>
  );
};

export default BridgePage;
