import { FC, useState } from "react";

import { MagicService } from "../../utils/services/magic";
import { ReactComponent as MagicLogo } from "../../assets/logos/wallet/3-Icon_Magic_White.svg";
import Spinner from "../Spinner/Spinner";

export const MagicWalletUIButton: FC = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleMagicWalletClick = () => {
    if (!isLoading) {
      setIsLoading(true);

      MagicService.wallet.showUI().finally(() => {
        setIsLoading(false);
      });
    }
  };

  return (
    <button
      disabled={isLoading}
      onClick={handleMagicWalletClick}
      className="mb-2 mt-2 flex flex-row items-center justify-center rounded-full bg-magicWallet p-2 text-white"
    >
      {isLoading ? (
        <Spinner className="!mr-[6px] !h-[24px] !w-[24px] fill-white text-opacity-20" />
      ) : (
        <MagicLogo className="mr-[6px] h-[24px] w-[24px]" />
      )}
      Magic Wallet
    </button>
  );
};
