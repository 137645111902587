import { useContext, useMemo } from "react";

import { useContractRead } from "wagmi";

import { SwapContext } from "../context/Swap.context";
import { CoinsString, Token } from "../utils/types/swap.types";
import { DiamondContract } from "../utils/constants/wagmiConfig/wagmiConfig";

const useGetSupportedSwaps = (
  selectedToken: keyof CoinsString | null,
  tokensList: Token[]
): Token[] => {
  const { addressesTokens, convertTokenList } = useContext(SwapContext);

  const { data = [] } = useContractRead({
    ...DiamondContract,
    functionName: "getSupportedSwaps",
    args: [selectedToken && addressesTokens[selectedToken]],
    enabled: Boolean(selectedToken),
  });

  return useMemo(
    () =>
      selectedToken
        ? tokensList.filter((token) => {
            if (selectedToken === "ETH" || selectedToken === "wETH") {
              if (
                token.name === "COUSD" ||
                token.name === "COETH" ||
                token.name === "COBTC"
              ) {
                return true;
              }
            }

            if (token.name === "ETH") {
              return true;
            }

            if (convertTokenList[selectedToken] === token.name) {
              return true;
            }

            return data.includes(addressesTokens[token.name]);
          })
        : [],
    [selectedToken, tokensList, data]
  );
};

export default useGetSupportedSwaps;
