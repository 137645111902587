import { FC, SVGProps, useContext, useRef } from "react";

import { Popover, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";

import { ReactComponent as Close } from "../../assets/icons/Close.svg";
import { ReactComponent as Menu } from "../../assets/icons/TwoBarsMenu.svg";
import { MainContext } from "../../context/Main.context";
// import { ReactComponent as LightTheme } from "../../assets/icons/LightTheme.svg";
// import { ReactComponent as DarkTheme } from "../../assets/icons/DarkTheme.svg";

type IProps = {
  links: {
    title: string;
    link: string;
    icon: FC<SVGProps<SVGSVGElement>>;
    fill?: boolean;
  }[];
};

const HeaderDropdown: FC<IProps> = ({ links }) => {
  const dRef = useRef<HTMLButtonElement | null>(null);
  const { setShowModalConnexion } = useContext(MainContext);

  const navigate = useNavigate();
  const { isConnected } = useAccount();

  // const lightActive = true;

  const handleLogin = () => {
    setShowModalConnexion(true);
  };

  return (
    <Popover>
      {({ open, close }) => {
        return (
          <>
            <Popover.Button
              ref={dRef}
              className={`
                ${open ? "" : "text-opacity-90"}
              group inline-flex items-center rounded-md px-3 py-2 text-base font-medium text-black hover:text-opacity-100 focus:outline-none ${
                isConnected ? "lg:hidden" : "md:hidden"
              }`}
            >
              {open ? <Close /> : <Menu />}
            </Popover.Button>

            {open && (
              <Transition
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel
                  static
                  className={`absolute inset-0 top-16 z-10 w-screen overscroll-none bg-white ${
                    isConnected ? "lg:hidden" : "md:hidden"
                  }`}
                >
                  <div className="borderBottom rounded-b-3xl bg-white px-6 py-3">
                    {/*{isConnected && (*/}
                    {/*  <div className="flex h-[48px] items-center justify-center rounded-lg border-[0.5px] bg-black bg-opacity-5 text-sm text-[#394A46]">*/}
                    {/*    Switch to Arbitrum*/}
                    {/*  </div>*/}
                    {/*)}*/}
                    <div className="my-2 flex flex-col">
                      {links.map(({ title, link, icon: Icon, fill }, index) => {
                        if ("/Earnings" === link && !isConnected) {
                          return null;
                        }
                        const isActive = location.pathname === link;
                        const hasBorder = links.length !== index + 1;
                        const iconColor = isActive ? "#FF87B2" : "black";
                        const iconStyle = fill
                          ? { fill: iconColor }
                          : { stroke: iconColor };

                        return (
                          <div
                            className={`${
                              hasBorder && "borderBottom"
                            } flex h-[100%] cursor-pointer items-center gap-[8px] p-4 text-base`}
                            key={title}
                            onClick={() => {
                              navigate(link);
                              close();
                            }}
                          >
                            <Icon {...iconStyle} />
                            <div
                              className={`${
                                isActive ? "text-pinkLight" : "text-gray-700"
                              }`}
                            >
                              {title}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    {/*<div className="mb-4 flex justify-between rounded-lg border-[0.5px] bg-black bg-opacity-5 p-[2px] text-sm">*/}
                    {/*  <div*/}
                    {/*    className={`${*/}
                    {/*      lightActive && "bg-[#394A46]"*/}
                    {/*    } flex flex-1 items-center justify-center gap-2 rounded-lg p-3 text-white`}*/}
                    {/*  >*/}
                    {/*    <LightTheme />*/}
                    {/*    Light*/}
                    {/*  </div>*/}
                    {/*  <div className="flex flex-1 items-center justify-center gap-2 rounded-lg p-3">*/}
                    {/*    <DarkTheme />*/}
                    {/*    Dark*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                    {!isConnected && (
                      <>
                        <div
                          className="flex h-12 w-full items-center justify-center rounded-lg bg-pinkLight px-5 py-2 font-semibold text-white hover:bg-[#FF67B2]"
                          onClick={handleLogin}
                        >
                          Get Started
                        </div>
                        <div
                          className="mt-2 flex h-12 w-full items-center justify-center rounded-lg border px-5 py-2 font-semibold"
                          onClick={handleLogin}
                        >
                          Login
                        </div>
                      </>
                    )}
                  </div>
                </Popover.Panel>
                <Popover.Overlay
                  className={`z-1 fixed inset-0 bg-black opacity-30 ${
                    isConnected ? "lg:hidden" : "md:hidden"
                  }`}
                />
              </Transition>
            )}
          </>
        );
      }}
    </Popover>
  );
};

export default HeaderDropdown;
