import { useCallback, useMemo } from "react";

import { providers } from "ethers";
import { useAccount, useWalletClient, WalletClient } from "wagmi";

import { WalletAsset } from "../utils/types/global.types";
import { showError } from "../utils/helpers/global.helper";

export function walletClientToProvider(walletClient: WalletClient) {
  const { transport } = walletClient;
  return new providers.Web3Provider(<any>transport, "any");
}

const useEtherProvider = () => {
  const { data: walletClient } = useWalletClient();
  const { connector } = useAccount();

  const watchAsset = useCallback(
    (asset: WalletAsset) => {
      if (connector?.id === "metaMask") {
        connector
          .watchAsset?.(asset)
          .then((res) => {
            if (res) {
              showError("Added successfully!", {
                type: "success",
              });
            }
          })
          .catch((error) => console.log("watchAsset error: ", error));
      }
    },
    [connector]
  );

  const provider = useMemo(
    () => (walletClient ? walletClientToProvider(walletClient) : undefined),
    [walletClient]
  );

  return {
    provider,
    watchAsset,
  };
};

export default useEtherProvider;
