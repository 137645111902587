const CoffeeLoader = () => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="100" height="100" fill="white" />
      <ellipse
        opacity="0.2"
        cx="50"
        cy="92.5"
        rx="37"
        ry="7.5"
        fill="#636160"
      />
      <path
        opacity="0.7"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.9222 13.3546C26.5361 12.6758 26.9465 11.8208 27.7202 11.7146C34.1316 10.8341 40.6362 10.8383 47.0513 11.7293L48.2834 11.9005C53.8876 12.6788 59.5592 12.8525 65.2005 12.4186L69.4037 12.0952C70.2456 12.0305 70.8532 12.8859 70.5148 13.6595L67.6383 20.2342C67.4601 20.6415 67.0577 20.9047 66.6131 20.9047H33.3956C33.336 20.9047 33.2766 20.9095 33.2179 20.9189C33.0992 20.938 32.9805 20.9621 32.8602 20.9621H32.5562C32.0951 20.9047 31.3451 21.1296 31.081 20.6652L26.9222 13.3546Z"
        fill="#636160"
      />
      <path
        d="M28.0446 6.71053L27.1898 4.52695C26.9025 3.79302 27.4437 3 28.2319 3H73.4673C74.2743 3 74.8159 3.82824 74.4925 4.56758L67.6383 20.2342C67.4601 20.6416 67.0577 20.9048 66.6131 20.9048H33.214M28.0446 6.71053H25.0666C24.2083 6.71053 23.6695 7.63689 24.0939 8.38289L31.0809 20.6653C31.3451 21.1297 31.9006 21.3425 32.4075 21.1736L33.214 20.9048M28.0446 6.71053L33.214 20.9048"
        stroke="#3A3634"
        strokeWidth="1.96952"
        strokeLinecap="round"
      />
      <path
        d="M32.857 38.8096H67.1437L78.8272 62.2783C86.0867 76.8607 76.8252 94.2739 60.7209 96.3213C53.6022 97.2263 46.3984 97.2263 39.2798 96.3213C23.1754 94.2739 13.914 76.8607 21.1735 62.2783L32.857 38.8096Z"
        stroke="#3A3634"
        strokeWidth="1.96952"
        strokeLinecap="round"
      />
      <path
        d="M33.2144 33.2143C33.2144 35.0684 31.7113 36.5715 29.8572 36.5715C28.0031 36.5715 26.5001 35.0684 26.5001 33.2143C26.5001 31.3602 28.0031 29.8572 29.8572 29.8572C31.7113 29.8572 33.2144 31.3602 33.2144 33.2143Z"
        fill="white"
      />
      <path
        d="M33.2144 33.2143C33.2144 35.0684 31.7113 36.5715 29.8572 36.5715C28.0031 36.5715 26.5001 35.0684 26.5001 33.2143M33.2144 33.2143C33.2144 31.3602 31.7113 29.8572 29.8572 29.8572C28.0031 29.8572 26.5001 31.3602 26.5001 33.2143M33.2144 33.2143H29.8572M26.5001 33.2143L21.4644 41.6072M26.5001 33.2143V39.3691"
        stroke="#3A3634"
        strokeWidth="1.96952"
        strokeLinecap="round"
      />
      <mask
        id="mask0_1104_9632"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="20"
        y="41"
        width="60"
        height="54"
      >
        <path
          d="M34.0812 41.0476H65.9189L76.7678 62.7111C83.5088 76.1717 74.9088 92.2454 59.9548 94.1353C53.3447 94.9707 46.6554 94.9707 40.0452 94.1353C25.0912 92.2454 16.4912 76.1717 23.2323 62.7111L34.0812 41.0476Z"
          fill="#D3CBC3"
          className="animate-waving-hand"
        />
      </mask>
      <g mask="url(#mask0_1104_9632)">
        <path
          d="M-96.0491 70.0576C-83.7262 64.2049 -73.1159 64.2015 -60.7857 70.0576V130.571H-134.643V70.0573C-123.452 75.3722 -108.345 75.8976 -96.0491 70.0576Z"
          fill="#636160"
        />
        <path
          d="M-22.192 70.0576C-9.86911 64.2049 0.741211 64.2015 13.0714 70.0576V130.571H-60.7857V70.0576C-49.5952 75.3725 -34.4881 75.8976 -22.192 70.0576Z"
          fill="#636160"
        />
        <path
          d="M51.6652 70.0576C63.988 64.2049 74.5984 64.2015 86.9286 70.0576V130.571H13.0714V70.0576C24.2619 75.3725 39.369 75.8976 51.6652 70.0576Z"
          fill="#636160"
        />
        <path
          d="M-169.906 70.0576C-157.583 64.2049 -146.973 64.2012 -134.643 70.0573V130.571H-208.5V70.3232C-197.31 75.6381 -182.202 75.8976 -169.906 70.0576Z"
          fill="#636160"
        />
        <path
          d="M149.821 70.0576C141.139 64.2049 133.663 64.2015 124.976 70.0576V130.571H177.012V70.0573C169.127 75.3722 158.484 75.8976 149.821 70.0576Z"
          fill="#3A3634"
        />
        <path
          d="M97.7849 70.0576C89.1029 64.2049 81.6274 64.2015 72.9402 70.0576V130.571H124.976V70.0576C117.092 75.3725 106.448 75.8976 97.7849 70.0576Z"
          fill="#3A3634"
        />
        <path
          d="M45.7492 70.0576C37.0671 64.2049 29.5917 64.2015 20.9045 70.0576V130.571H72.9402V70.0576C65.056 75.3725 54.4123 75.8976 45.7492 70.0576Z"
          fill="#3A3634"
        />
        <path
          d="M201.856 70.0576C193.174 64.2049 185.699 64.2012 177.012 70.0573V130.571H229.047V70.3232C221.163 75.6381 210.519 75.8976 201.856 70.0576Z"
          fill="#3A3634"
        />
      </g>
      <g clipPath="url(#clip0_1104_9632)">
        <path
          d="m53,41.20001c0,1.6569 -1.3431,3 -3,3c-1.6569,0 -3,-1.3431 -3,-3c0,-1.6568 3,-5.4 3,-5.4c0,0 3,3.7432 3,5.4z"
          fill="#3A3634"
          id="svg_20"
          className="animate-drop-first"
        />
        <path
          d="m53,53.60001c0,1.6568 -1.3431,3 -3,3c-1.6569,0 -3,-1.3432 -3,-3c0,-1.6569 3,-5.4 3,-5.4c0,0 3,3.7431 3,5.4z"
          fill="#3A3634"
          id="svg_21"
          className="animate-drop-second"
        />
      </g>
      <path
        d="M66.2897 20.9048C69.2803 20.9048 71.1276 24.0362 69.5889 26.4976L66.5779 31.3144C66.2123 31.8991 66.0296 32.5567 66.0296 33.2143C66.0296 33.8719 66.2123 34.5295 66.5779 35.1143L69.5889 39.931C71.1276 42.3924 69.2803 45.5238 66.2897 45.5238H33.7108C30.7202 45.5238 28.873 42.3924 30.4117 39.931L33.4227 35.1143C33.7882 34.5295 33.971 33.8719 33.971 33.2143C33.971 32.5567 33.7882 31.8991 33.4227 31.3143L30.4117 26.4976C28.873 24.0362 30.7202 20.9048 33.7108 20.9048H66.2897Z"
        fill="white"
      />
      <path
        d="M66.0296 33.2143C66.0296 32.5567 66.2123 31.8991 66.5779 31.3143L69.5889 26.4976C71.1276 24.0362 69.2803 20.9048 66.2897 20.9048H33.7108C30.7202 20.9048 28.873 24.0362 30.4117 26.4976L33.4227 31.3143C33.7882 31.8991 33.971 32.5567 33.971 33.2143M66.0296 33.2143C66.0296 33.8719 66.2123 34.5295 66.5779 35.1143L69.5889 39.931C71.1276 42.3924 69.2803 45.5238 66.2897 45.5238H33.7108C30.7202 45.5238 28.873 42.3924 30.4117 39.931L33.4227 35.1143C33.7882 34.5295 33.971 33.8719 33.971 33.2143M66.0296 33.2143H33.971"
        stroke="#3A3634"
        strokeWidth="1.96952"
        strokeLinecap="round"
      />
      <defs>
        <clipPath id="clip0_1104_9632">
          <rect
            width="6"
            height="33"
            fill="white"
            transform="translate(47 46)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CoffeeLoader;
