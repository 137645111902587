const abiRCPT = [
  {
    inputs: [
      {
        internalType: "address",
        name: "_app",
        type: "address",
      },
      {
        internalType: "address",
        name: "_coUSD",
        type: "address",
      },
      {
        internalType: "address",
        name: "_coETH",
        type: "address",
      },
      {
        internalType: "address",
        name: "_coBTC",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_cofi",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_period",
        type: "uint256",
      },
    ],
    name: "getRebasingCreditsPerToken",
    outputs: [
      {
        internalType: "uint256",
        name: "rcptA",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "rcptB",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_cofi",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_rcpt",
        type: "uint256",
      },
    ],
    name: "pushRebasingCreditsPerToken",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "rcpt",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_cofi",
        type: "address",
      },
    ],
    name: "rebase",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_account",
        type: "address",
      },
      {
        internalType: "uint8",
        name: "_enabled",
        type: "uint8",
      },
    ],
    name: "setAdmin",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_cofi",
        type: "address",
      },
      {
        internalType: "uint256[]",
        name: "_rcpt",
        type: "uint256[]",
      },
    ],
    name: "setRebasingCreditsPerToken",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_account",
        type: "address",
      },
      {
        internalType: "uint8",
        name: "_enabled",
        type: "uint8",
      },
    ],
    name: "setUpkeep",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
];

module.exports = {
  abiRCPT,
};
