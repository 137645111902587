import { FC, SVGProps, useContext } from "react";

import { Collapse } from "react-collapse";

import { ReactComponent as Arrow } from "../../../assets/icons/ArrowBlack.svg";
import { TokenName } from "../../../utils/types/swap.types";
import { getCoName } from "../../../utils/helpers/global.helper";
import { APYContext } from "../../../context/APY.context";

import CollapseTokenInfos from "./CollapseTokenInfos/CollapseTokenInfos";

type TokenInfosProps = {
  collapseOpen: boolean;
  setCollapseOpen: () => void;
  TokenLogo: FC<
    SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  FiLogo: FC<
    SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  tokenName: TokenName;
};

const TokenInfos: FC<TokenInfosProps> = ({
  collapseOpen,
  setCollapseOpen,
  TokenLogo,
  FiLogo,
  tokenName,
}) => {
  const coinName = getCoName(tokenName);

  const { getApyFractionalValueByTokenName } = useContext(APYContext);
  const apyValue = getApyFractionalValueByTokenName(tokenName);

  return (
    <div className="card w-full">
      <div
        className={`flex cursor-pointer justify-between p-5 px-7 ${
          collapseOpen && "borderBottom"
        }`}
        onClick={setCollapseOpen}
      >
        <div className="center hidden gap-2 sm:flex">
          <TokenLogo />
          <span>
            Deposit {coinName.slice(2)} and earn{" "}
            {apyValue?.toPercentageFormat?.(2)} APY
          </span>
        </div>
        <div className="center flex w-full gap-[6px] sm:w-fit">
          <span className="mr-3 hidden sm:block">receive</span>
          <FiLogo />
          {coinName}
          <div className="center ml-auto h-[32px] w-[32px] cursor-pointer  rounded-lg">
            <Arrow className={`${collapseOpen && "rotate-180"}`} />
          </div>
        </div>
      </div>
      <Collapse isOpened={collapseOpen}>
        <CollapseTokenInfos tokenName={tokenName} />
      </Collapse>
    </div>
  );
};

export default TokenInfos;
