import { FC, useContext, useEffect, useRef, useState, SVGProps } from "react";

import { useNavigate } from "react-router-dom";

import { Coins, TokenName } from "../../../utils/types/swap.types";
import { ReactComponent as ThreeDots } from "../../../assets/icons/three-dots-svgrepo-com.svg";
import { MainContext } from "../../../context/Main.context";
import { SwapContext } from "../../../context/Swap.context";
import {
  bigIntToDecimal,
  getCoName,
  roundNumber,
} from "../../../utils/helpers/global.helper";
import { COINS_DISPLAY_DIGITS } from "../../../utils/constants/general";
import { APYContext } from "../../../context/APY.context";

type BalanceFIProps = {
  TokenLogo: FC<
    SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  tokenName: TokenName;
  yieldEarned: bigint;
};

const BalanceFI: FC<BalanceFIProps> = ({
  TokenLogo,
  tokenName,
  yieldEarned,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const [show, setShow] = useState<boolean>(false);

  const { setShowTransak, setIsOpenModalSwap, setAction } =
    useContext(MainContext);
  const { balanceCoinsFormatted, pricesCoins, decimalsTokens } =
    useContext(SwapContext);
  const { getApyFractionalValueByTokenName } = useContext(APYContext);

  const apyValue = getApyFractionalValueByTokenName(tokenName);

  const navigate = useNavigate();

  const earnedAmount = bigIntToDecimal(yieldEarned, decimalsTokens[tokenName]);

  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      setShow(false);
    }
  };

  useEffect(() => {
    if (show) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show]);

  const balanceAmountMoney = roundNumber(
    `${
      balanceCoinsFormatted[tokenName] * pricesCoins[tokenName as keyof Coins]
    }`,
    2
  );
  const balanceCoin = roundNumber(
    `${balanceCoinsFormatted[tokenName]}`,
    COINS_DISPLAY_DIGITS[tokenName]
  );

  const balanceText = `Balance: ${balanceCoin} ${
    Number(balanceCoin) > 0 ? `($${balanceAmountMoney})` : ""
  }`;

  const earningsText = `Earnings: ${earnedAmount?.cofiFormatFloor(1) || "--"}`;

  return (
    <div className="relative flex flex-col justify-between gap-4 rounded-xl bg-gray-100 px-7 py-4  md:flex-row ">
      <div>
        <div className="md:center flex flex-row items-center gap-3 pr-16 md:pr-0">
          <TokenLogo width={44} height={44} />
          <div>
            <span className="text-xl ">{getCoName(tokenName)}</span>
            <div className="hidden flex-wrap gap-2 md:flex">
              <p>{balanceText}</p>
              <p>{earningsText}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col flex-wrap gap-2 md:hidden">
        <p>{balanceText}</p>
        <p>{earningsText}</p>
      </div>
      <div className="flex flex-wrap items-center gap-4 md:ml-auto md:mr-4">
        <p className="flex flex-nowrap">
          30 Days APY: {apyValue?.toPercentageFormat?.(2)}
        </p>
      </div>
      <div
        ref={modalRef}
        className="center absolute right-6 top-4 cursor-pointer md:relative md:right-0 md:top-0"
      >
        <ThreeDots
          width={34}
          height={34}
          className=" h-12 w-12 rotate-90 rounded-[50%] bg-slate-50 p-4 shadow-md"
          onClick={() => {
            setShow((prev) => !prev);
          }}
        />
        {show && (
          <div className="absolute top-[100%] z-10 rounded-xl bg-white ">
            <div
              className="cursor-pointer rounded-se-xl rounded-ss-xl px-3 py-2 hover:bg-slate-200"
              onClick={() => {
                setShowTransak(true);
                navigate("/Deposit");
              }}
            >
              Buy
            </div>
            <div className=" border-t-[0.5px] border-solid border-[#00000033]"></div>
            <div
              className="cursor-pointer px-3 py-2 hover:bg-slate-200"
              onClick={() => {
                setIsOpenModalSwap(true);
                navigate("/Deposit");
              }}
            >
              Deposit
            </div>
            <div className=" border-t-[0.5px] border-solid border-[#00000033]"></div>
            <div
              className="cursor-pointer rounded-ee-xl  rounded-es-xl px-3 py-2 hover:bg-slate-200"
              onClick={() => {
                setAction(1);
                setIsOpenModalSwap(true);
                navigate("/Deposit");
              }}
            >
              Redeem
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BalanceFI;
