import { useContext, useEffect, useState } from "react";

import { useAccount } from "wagmi";

import { ReactComponent as Bulb } from "../../assets/icons/PinkBulb.svg";
import { ReactComponent as PointMainIllustration } from "../../assets/illustrations/PointMainIllustration.svg";
import { MainContext } from "../../context/Main.context";
import { WalletContext } from "../../context/Wallet.context";
import { getReferralLink } from "../../utils/helpers/global.helper";
import { ReactComponent as CopyIcon } from "../../assets/icons/Copy.svg";
// import { ReactComponent as PlusIcon } from "../../assets/icons/PlusFriends.svg";

const PointsPage = () => {
  const [copied, setCopied] = useState(false);

  const { setShowModalConnexion } = useContext(MainContext);
  const { constants } = useContext(WalletContext);
  const { address, isConnected } = useAccount();

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);

  const handleCopyClicked = () => {
    if (isConnected) {
      navigator.clipboard
        .writeText(getReferralLink(address, true))
        .then(() => setCopied(true));
    } else {
      setShowModalConnexion(true);
    }
  };

  return (
    <div className="flex-1 bg-bgCardNavbar py-16">
      <div className="container flex flex-col items-center justify-center gap-[16px]">
        <div className=" card w-full gap-5 p-5">
          <div className="overflow-hidden rounded-lg border border-[#3A363426] border-opacity-100 opacity-90">
            <PointMainIllustration width="100%" height="100%" />
          </div>
          <div>
            <div className="flex flex-col items-center justify-between py-[20px] ">
              <div className="text-[16px] font-normal text-[#000000B2]">
                My Points
              </div>
              <div className=" flex gap-[4px] text-3xl font-semibold">
                {constants.points?.toFixed(0)}
                <span className="mb-[2px] flex h-[20px] self-end  text-sm font-normal text-textGray">
                  COFI
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className=" flex w-full flex-row flex-wrap justify-between gap-4">
          <div className="card flex min-w-[220px] flex-1 flex-col justify-between p-5">
            <Bulb />
            <div className="gap-[4px]">
              <div className=" text-base font-semibold ">Cofi Tip</div>
              <div className="text-base font-normal text-black opacity-70">
                Earn points by holding COFI money and in the future by spending
                it on merchant cash backs.
              </div>
            </div>
          </div>

          <div className="card flex min-w-[220px] flex-1 flex-col justify-between bg-bgFriends bg-cover p-5">
            <div className="gap-[4px]">
              <div className=" text-base font-semibold">
                Refer Friends, Earn Rewards!
              </div>
              <div className="text-base font-normal text-black opacity-70">
                Invite your friends and earn 100 points for each successful
                referral!
              </div>
            </div>
            <button
              className="text-basic group mt-4 flex h-[40px] w-full cursor-pointer items-center justify-center rounded-lg bg-pinkLight text-white"
              disabled={copied}
              onClick={handleCopyClicked}
            >
              {isConnected ? (
                copied ? (
                  "Copied!"
                ) : (
                  <p className="flex items-center justify-center gap-1 overflow-hidden text-ellipsis whitespace-nowrap px-5">
                    <span className="group-hover:hidden">Invite Friends</span>
                    <span className="hidden overflow-hidden text-ellipsis whitespace-nowrap group-hover:block">
                      {getReferralLink(address)}
                    </span>
                    <CopyIcon width={20} />
                  </p>
                )
              ) : (
                "Get started"
              )}
            </button>
          </div>
        </div>
        {/*<div className="card h-10 h-auto w-full p-5">*/}
        {/*  <div className="flex justify-between">*/}
        {/*    <div>My Referrals</div>*/}
        {/*    /!*<button className="flex items-center gap-1 rounded-lg border-[0.5px] px-2 py-1">*!/*/}
        {/*    /!*  <PlusIcon />*!/*/}
        {/*    /!*  Invite Friends*!/*/}
        {/*    /!*</button>*!/*/}
        {/*  </div>*/}
        {/*  <div className="mb-5 mt-8 flex w-full justify-between text-xs text-black text-opacity-40">*/}
        {/*    <div className="w-[40%] ">Address</div>*/}
        {/*    <div className="w-[30%] sm:w-[40%]">Date</div>*/}
        {/*    <div className="w-[30%] text-center sm:w-[20%] sm:text-left">*/}
        {/*      Points Reward*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <div className="borderTop flex w-full justify-between border-black border-opacity-[15%] py-3 text-sm text-black opacity-70 sm:text-base">*/}
        {/*    <div className="w-[40%]">0xB7d2...2845</div>*/}
        {/*    <div className="w-[30%] sm:w-[40%]">05.05.2023</div>*/}
        {/*    <div className="w-[30%] text-center sm:w-[20%] sm:text-left">-</div>*/}
        {/*  </div>*/}
        {/*  <div className="borderTop flex w-full justify-between border-black border-opacity-[15%] py-3 text-sm text-black opacity-70 sm:text-base">*/}
        {/*    <div className="w-[40%]">0xB7d2...2845</div>*/}
        {/*    <div className="w-[30%] sm:w-[40%]">05.05.2023</div>*/}
        {/*    <div className="w-[30%] text-center sm:w-[20%] sm:text-left">*/}
        {/*      100*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <div className="borderTop flex w-full justify-between border-black border-opacity-[15%] py-3 text-sm text-black opacity-70 sm:text-base">*/}
        {/*    <div className="w-[40%]">0xB7d2...2845</div>*/}
        {/*    <div className="w-[30%] sm:w-[40%]">05.05.2023</div>*/}
        {/*    <div className="w-[30%] text-center sm:w-[20%] sm:text-left">*/}
        {/*      100*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

export default PointsPage;
