import { FC } from "react";

import { ReactComponent as Back } from "../../../assets/icons/Back.svg";
import { FaqQuestion } from "../../../utils/types/global.types";

type CardInfoProps = {
  question: FaqQuestion;
  setShowCard: Function;
};

const CardInfo: FC<CardInfoProps> = ({ question, setShowCard }) => {
  const ListType: any = question.listType || "ul";

  const renderString = (str: string) => {
    const strPattern = "\\S+@\\S+";
    const pattern = new RegExp(strPattern, "g");
    const matches = pattern.exec(str);
    if (matches) {
      const [left, right] = str.split(matches[0]);
      return (
        <>
          {left}
          <a className="cursor-pointer underline" href={`mailto:${matches[0]}`}>
            {matches[0]}
          </a>
          {right}
        </>
      );
    }
    return str;
  };

  return (
    <div className="flex w-full max-w-[904px] items-start">
      <div className="card w-full">
        <div className="relative flex flex-col p-[20px]">
          <Back
            onClick={() => {
              setShowCard(false);
            }}
            className=" cursor-pointer"
          />
          <div className="flex items-center justify-between">
            <div className="mt-4 flex items-center text-xl font-semibold leading-8">
              {question.title}
            </div>
            <div className="absolute right-6 top-[20px] text-sm">
              {question.topic}
            </div>
          </div>
        </div>
        <div className=" border-[0.5px] border-solid border-borderCardAbout"></div>
        <div className="p-[24px]">
          {!!question.text?.length &&
            question.text.map((item: string, index: number) => (
              <p key={index}>{renderString(item)}</p>
            ))}
          {!!question.list?.length && (
            <ListType
              className={`mt-3 pl-4 ${
                question.listType === "ol" ? "list-decimal" : "list-disc"
              }`}
            >
              {question.list.map((item: string, index: number) => (
                <li key={index} className="mt-2">
                  {renderString(item)}
                </li>
              ))}
            </ListType>
          )}
        </div>
      </div>
    </div>
  );
};

export default CardInfo;
