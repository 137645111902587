window.Buffer = window.Buffer || require("buffer").Buffer;

import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ReactGA from "react-ga4";
import "react-toastify/dist/ReactToastify.css";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
import { optimism } from "viem/chains";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { CoinbaseWalletConnector } from "@wagmi/core/connectors/coinbaseWallet";
import { WalletConnectConnector } from "@wagmi/core/connectors/walletConnect";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Cookies from "universal-cookie";

import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import GoogleAnalytics from "../components/Analytics/GoogleAnalytics";
import { getMagicConnector } from "../utils/services/magic";

import Routes from "./Routes";
import Providers from "./Providers";
import Modals from "./Modals";

export const cookies = new Cookies(null, { path: "/" });

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [optimism],
  [
    alchemyProvider({ apiKey: "Qxyx5pvta-u8NGcIAPraZnCO_7z0gobZ" }),
    publicProvider(),
  ]
);

const isDev = process.env.NODE_ENV === "development";

if (process.env.REACT_APP_GA_TRACKING_ID) {
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID, {
    testMode: isDev,
  });
} else {
  console.log("PROVIDE REACT_APP_GA_TRACKING_ID");
}

const config = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: "wagmi",
      },
    }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: "059415c7c59640771e4272d1c7383e7f",
      },
    }),
    getMagicConnector(chains),
  ],
  publicClient,
  webSocketPublicClient,
});

const queryClient = new QueryClient();

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <WagmiConfig config={config}>
        <Providers>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
          <Router>
            <div className="flex min-h-[100%] flex-col">
              <Header />
              <Routes />
              <Footer />
              <Modals />
              <GoogleAnalytics />
            </div>
          </Router>
        </Providers>
      </WagmiConfig>
    </QueryClientProvider>
  );
};

export default App;
