import { FC, useMemo } from "react";

import { Chain, TransactionAsset } from "../../../../utils/types/bridge.types";
import { parseBlockchainNumber } from "../../../../utils/helpers/global.helper";

type AssetItemProps = {
  asset: TransactionAsset;
  chains?: Chain[] | undefined;
  amount: number;
  decimals?: number | undefined;
  hideIcon?: boolean;
};

const DEFAULT_DECIMALS = 18;

const AssetItem: FC<AssetItemProps> = ({
  asset,
  amount,
  chains,
  decimals,
  hideIcon,
}) => {
  const chain = useMemo(() => {
    if (!chains) return;
    return chains.find((item) => item.chainId === asset.chainId);
  }, [asset.chainId, chains]);

  return (
    <div className="flex flex-col gap-1">
      <div className="flex items-center gap-1">
        {!hideIcon && (
          <img
            src={asset.logoURI}
            className="h-6 w-6 rounded-[50%] sm:h-4 sm:w-4"
            alt={asset.symbol}
          />
        )}
        <span
          className={`${hideIcon ? "text-sm" : "text-lg"} font-bold sm:text-sm`}
        >
          {parseBlockchainNumber({
            amount,
            decimals: decimals || DEFAULT_DECIMALS,
          })}{" "}
          {asset.symbol}
        </span>
      </div>
      {chain && (
        <div className="flex items-center gap-1">
          <img
            src={chain.icon || ""}
            className="h-4 w-4 rounded-[50%]"
            alt={asset.symbol}
          />
          <span className="font-bold sm:text-sm">{chain.name}</span>
        </div>
      )}
    </div>
  );
};

export default AssetItem;
