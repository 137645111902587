import { useContext, useMemo, useEffect, useState } from "react";

import { useAccount } from "wagmi";
import { AxiosResponse } from "axios";

import PieChartComponent from "../../components/PieChart/PieChart";
import { BalanceCoins, Coins, FITokens } from "../../utils/types/swap.types";
import { ReactComponent as coUSDCLogo } from "../../assets/logos/tokens/COUSDCLogo.svg";
import { ReactComponent as coBTCLogo } from "../../assets/logos/tokens/COBTCLogo.svg";
import { ReactComponent as coETHLogo } from "../../assets/logos/tokens/COETHLogo.svg";
import { SwapContext, decimalsTokens } from "../../context/Swap.context";
import {
  getEatchFIBalance,
  getTotalBalance,
  getTotalDeposit,
  getYield,
} from "../../utils/helpers/earnings.helper";
import apiDefi from "../../utils/services/apiDapp";
import useYieldEarned from "../../hooks/useYieldEarned";
import {
  bigIntToDecimal,
  getCoName,
  roundNumber,
} from "../../utils/helpers/global.helper";
import { COINS_DISPLAY_DIGITS } from "../../utils/constants/general";

import BalanceFI from "./BalanceFI/BalanceFI";
const BG_COLORS: Record<string, string> = {
  COUSD: "#394A46",
  COETH: "#F0C9DC",
  COBTC: "#FFECF5",
};

const Earnings = () => {
  const [arrayDeposit, setArrayDeposit] = useState<FITokens>({
    COUSD: 0,
    COETH: 0,
    COBTC: 0,
  });
  const { address, isConnected } = useAccount();
  const { yieldEarned } = useYieldEarned();

  const { pricesCoins, balanceCoins, balanceCoinsFormatted } =
    useContext(SwapContext);

  const totalDeposit = useMemo(() => {
    return getTotalDeposit(arrayDeposit, pricesCoins);
  }, [arrayDeposit]);

  const totalBalance: number = useMemo(() => {
    return getTotalBalance(balanceCoinsFormatted, pricesCoins);
  }, [balanceCoins, pricesCoins]);

  const yieldAmount: number = useMemo(() => {
    return getYield(totalBalance, totalDeposit) || 0;
  }, [totalBalance, totalDeposit]);

  const balancesFi: FITokens = useMemo(() => {
    return getEatchFIBalance(balanceCoinsFormatted, pricesCoins);
  }, [balanceCoins, pricesCoins]);

  const balancesFiTotal = Object.keys(balancesFi).reduce(
    (t, c) => t + (balancesFi[c as keyof FITokens] || 0),
    0
  );

  const sortedBalancesKeys = Object.keys(balancesFi).sort(
    (a, b) => balancesFi[b as keyof FITokens] - balancesFi[a as keyof FITokens]
  );

  useEffect(() => {
    if (isConnected) {
      apiDefi
        .getDepositArray(address)
        .then((deposits: AxiosResponse<FITokens, any>) => {
          setArrayDeposit(deposits.data);
        });
    }
  }, [address]);

  const renderPie = balancesFiTotal > 0;
  return (
    <div className="flex-1 bg-bgCardNavbar py-16">
      <div className="container flex flex-col gap-5">
        <div className="flex w-full flex-col justify-between gap-4 md:flex-row">
          <div
            className={`flex flex-1 gap-5 ${
              renderPie
                ? "flex-col sm:flex-row md:flex-col"
                : "flex-col sm:flex-row"
            }`}
          >
            <div className="card flex flex-1 justify-between gap-2 p-5 sm:flex-col">
              <h3 className="text-lg font-extrabold sm:text-2xl">
                Total Balance
              </h3>
              <p className="text-xl font-bold text-gray-600">
                ${totalBalance?.cofiFormatFloor(1)}
              </p>
            </div>
            <div className="card flex flex-1 justify-between gap-2 p-5 sm:flex-col">
              <h3 className="text-lg font-extrabold sm:text-2xl">Yield</h3>
              <p className="text-xl font-bold text-gray-600">
                ${yieldAmount?.cofiFormatFloor(1)}
              </p>
            </div>
          </div>

          {renderPie && (
            <div className="card flex flex-1 flex-col p-5">
              <div className="text-lg font-extrabold sm:text-2xl">
                My Holdings
              </div>
              <div className="flex flex-wrap justify-between gap-y-10">
                <div className="mt-4 min-w-[150px]">
                  {sortedBalancesKeys.map((key) => {
                    const decimalValue = bigIntToDecimal(
                      balanceCoins?.[key as keyof BalanceCoins],
                      decimalsTokens?.[key as keyof Coins]
                    );

                    const estimatedValue = decimalValue
                      ? pricesCoins[key as keyof Coins] * decimalValue
                      : "";

                    const estimatedValueRounded = roundNumber(
                      `${estimatedValue}`,
                      2
                    );

                    return (
                      <div key={key} className="flex items-center text-lg">
                        <div
                          className="mr-1 h-4 w-4"
                          style={{
                            backgroundColor: BG_COLORS[key],
                          }}
                        />
                        <span>{getCoName(key)}</span>:{" "}
                        {roundNumber(
                          `${decimalValue}`,
                          COINS_DISPLAY_DIGITS[key as keyof Coins]
                        )}{" "}
                        (${estimatedValueRounded})
                      </div>
                    );
                  })}
                </div>
                <div className="center relative top-[-10px] mx-auto self-center">
                  <PieChartComponent
                    balancesFi={balancesFi}
                    bgColors={BG_COLORS}
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="card flex w-full flex-col justify-between  gap-5 md:p-5">
          <div className="p-3 text-xl font-extrabold">My Balances</div>
          <div className="flex flex-col gap-3 p-4 md:p-5">
            <BalanceFI
              TokenLogo={coUSDCLogo}
              tokenName={"COUSD"}
              yieldEarned={yieldEarned.COUSD}
            />
            <BalanceFI
              TokenLogo={coETHLogo}
              tokenName={"COETH"}
              yieldEarned={yieldEarned.COETH}
            />
            <BalanceFI
              TokenLogo={coBTCLogo}
              tokenName={"COBTC"}
              yieldEarned={yieldEarned.COBTC}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Earnings;
