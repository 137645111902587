import { FC, useContext, useEffect } from "react";

import transakSDK from "@transak/transak-sdk";
import { useAccount } from "wagmi";

import { WalletContext } from "../../../context/Wallet.context";

type ModalTransakProps = {
  showTransak: boolean;
  productsAvailed: string;
  setShowTransak: Function;
  setTransakOrderStatusDataModal: Function;
};

const ModalTransak: FC<ModalTransakProps> = ({
  showTransak,
  setShowTransak,
  productsAvailed,
  setTransakOrderStatusDataModal,
}) => {
  const { address } = useAccount();
  const { applicantData, userData } = useContext(WalletContext);

  useEffect(() => {
    if (showTransak) {
      const transak = new transakSDK({
        apiKey: process.env.REACT_APP_KEY_TRANSAK,
        environment: process.env.REACT_APP_TRANSAK_ENV || "STAGING",
        network: "optimism",
        walletAddress: address,
        widgetHeight: "500px",
        productsAvailed: productsAvailed,
        isAutoFillUserData: true,
        userData: {
          firstName: applicantData?.info?.firstName,
          lastName: applicantData?.info?.lastName,
          email: userData.email,
          dob: applicantData?.info?.dob,
          address: {
            countryCode: applicantData?.info?.country,
          },
        },
      });

      transak.init();

      transak.on(transak.EVENTS.TRANSAK_WIDGET_CLOSE, () => {
        transak.close();

        setShowTransak(false);
      });

      transak.on(
        transak.EVENTS.TRANSAK_ORDER_SUCCESSFUL,
        (transaktionData: Record<string, any>) => {
          transak.close();

          setShowTransak(false);
          setTransakOrderStatusDataModal(transaktionData);
        }
      );

      transak.on(
        transak.EVENTS.TRANSAK_ORDER_CANCELLED,
        (transaktionData: Record<string, any>) => {
          transak.close();

          setShowTransak(false);
          setTransakOrderStatusDataModal(transaktionData);
        }
      );

      transak.on(
        transak.EVENTS.TRANSAK_ORDER_FAILED,
        (transaktionData: Record<string, any>) => {
          transak.close();

          setShowTransak(false);
          setTransakOrderStatusDataModal(transaktionData);
        }
      );
    }
  }, [showTransak]);

  return <div></div>;
};

export default ModalTransak;
