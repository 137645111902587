import { createContext, ReactNode } from "react";

import { addressCOUSD } from "../utils/constants/address/addressesCOFI/USDCOFI";
import { addressCOETH } from "../utils/constants/address/addressesCOFI/ETHCOFI";
import { addressCOBTC } from "../utils/constants/address/addressesCOFI/BTCCOFI";
import { useApyPercentageData } from "../hooks/useApyPercentageData";
import { TokenName } from "../utils/types/swap.types";

export type ApyDataType = Record<number, Record<string, number>>;

type APYContextProps = {
  coUSDData: ApyDataType;
  coETHData: ApyDataType;
  coBTCData: ApyDataType;
  getDataByTokenName: (token: TokenName) => ApyDataType | null;
  getApyFractionalValueByTokenName: (token: TokenName) => number;
};

type APYProviderProps = {
  children: ReactNode;
};

export type PeriodType = {
  name: string;
  value: number;
};

export const PERIODS_DATA: PeriodType[] = [
  { name: "7 days APY", value: 7 },
  { name: "30 days APY", value: 10 },
  { name: "60 days APY", value: 14 },
];

export const APYContext = createContext({} as APYContextProps);

const APYProvider = ({ children }: APYProviderProps) => {
  const coUSDData = useApyPercentageData(
    PERIODS_DATA.map(({ value }) => ({
      address: addressCOUSD,
      period: value,
    }))
  );
  const coETHData = useApyPercentageData(
    PERIODS_DATA.map(({ value }) => ({
      address: addressCOETH,
      period: value,
    }))
  );
  const coBTCData = useApyPercentageData(
    PERIODS_DATA.map(({ value }) => ({
      address: addressCOBTC,
      period: value,
    }))
  );

  const getDataByTokenName = (token: TokenName) => {
    switch (token) {
      case "COUSD":
        return coUSDData;
      case "COETH":
        return coETHData;
      case "COBTC":
        return coBTCData;
      default:
        return null;
    }
  };

  const getApyFractionalValueByTokenName = (token: TokenName) => {
    const dataPer30DaysPeriod = Object.values(
      getDataByTokenName(token)?.[7] || {}
    );

    return dataPer30DaysPeriod[0] || 0;
  };

  return (
    <APYContext.Provider
      value={{
        coUSDData,
        coETHData,
        coBTCData,
        getDataByTokenName,
        getApyFractionalValueByTokenName,
      }}
    >
      {children}
    </APYContext.Provider>
  );
};

export default APYProvider;
