import axios from "axios";

import { HubspotContact } from "../types/hubspot.types";
import { RequiredField } from "../types/global.types";

const crmAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL + "crm/",
  timeout: 10000,
});

const addContact = (data: HubspotContact) => {
  try {
    return crmAxiosInstance.post("contacts", {
      properties: { ...data, website: window.location.host },
    });
  } catch (error) {
    console.error(error);
  }
};

const updateContact = (data: RequiredField<HubspotContact, "email">) => {
  try {
    return crmAxiosInstance.patch(`contacts/${data.email}`, {
      properties: data,
    });
  } catch (error) {
    console.error(error);
  }
};

export default {
  addContact,
  updateContact,
};
